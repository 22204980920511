import React, { useEffect, useState } from 'react'
import { BetmenPage, Tab } from '../../../common/components/BetmenPage'
import { useTranslation } from '../../../common/hooks/helper/useTranslation'
import { PatientList } from './components/PatientList'
import { AddButton } from '../../../common/components/AddButton/AddButton'
import { AddPatientModal } from './components/AddPatientModal'
import { usePatientsList } from '../../../common/hooks/usePatientsList'
import { PAGE_SIZE } from '../../../config'
import { useUserProfile } from '../../../common/hooks/useUserProfile'
import { PatientListFilters } from './components/PatientListFilters'
import { PatientFilter, PatientStatus } from '../../../models/graphql'
import { useDebounce } from '../../../common/hooks'

enum PatientListTabs {
  Active = 0,
  Removed = 1,
}

export const DoctorPatients: React.FC = () => {
  const { t } = useTranslation()

  const profile = useUserProfile()

  const [selectedTabIndex, setSelectedTabIndex] = useState<PatientListTabs>(
    PatientListTabs.Active
  )

  const tabs: Tab[] = [
    { label: t('doctor:patientList.current') },
    { label: t('doctor:patientList.removed') },
  ]

  const [filterInput, setFilterInput] = useState<PatientFilter>({})
  const filterData: PatientFilter = useDebounce(filterInput)

  const { refetch, ...patientList } = usePatientsList({
    variables: {
      doctorId: profile?.id ?? '',
      filterData: {
        ...filterData,
        status:
          selectedTabIndex === PatientListTabs.Active
            ? PatientStatus.ACTIVE
            : PatientStatus.DISCHARGED,
      },
      paginationInput: {
        offset: 0,
        limit: PAGE_SIZE,
      },
    },
  })

  useEffect(() => {
    refetch()
  }, [filterData, refetch, selectedTabIndex])

  const [isAddPatientsModalOpen, toggleIsAddPatientModalOpen] =
    useState<boolean>(false)

  const [isFilterPanelOpen, setFilterPanelOpen] = useState(false)

  const handleFilterOpen = () => {
    setFilterPanelOpen(true)
  }

  const handleFilterClose = () => {
    setFilterPanelOpen(false)
  }

  return (
    <>
      <BetmenPage
        title={t('menu:doctor.patients')}
        tabs={tabs}
        onTabChange={setSelectedTabIndex}
        withoutPadding
        headerRightContent={
          <AddButton
            handleAdd={() => toggleIsAddPatientModalOpen(true)}
            buttonText={t(`patients:new`)}
          />
        }
        filters={
          <PatientListFilters
            isFilterPanelOpen={isFilterPanelOpen}
            handleFilterClose={handleFilterClose}
            filterInput={filterInput}
            setFilterInput={setFilterInput}
          />
        }
      >
        <PatientList
          filterInput={filterInput}
          setFilterInput={setFilterInput}
          handleFilterOpen={handleFilterOpen}
          {...patientList}
        />
      </BetmenPage>

      {isAddPatientsModalOpen && (
        <AddPatientModal
          patientList={patientList.data}
          isOpen={isAddPatientsModalOpen}
          toggleIsOpen={toggleIsAddPatientModalOpen}
        />
      )}
    </>
  )
}
