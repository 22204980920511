import React, { useCallback, useEffect, useState } from 'react'
import {
  BetmenList,
  BetmenListBody,
  BetmenListHeader,
  BetmenListHeaderCell,
} from '../../../../common/components'
import { PatientListActions } from './PatientListActions'
import { PatientListItem } from './PatientListItem'
import { useTranslation } from 'react-i18next'
import { useUserProfile } from '../../../../common/hooks/useUserProfile'
import { UsePatientsListReturn } from '../../../../common/hooks/usePatientsList'
import { PAGE_SIZE } from '../../../../config'
import { PatientFilter } from '../../../../models/graphql'

export interface PatientListProps
  extends Pick<
    UsePatientsListReturn,
    'data' | 'isLoading' | 'hasMore' | 'fetchMore' | 'fetchMoreLoading'
  > {
  filterInput: PatientFilter
  setFilterInput: (filter: PatientFilter) => void
  handleFilterOpen: () => void
}

const PatientList: React.FC<PatientListProps> = ({
  data,
  isLoading,
  hasMore,
  fetchMore,
  fetchMoreLoading,
  filterInput,
  setFilterInput,
  handleFilterOpen,
}) => {
  const { t } = useTranslation()

  const profile = useUserProfile()

  const [selectedPatientIds, setSelectedPatientIds] = useState<string[]>([])

  const fetchMorePatients = useCallback(() => {
    const offset = data.length
    fetchMore({
      variables: {
        doctorId: profile?.id ?? '',
        paginationInput: {
          offset,
          limit: PAGE_SIZE,
        },
      },
    })
  }, [data.length, fetchMore, profile?.id])

  useEffect(() => {
    setSelectedPatientIds([])
  }, [filterInput])

  return (
    <BetmenList
      loading={isLoading && !fetchMoreLoading}
      fetchMore={fetchMorePatients}
      fetchMoreLoading={fetchMoreLoading}
      dataSource={data}
      hasMoreItem={hasMore}
      gridColumnSizes={[1, 4, 2, 3, 2]}
      filters={
        <PatientListActions
          data={data}
          selectedIds={selectedPatientIds}
          setSelectedIds={setSelectedPatientIds}
          filterInput={filterInput}
          setFilterInput={setFilterInput}
          handleFilterOpen={handleFilterOpen}
        />
      }
      header={
        <BetmenListHeader>
          <BetmenListHeaderCell />
          <BetmenListHeaderCell>
            {t('patients:namePlaceholder')}
          </BetmenListHeaderCell>
          <BetmenListHeaderCell>{t('common:taj')}</BetmenListHeaderCell>
          <BetmenListHeaderCell>{t('common:birthdate')}</BetmenListHeaderCell>
          <BetmenListHeaderCell>{t('common:status')}</BetmenListHeaderCell>
        </BetmenListHeader>
      }
    >
      <BetmenListBody>
        {data.map((patient, i) => (
          <PatientListItem
            key={`${patient.id}_${i}`}
            patient={patient}
            selectedPatientIds={selectedPatientIds}
            setSelectedPatientIds={setSelectedPatientIds}
          />
        ))}
      </BetmenListBody>
    </BetmenList>
  )
}

export { PatientList }
