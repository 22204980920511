import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form'
import { useNotifications } from '../../../../../common/providers/NotificationProvider'
import { BoxWithLabel } from '../../../../../common/components/BoxWithLabel'
import { useDoctorPatientsData } from '../../../../../common/hooks/useDoctorPatientsData'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { formatDayjsToStringDateType } from '../../../../../utils/formatToDateType'
import { useSendSurveyToUsers } from '../../hooks/useSendSurveyToPatients'
import {
  DEFAULT_FILLOUT_TIME_IN_DAYS,
  SurveyDatePicker,
} from './SurveyDatePicker'
import { PatientListItemType } from '../../../DoctorPatients/types/patientList.types'

interface Props extends DialogProps {
  surveySchemaId: string
  onClose: () => void
}

export const SendSurveyToPatientModal: React.FC<Props> = ({
  surveySchemaId,
  onClose,
  ...props
}) => {
  const { t } = useTranslation()
  const { setSuccessToast, setErrorToast } = useNotifications()
  const { patientList } = useDoctorPatientsData({
    showOnlyActivePatients: true,
  })
  const { setValue } = useFormContext()

  const today = dayjs().startOf('day')
  const oneWeekLater = today.add(DEFAULT_FILLOUT_TIME_IN_DAYS, 'day')

  const formMethods = useForm<{
    patients: PatientListItemType[]
    fillableFromDate: string
    fillableUntilDate: string
  }>({
    mode: 'onChange',
    defaultValues: {
      patients: [],
      fillableFromDate: formatDayjsToStringDateType(today),
      fillableUntilDate: formatDayjsToStringDateType(oneWeekLater),
    },
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = formMethods

  const [sendSurveyToPatients] = useSendSurveyToUsers({
    onCompleted: () => {
      setSuccessToast('successSendSurvey')
      setValue('canBeDeleted', false)
      onClose()
    },
    onError: (e) => setErrorToast(e),
  })

  const onSubmit = handleSubmit(async (formValues) => {
    await sendSurveyToPatients({
      variables: {
        surveySchemaId,
        userIds: formValues.patients
          .map((patientOption) => patientOption?.user?.id)
          .filter((x) => x) as string[],
        fillableFrom: formValues.fillableFromDate,
        fillableUntil: formValues.fillableUntilDate,
      },
    })
  })

  return (
    <>
      <FormProvider {...formMethods}>
        <Dialog fullWidth {...props}>
          <DialogTitle
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {t(`survey:sendToPatientTitle`)}
          </DialogTitle>
          <DialogContent>
            <Stack>
              <BoxWithLabel label={t('survey:shareWithPatientLabel')}>
                <Controller
                  control={control}
                  name="patients"
                  rules={{
                    validate: (value) =>
                      value?.length < 1
                        ? (t('messages:warnings.required') as string)
                        : true,
                  }}
                  render={({ field: { onChange, onBlur, value, name } }) => (
                    <Autocomplete
                      multiple
                      options={patientList}
                      disableClearable
                      filterSelectedOptions
                      getOptionLabel={(patientItem) =>
                        `${patientItem.lastName} ${
                          patientItem.firstName
                        } (${dayjs(patientItem.birthDate ?? '').format(
                          'YYYY-MM-DD'
                        )}) - TAJ: ${patientItem.tajNumber}`
                      }
                      value={value || []}
                      onChange={(_, value) => onChange([...value])}
                      onBlur={onBlur}
                      data-cy="SendSurveyPatientModal-Autocomplete-patients"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={t('patients:namePlaceholder')}
                          error={!!errors.patients}
                          variant="outlined"
                          name={name}
                          helperText={errors.patients?.message}
                        />
                      )}
                    />
                  )}
                />
              </BoxWithLabel>
            </Stack>
            <SurveyDatePicker
              fillableUntilName="fillableUntilDate"
              fillableFromName="fillableFromDate"
            />
          </DialogContent>
          <DialogActions sx={{ pb: 2, px: 3 }}>
            <Button variant="outlined" onClick={onClose}>
              {t('common:cancel')}
            </Button>
            <Button
              data-cy="SendSurveyPatientModal-Button-send"
              onClick={onSubmit}
            >
              {t('common:send')}
            </Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  )
}
