import { useQuery } from '@apollo/client'

import { GET_DOCTOR_OWN_PATIENTS } from '../../operations/doctorProfileOperations'
import { GET_ASSISTANT_PATIENTS } from '../../operations/assistantProfileOperations'
import {
  getAssistantPatients,
  getDoctorOwnPatients,
  getDoctorOwnPatientsVariables,
  UserType,
} from '../../models/graphql'
import { useMemo } from 'react'
import dayjs from 'dayjs'
import { useUserProfile } from './useUserProfile'
import { useUserType } from './useUserType'
import { PatientListItemType } from '../../views/doctor/DoctorPatients/types/patientList.types'

type ReturnData = {
  patientList: PatientListItemType[]
  isLoading: boolean
  refetch: () => void
}

type Props = {
  skip?: boolean
  showOnlyActivePatients?: boolean
}

export const useDoctorPatientsData = ({
  skip,
  showOnlyActivePatients,
}: Props): ReturnData => {
  const userType = useUserType()
  const profile = useUserProfile()
  const isAssistant = userType === UserType.Assistant
  const doctorProfileId = profile?.id

  const doctorResults = useQuery<
    getDoctorOwnPatients,
    getDoctorOwnPatientsVariables
  >(GET_DOCTOR_OWN_PATIENTS, {
    skip: skip || isAssistant || !doctorProfileId,
    variables: {
      doctorProfileId: doctorProfileId || '',
      isActivePatient: showOnlyActivePatients,
    },
    fetchPolicy: 'network-only',
  })

  const assistantResults = useQuery<getAssistantPatients>(
    GET_ASSISTANT_PATIENTS,
    { skip: skip || !isAssistant }
  )

  const patientList = useMemo(() => {
    const rawPatientList = isAssistant
      ? assistantResults.data?.getAssistantProfile?.ownPatients || []
      : doctorResults.data?.getDoctorProfile.ownPatients || []
    const sortedPatientList = rawPatientList
      .slice()
      .sort((a, b) => (dayjs(b.createdAt).isAfter(a.createdAt) ? 1 : -1))

    return sortedPatientList.map(({ patient, closedAt }) => ({
      id: patient?.id ?? '',
      user: patient?.user ?? null,
      firstName: patient?.firstName ?? '',
      lastName: patient?.lastName ?? '',
      title: patient?.title ?? '',
      tajNumber: patient?.tajNumber ?? '',
      phone: patient?.phone ?? '',
      birthDate: patient?.birthDate ?? null,
      weight: patient?.weight ?? null,
      height: patient?.height ?? null,
      isDeletableForDoctor: patient?.isDeletableForDoctor ?? false,
      isActive: !closedAt,
    }))
  }, [isAssistant, assistantResults.data, doctorResults.data])

  const isLoading = isAssistant
    ? assistantResults.loading
    : doctorResults.loading
  const refetch = isAssistant ? assistantResults.refetch : doctorResults.refetch

  return {
    patientList,
    isLoading,
    refetch,
  }
}
