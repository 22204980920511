import React from 'react'
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  IconButton,
  Slider,
  Stack,
  Typography,
} from '@mui/material'

import { Close as CloseIcon } from '@mui/icons-material'
import {
  BnoCode,
  BnoCodeSelector,
} from '../../../../common/components/selectors/BnoCodeSelector'
import { FormProvider, useForm } from 'react-hook-form'
import { Gender, PatientFilter } from '../../../../models/graphql'
import omit from 'lodash/omit'
import { AGE_MAX, AGE_MIN } from './patients.constants'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '../../../../common/components'
import { useTranslation } from 'react-i18next'

const FILTER_PANEL_WIDTH = 350
const emptyFilter = { includes: [], excludes: [] }

interface PatientListFiltersProps {
  isFilterPanelOpen: boolean
  handleFilterClose: () => void
  filterInput: PatientFilter
  setFilterInput: (filter: PatientFilter) => void
}
export const PatientListFilters: React.FC<PatientListFiltersProps> = ({
  isFilterPanelOpen,
  handleFilterClose,
  filterInput,
  setFilterInput,
}) => {
  const { t } = useTranslation('patients')

  const clearFilters = () => setFilterInput({})
  const clearFilter = (key: string) => setFilterInput(omit(filterInput, key))

  // BnoCodeSelector component doesn't work without a form wrapper
  const formMethods = useForm<Pick<PatientFilter, 'bnoCode'>>({
    defaultValues: {
      bnoCode: emptyFilter,
    },
  })

  const setBnoFilter = (type: 'includes' | 'excludes', data: string[]) =>
    setFilterInput({
      ...filterInput,
      bnoCode: { ...filterInput.bnoCode, [type]: data },
    })

  const clearBnoFilters = () => {
    formMethods.setValue('bnoCode.includes', [])
    formMethods.setValue('bnoCode.excludes', [])
    clearFilter('bnoCode')
  }

  const setGenderFilter = (gender: Gender) =>
    filterInput.gender === gender
      ? clearFilter('gender')
      : setFilterInput({
          ...filterInput,
          gender,
        })

  const setAgeFilter = (_: Event, age: number | number[]) => {
    if (Array.isArray(age)) {
      setFilterInput({
        ...filterInput,
        age: {
          min: age[0],
          max: age[1],
        },
      })
    }
  }

  return (
    <Collapse in={isFilterPanelOpen} orientation="horizontal">
      <Stack
        sx={{
          width: FILTER_PANEL_WIDTH,
          height: '100%',
          backgroundColor: 'white',
        }}
        py={1}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          pl={2}
          pr={1}
        >
          <Typography variant="h6" sx={{ textTransform: 'uppercase' }}>
            {t('filters.title')}
          </Typography>

          <Button
            size="small"
            color="primary"
            variant="text"
            onClick={clearFilters}
            sx={{ marginLeft: 'auto', paddingX: '4px' }}
          >
            {t('filters.reset')}
          </Button>
          <IconButton onClick={handleFilterClose} size="large">
            <CloseIcon />
          </IconButton>
        </Stack>

        <Stack px={1} sx={{ gap: '16px' }}>
          <FormProvider {...formMethods}>
            <Accordion defaultExpanded>
              <AccordionSummary>
                <Typography variant="body2">
                  {t('filters.bno.title')}
                </Typography>
                {((filterInput.bnoCode?.includes?.length ?? 0) > 0 ||
                  (filterInput.bnoCode?.excludes?.length ?? 0) > 0) && (
                  <Button
                    size="small"
                    color="primary"
                    variant="text"
                    onClick={(e) => {
                      e.stopPropagation()
                      clearBnoFilters()
                    }}
                    sx={{ marginLeft: 'auto', paddingX: '4px' }}
                  >
                    {t('filters.resetOne')}
                  </Button>
                )}
              </AccordionSummary>
              <AccordionDetails>
                <BnoCodeSelector
                  name="bnoCode.includes"
                  label={t('filters.bno.includes')}
                  onChange={(data) => {
                    if (Array.isArray(data)) {
                      setBnoFilter(
                        'includes',
                        data.map((item: BnoCode) => item.id)
                      )
                    }
                  }}
                  exclude={filterInput.bnoCode?.excludes ?? []}
                />
                <BnoCodeSelector
                  name="bnoCode.excludes"
                  label={t('filters.bno.excludes')}
                  onChange={(data) => {
                    if (Array.isArray(data)) {
                      setBnoFilter(
                        'excludes',
                        data.map((item: BnoCode) => item.id)
                      )
                    }
                  }}
                  exclude={filterInput.bnoCode?.includes ?? []}
                />
              </AccordionDetails>
            </Accordion>
          </FormProvider>

          <Accordion defaultExpanded>
            <AccordionSummary>
              <Typography variant="body2">
                {' '}
                {t('filters.gender.title')}
              </Typography>
              {filterInput.gender && (
                <Button
                  size="small"
                  color="primary"
                  variant="text"
                  onClick={(e) => {
                    e.stopPropagation()
                    clearFilter('gender')
                  }}
                  sx={{ marginLeft: 'auto', paddingX: '4px' }}
                >
                  {t('filters.resetOne')}
                </Button>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <Stack direction="row" alignItems="center">
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setGenderFilter(Gender.MALE)}
                >
                  <Checkbox checked={filterInput.gender === Gender.MALE} />
                  <Typography>{t('filters.gender.male')}</Typography>
                </Stack>

                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setGenderFilter(Gender.FEMALE)}
                >
                  <Checkbox
                    checked={filterInput.gender === Gender.FEMALE}
                    sx={{ marginLeft: 4 }}
                  />
                  <Typography>{t('filters.gender.female')}</Typography>{' '}
                </Stack>
              </Stack>
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded>
            <AccordionSummary>
              <Typography variant="body2">{t('filters.age.title')}</Typography>
              {(filterInput.age?.min || filterInput.age?.max) && (
                <Button
                  size="small"
                  color="primary"
                  variant="text"
                  onClick={(e) => {
                    e.stopPropagation()
                    clearFilter('age')
                  }}
                  sx={{ marginLeft: 'auto', paddingX: '4px' }}
                >
                  {t('filters.resetOne')}
                </Button>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ px: 1 }}>
                <Slider
                  value={[
                    filterInput.age?.min ?? AGE_MIN,
                    filterInput.age?.max ?? AGE_MAX,
                  ]}
                  max={AGE_MAX}
                  onChange={setAgeFilter}
                  marks={[
                    { value: AGE_MIN, label: `${AGE_MIN}` },
                    {
                      value: AGE_MAX,
                      label: ` ${AGE_MAX} ${t('filters.age.years')}`,
                    },
                  ]}
                  valueLabelDisplay="auto"
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        </Stack>
      </Stack>
    </Collapse>
  )
}
