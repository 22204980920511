import React from 'react'
import { styled } from '@mui/material/styles'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'

import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import { Stack } from '@mui/material'

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters={false} elevation={0} square {...props} />
))(({ theme }) => ({
  borderRadius: '8px',
  overflow: 'hidden',
  backgroundColor: theme.palette.grey[200],
  '&::before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props}>
    <Stack
      direction="row"
      marginLeft={2}
      height={32}
      alignItems="center"
      justifyContent="space-between"
      flex={1}
    >
      {props.children}
    </Stack>
  </MuiAccordionSummary>
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  backgroundColor: theme.palette.grey[200],
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.grey[50]}`,
}))

export { Accordion, AccordionSummary, AccordionDetails }
