import React, { FC, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Collapse,
  TextField,
  Typography,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  FormControl,
  Checkbox,
} from '@mui/material'
import {
  Check as CheckIcon,
  Clear as ClearIcon,
  Person as PersonIcon,
} from '@mui/icons-material'

import { gql, Reference, useMutation, useQuery } from '@apollo/client'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useUserProfile } from '../../../../common/hooks/useUserProfile'

import {
  FIND_PATIENT_PROFILE,
  INVITE_PATIENT,
  INVITE_EXISTING_PATIENT,
} from '../../../../operations/doctorPatientOperations'
import {
  inviteExistingPatient,
  inviteExistingPatientVariables,
  invitePatient,
  invitePatientVariables,
  findPatientProfile,
  findPatientProfileVariables,
  UserType,
  listAllDoctors_listAllDoctors as Doctor,
} from '../../../../models/graphql'
import { Loading } from '../../../../common/components/Loading'
import { useStoreActions } from '../../../../store/store.hooks'
import { FRAGMENT_PATIENT_CHORE } from '../../../../operations/fragments'
import { ValidPhonePattern } from '../../../../utils/isValidPhone'
import { ValidEmailPattern } from '../../../../utils/isValidEmail'
import dayjs from 'dayjs'
import { useUserType } from '../../../../common/hooks/useUserType'
import { DoctorSelector } from '../../../../common/components/selectors/DoctorSelector'
import { PatientListItemType } from '../types/patientList.types'

// validate end extract taj number from string
function parseTajNumber(tajString: string): false | string {
  const inputDigits = (tajString.match(/\d+/g) || []).join('').split('')
  const cdvCode =
    [...inputDigits.slice(0, 8)].reduce(
      (acc, item, index) => acc + Number(item) * (index % 2 !== 0 ? 7 : 3),
      0
    ) % 10
  return inputDigits.length === 9 && Number(inputDigits[8]) === cdvCode
    ? inputDigits.join('')
    : false
}

type newPatientForm = {
  tajNumber: string
  birthDate: string
  title: string
  firstName: string
  lastName: string
  gender: string
  email: string
  phone: string
  patientSigned?: boolean
  assistantOwnDoctors?: Doctor[]
}

type Props = {
  patientList?: PatientListItemType[]
  isOpen: boolean
  toggleIsOpen: (isOpen: boolean) => void
}

const defaultValues: newPatientForm = {
  tajNumber: '',
  birthDate: '',
  title: '',
  firstName: '',
  lastName: '',
  gender: '',
  email: '',
  phone: '',
  patientSigned: false,
  assistantOwnDoctors: [],
}

const AddPatientModal: FC<Props> = ({
  isOpen,
  toggleIsOpen,
  patientList = [],
}) => {
  const { t } = useTranslation()
  const history = useHistory()

  const setToast = useStoreActions((actions) => actions.toast.setToast)

  const [invalidEmailValues, setInvalidEmailValues] = useState<string[]>([])
  const [showError, setShowError] = useState<boolean>(false)

  const methods = useForm<newPatientForm>({
    mode: 'onChange',
    defaultValues,
  })

  const {
    watch,
    register,
    handleSubmit,
    control,
    trigger,
    reset,
    formState: { errors, isValid },
  } = methods

  const tajNumber = isOpen ? watch('tajNumber') : ''
  const tajDigitCount = (tajNumber.match(/\d+/g) || []).join('').length
  const validTajNumber = parseTajNumber(tajNumber)
  const isTajValid = !!validTajNumber
  const userType = useUserType()

  const urlUserTypeSegment = useMemo(
    () => (userType === UserType.Assistant ? 'assistant' : 'doctor'),
    [userType]
  )

  const { data: storedProfileData, loading: searchingProfile } = useQuery<
    findPatientProfile,
    findPatientProfileVariables
  >(FIND_PATIENT_PROFILE, {
    skip: !isTajValid,
    variables: {
      tajNumber: validTajNumber || '',
    },
  })

  // Check storeProfileData in useEffect instead of the query onCompleted fn
  // as it's not complete properly after modal is reopened
  useEffect(() => {
    if (
      !storedProfileData ||
      storedProfileData.findPatientProfile.__typename ===
        'PatientProfileNotFoundError'
    ) {
      return
    }

    reset({
      tajNumber: storedProfileData.findPatientProfile.tajNumber,
      birthDate: storedProfileData.findPatientProfile.birthDate,
      title: storedProfileData.findPatientProfile.title,
      firstName: storedProfileData.findPatientProfile.firstName,
      lastName: storedProfileData.findPatientProfile.lastName,
      gender: storedProfileData.findPatientProfile.gender,
      email: ' ', // it should be a not null string
      phone: storedProfileData.findPatientProfile.phone,
      patientSigned: true,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedProfileData])

  const profileToAdd =
    storedProfileData?.findPatientProfile.__typename === 'PatientProfile'
      ? storedProfileData?.findPatientProfile
      : undefined

  const doctorOwnProfile = patientList.find(
    (patient) => patient && patient.id === profileToAdd?.id
  )
  const isInvalidTaj = tajNumber.length >= 9 && !isTajValid

  const patientHasProfile = !searchingProfile && isTajValid && !!profileToAdd
  const isNewProfile = !searchingProfile && isTajValid && !profileToAdd

  const handleClose = () => {
    reset(defaultValues)
    toggleIsOpen(false)
  }

  const profile = useUserProfile()
  const doctorProfileId = profile?.id

  const [sendExistinginvite, { loading: isExistingSaving }] = useMutation<
    inviteExistingPatient,
    inviteExistingPatientVariables
  >(INVITE_EXISTING_PATIENT, {
    onCompleted: (data) => {
      setToast({
        text: t('patients:addPatientSuccess'),
        type: 'success',
      })
      const patientId = data.inviteExistingPatient[0]?.patient?.id
      if (patientId) {
        history.push(`/${urlUserTypeSegment}/patients/${patientId}`)
      }
    },
    update: (cache, { data }) => {
      const ownPatient = data?.inviteExistingPatient[0]
      cache.modify({
        id: cache.identify({
          id: doctorProfileId,
          __typename: 'DoctorProfile',
        }),
        fields: {
          ownPatients(existingDoctorProfileRefs: Reference[] | null) {
            const newOwnPatientRef = cache.writeFragment({
              data: ownPatient,
              fragment: gql`
                fragment NewOwnPatient on DoctorOwnPatients {
                  id
                  patient {
                    id
                    user {
                      id
                      email
                    }
                    ...patientChore
                    birthDate
                  }
                }
                ${FRAGMENT_PATIENT_CHORE}
              `,
              fragmentName: 'NewOwnPatient',
            })

            return [...(existingDoctorProfileRefs || []), newOwnPatientRef]
          },
        },
      })
    },
  })

  const [sendInvite, { loading: isSaving }] = useMutation<
    invitePatient,
    invitePatientVariables
  >(INVITE_PATIENT, {
    onCompleted: (data) => {
      setToast({
        text: t('patients:invitePatientSuccess'),
        type: 'success',
      })
      const patientId = data.invitePatient.id
      if (patientId) {
        history.push(`/${urlUserTypeSegment}/patients/${patientId}`)
      }
    },
    onError: (error) => {
      const errorMessages: string[] = []
      const graphQlError = error.graphQLErrors[0]
      if (graphQlError) {
        const detail =
          (graphQlError.extensions?.exception?.meta as { target: string })
            ?.target || ''
        const isFieldDuplicate = !!~detail.indexOf('email')
        if (isFieldDuplicate) {
          setInvalidEmailValues([...invalidEmailValues, watch('email')])
          trigger('email')
          errorMessages.push(t('common:invite.valueExists', { field: 'email' }))
        }
      }
      setToast({
        text: errorMessages.join(', ') || t('common:serverError'),
        type: 'error',
      })
    },
  })

  const onSubmit = handleSubmit(async (createPatientArgs, event) => {
    event?.stopPropagation()
    const activePatientLink = patientList.find(
      (patient) =>
        patient.isActive && patient?.tajNumber === createPatientArgs.tajNumber
    )

    if (activePatientLink) {
      history.push(`/${urlUserTypeSegment}/patients/${activePatientLink.id}`)
      return
    }

    setShowError(false)
    delete createPatientArgs.patientSigned
    const doctorUserIds = (createPatientArgs.assistantOwnDoctors ?? [])
      .slice()
      .map((doctor) => doctor.userId)

    if (!patientHasProfile) {
      delete createPatientArgs.assistantOwnDoctors
      return await sendInvite({
        variables: {
          createPatientArgs,
          doctorUserIds,
        },
      })
    }

    if (!profileToAdd) {
      return
    }

    return await sendExistinginvite({
      variables: {
        patientId: profileToAdd.id,
        doctorUserIds,
      },
    })
  })

  const required = {
    value: true,
    message: t('messages:warnings.required'),
  }

  const handleTajClear = () => {
    reset()
  }

  const DoctorSelectorComponent = () =>
    userType === UserType.Assistant ? (
      <Box my={1}>
        <DoctorSelector
          label={t('patients:addToDoctors')}
          name="assistantOwnDoctors"
          doctorListFilterData={{ assistantId: profile?.id ?? '' }}
          required
        />
      </Box>
    ) : null

  return (
    <Dialog
      open={isOpen}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose()
        }
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <DialogTitle>{t('patients:addPatient')}</DialogTitle>

          <DialogContent sx={{ width: { sm: 400 } }}>
            <Box mb={3}>
              <DialogContentText>
                {t('patients:addDoctorPatient')}
              </DialogContentText>
            </Box>

            <Box my={1}>
              <Typography variant="subtitle2">{t('patients:taj')}</Typography>
              <Controller
                control={control}
                name={'tajNumber'}
                rules={{ required }}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    onChange={(event) => {
                      field.onChange(event)
                      setShowError(true)
                    }}
                    value={field.value}
                    onBlur={field.onBlur}
                    autoFocus
                    autoComplete="off"
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      endAdornment: searchingProfile ? (
                        <InputAdornment position="end">
                          <Loading size={14} />
                        </InputAdornment>
                      ) : isTajValid ? (
                        <InputAdornment position="end">
                          <CheckIcon color="inherit" fontSize="inherit" />
                        </InputAdornment>
                      ) : isInvalidTaj ? (
                        <InputAdornment position="end" onClick={handleTajClear}>
                          <ClearIcon color="inherit" fontSize="inherit" />
                        </InputAdornment>
                      ) : undefined,
                    }}
                  />
                )}
              />
            </Box>

            <Collapse in={isInvalidTaj || isTajValid}>
              {showError && (
                <Box ml={0.5}>
                  {isTajValid && (
                    <Typography variant="subtitle2" color="primary">
                      {t('patients:validTaj', { tajNumber: validTajNumber })}
                    </Typography>
                  )}
                  {!isTajValid && (
                    <Typography variant="subtitle2" color="error">
                      {tajDigitCount === 9 && t('patients:invalidTaj')}
                      {tajDigitCount !== 9 && t('patients:invalidTajlength')}
                    </Typography>
                  )}
                </Box>
              )}
            </Collapse>

            <Collapse in={patientHasProfile}>
              <Box mt={2} ml={0.5}>
                <Typography variant="body1" color="textSecondary">
                  {t('patients:betmenProfileFound')}
                </Typography>

                <Box mt={1} display="flex" alignItems="center">
                  <Box px={1} fontSize={32} display="flex">
                    <PersonIcon fontSize="inherit" />
                  </Box>
                  <Box>
                    <Typography variant="subtitle2">
                      {t('common:formattedNameFull', profileToAdd)}
                    </Typography>
                    <Typography variant="body2">
                      {t('common:dateFormatted', {
                        date: profileToAdd?.birthDate,
                      })}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <DoctorSelectorComponent />
            </Collapse>

            <Collapse in={isSaving || isExistingSaving}>
              <Box my={5}>
                <Loading />
              </Box>
            </Collapse>
            <Collapse in={isNewProfile && !isSaving} unmountOnExit>
              <Box mt={2}>
                <Typography variant="body1" color="textSecondary">
                  {t('patients:inviteToBetmen')}
                </Typography>
              </Box>

              <DoctorSelectorComponent />

              <Box my={1}>
                <Typography variant="subtitle2">
                  {t('patients:birthdate')}
                </Typography>
                <TextField
                  {...register('birthDate', {
                    required,
                    min: {
                      value: '1900-01-01',
                      message: t('messages:warnings.invalidDate'),
                    },
                  })}
                  type="date"
                  fullWidth
                  error={!!errors.birthDate}
                  helperText={errors.birthDate?.message}
                  variant="outlined"
                  InputProps={{
                    inputProps: {
                      min: '1900-01-01',
                      max: dayjs().format('YYYY-MM-DD'),
                    },
                  }}
                />
              </Box>

              <Box my={1}>
                <Typography variant="subtitle2">
                  {t('patients:gender')}
                </Typography>
                <FormControl error={!!errors.gender}>
                  <Controller
                    name="gender"
                    control={control}
                    rules={{ required }}
                    render={({ field }) => (
                      <RadioGroup {...field}>
                        <Box display="flex">
                          <FormControlLabel
                            value="f"
                            control={<Radio />}
                            label={t('patients:genders.f')}
                          />
                          <FormControlLabel
                            value="m"
                            control={<Radio />}
                            label={t('patients:genders.m')}
                          />
                        </Box>
                      </RadioGroup>
                    )}
                  />
                  <FormHelperText>{errors.gender?.message}</FormHelperText>
                </FormControl>
              </Box>

              <Box my={1}>
                <Typography variant="subtitle2">
                  {t('patients:title')}
                </Typography>
                <TextField
                  {...register('title')}
                  error={!!errors.title}
                  helperText={errors.title?.message}
                  fullWidth
                  variant="outlined"
                />
              </Box>

              <Box my={1}>
                <Typography variant="subtitle2">
                  {t('patients:lastName')}
                </Typography>
                <TextField
                  {...register('lastName', { required })}
                  error={!!errors.lastName}
                  helperText={errors.lastName?.message}
                  fullWidth
                  variant="outlined"
                />
              </Box>

              <Box my={1}>
                <Typography variant="subtitle2">
                  {t('patients:firstName')}
                </Typography>

                <TextField
                  {...register('firstName', { required })}
                  error={!!errors.firstName}
                  helperText={errors.firstName?.message}
                  fullWidth
                  variant="outlined"
                />
              </Box>

              <Box my={1}>
                <Typography variant="subtitle2">{t('common:email')}</Typography>
                <TextField
                  {...register('email', {
                    required: !patientHasProfile,
                    validate: (value) => !invalidEmailValues.includes(value),
                    pattern: patientHasProfile
                      ? undefined
                      : {
                          value: ValidEmailPattern,
                          message: 'messages:warnings.notEmail',
                        },
                  })}
                  type="email"
                  error={!!errors.email}
                  helperText={
                    errors.email?.message ? t(errors.email?.message) : undefined
                  }
                  fullWidth
                  variant="outlined"
                />
              </Box>

              <Box my={1}>
                <Typography variant="subtitle2">{t('common:phone')}</Typography>
                <TextField
                  {...register('phone', {
                    required,
                    pattern: {
                      value: ValidPhonePattern,
                      message: 'messages:warnings.notPhone',
                    },
                  })}
                  type="phone"
                  fullWidth
                  placeholder="+36 1 234 5678 #4433"
                  error={!!errors.phone}
                  helperText={
                    errors.phone?.message ? t(errors.phone?.message) : undefined
                  }
                  variant="outlined"
                />
              </Box>

              <Box my={1}>
                <FormControl error={!!errors.patientSigned}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...register('patientSigned', { required })}
                        color="primary"
                      />
                    }
                    label={t('patients:patientSigned')}
                  />
                  <FormHelperText>
                    {errors.patientSigned?.message}
                  </FormHelperText>
                </FormControl>
              </Box>
            </Collapse>
          </DialogContent>

          <DialogActions>
            <Button
              disabled={isSaving}
              variant="outlined"
              onClick={handleClose}
            >
              {t('common:cancel')}
            </Button>

            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={
                !isValid || isSaving || (!patientHasProfile && !isNewProfile)
              }
            >
              {!!doctorOwnProfile
                ? t('common:choose')
                : isNewProfile
                ? t('patients:invitePatientButton')
                : t('patients:addPatient')}
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  )
}

export { AddPatientModal }
