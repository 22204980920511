import { Autocomplete, TextField, TextFieldProps } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form'
import { useBnoCodesData } from '../../../common/hooks/useBnoCodesData'
import { useTranslation } from '../../hooks/helper/useTranslation'
import { BnoCodeChore } from '../../../models/graphql'
import { useDebounce } from '../../../common/hooks/helper/useDebounce'
import { BoxWithLabel } from '../BoxWithLabel'

export type BnoCode = Omit<BnoCodeChore, '__typename'>

type Props = TextFieldProps & {
  name: string
  label?: string
  disabled?: boolean
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >
  clearInitialErrors?: () => void
  onChange?: (data: BnoCode[]) => void
  exclude?: string[]
}

export const BnoCodeSelector: FC<Props> = ({
  name,
  label,
  disabled,
  rules,
  clearInitialErrors,
  exclude,
  ...props
}) => {
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState('')
  const searchTerm = useDebounce(inputValue)
  const { control } = useFormContext()

  const { bnoCodes, loading, refetch } = useBnoCodesData({}, exclude)

  useEffect(() => {
    refetch({ searchTerm })
  }, [refetch, searchTerm])

  return (
    <BoxWithLabel label={label}>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field }) => (
          <Autocomplete<BnoCode, true, true>
            {...field}
            disabled={disabled}
            multiple
            disableClearable
            loading={loading}
            options={bnoCodes}
            onChange={(_, data) => {
              field.onChange(data)
              clearInitialErrors && clearInitialErrors()
              props.onChange && props.onChange(data)
            }}
            onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(item) => `${item.code}`}
            groupBy={(item) => item.code.slice(0, 3)}
            filterOptions={(x) => x}
            data-cy="BnoCodeSelector-Autocomplete"
            renderInput={(params) => (
              <TextField
                {...params}
                {...props}
                placeholder={t('common:search')}
                variant="outlined"
                name={name}
              />
            )}
            renderOption={(renderProps, item) => (
              <li {...renderProps}>{`${item.code} - ${item.title}`}</li>
            )}
          />
        )}
      />
    </BoxWithLabel>
  )
}
