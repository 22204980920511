import { Box, Stack, Typography, useTheme } from '@mui/material'
import { isFunction } from 'lodash'
import React from 'react'
import { useColorPalette } from '../../hooks/helper/useColor'
import { useBetmenPage } from '../BetmenPage'
import { Loading } from '../Loading'

export interface ScrollablePanelProps {
  title?: string
  isLoading?: boolean
  scrollableRef?: React.MutableRefObject<HTMLDivElement | null>
  headerRightContent?: React.ReactNode
  extraHeaderContent?: React.ReactNode
  filters?: React.ReactNode
  tabs?: React.ReactNode
  withoutPadding?: boolean
  containerComponent?: React.ElementType
  onSubmit?: () => void
  smoothScroll?: boolean
}

const ContentWrapper: React.FC<{
  withContainer: boolean
  scrollableRef?: React.MutableRefObject<HTMLDivElement | null>
  smoothScroll?: boolean
}> = ({ withContainer, scrollableRef, children }) => {
  const colorPalette = useColorPalette()
  const { setScrollPositionY } = useBetmenPage()

  return (
    <Box
      p={withContainer ? { xs: 1, md: 2 } : {}}
      flexGrow={1}
      overflow="auto"
      style={{ scrollBehavior: 'smooth' }}
      ref={scrollableRef}
      sx={{ background: colorPalette.grey[200] }}
      onScroll={(e) =>
        isFunction(setScrollPositionY) &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setScrollPositionY((e.target as any).scrollTop)
      }
    >
      {children}
    </Box>
  )
}

const ScrollablePanel: React.FC<ScrollablePanelProps> = ({
  title,
  headerRightContent,
  extraHeaderContent,
  filters,
  tabs,
  isLoading,
  withoutPadding,
  scrollableRef,
  children,
  containerComponent,
  onSubmit,
  smoothScroll,
}) => {
  const theme = useTheme()

  return (
    <Box
      component={containerComponent}
      display="flex"
      flexDirection="column"
      height="100%"
      width="100%"
      onSubmit={onSubmit}
    >
      {(title || headerRightContent) && (
        <Box
          px={{ xs: 2, md: 3 }}
          pt={1.375}
          pb={1.5}
          flexShrink={0}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderBottom={`1px solid ${theme.palette.common.black}20`}
        >
          <Typography color={theme.palette.common.black} variant="h5">
            {title}
          </Typography>

          <Stack marginY="-4px">{tabs}</Stack>

          {headerRightContent}
        </Box>
      )}

      {extraHeaderContent}
      <Box display="flex" flexDirection="row" flex={1} overflow="hidden">
        {filters}
        <ContentWrapper
          smoothScroll={smoothScroll}
          scrollableRef={scrollableRef}
          withContainer={!withoutPadding}
        >
          {isLoading && (
            <Box display="flex" height="100%" alignItems="center">
              <Loading />
            </Box>
          )}
          {children}
        </ContentWrapper>
      </Box>
    </Box>
  )
}

export { ScrollablePanel }
