import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'

import { Delete as DeleteIcon } from '@mui/icons-material'
import React, { useState } from 'react'
import {
  BetmenListFilter,
  ConfirmDialog,
  ConfirmDialogValue,
} from '../../../../common/components'
import { useTranslation } from 'react-i18next'
import { useColorPalette } from '../../../../common/hooks'
import { UsePatientsListReturn } from '../../../../common/hooks/usePatientsList'
import { useNotifications } from '../../../../common/providers'
import { useDeleteDoctorOwnPatient } from '../hooks/useDeleteDoctorOwnPatient'
import { PatientFilter } from '../../../../models/graphql'

export interface PatientListActionsProps
  extends Pick<UsePatientsListReturn, 'data'> {
  filterInput: PatientFilter
  setFilterInput: (filter: PatientFilter) => void
  selectedIds: string[]
  setSelectedIds: (ids: string[]) => void
  handleFilterOpen: () => void
}

const PatientListActions: React.FC<PatientListActionsProps> = ({
  data,
  filterInput,
  setFilterInput,
  selectedIds,
  setSelectedIds,
  handleFilterOpen,
}) => {
  const { t } = useTranslation()
  const colorPalette = useColorPalette()
  const { setSuccessToast } = useNotifications()

  const handleSearch = (searchInput: string) => {
    setFilterInput({
      ...filterInput,
      nameOrTAJ: searchInput,
    })
  }
  const [confirmDeletePatients, setConfirmDeletePatients] =
    useState<ConfirmDialogValue>(false)

  const clearSelectedIds = () => setSelectedIds([])

  const handleSelectAll = () => {
    const selectablePatientList = data.filter((patient) => patient.isActive)
    if (selectedIds.length === 0) {
      setSelectedIds(selectablePatientList.map((patient) => patient.id))
    } else {
      clearSelectedIds()
    }
  }

  const [deleteDoctorsOwnPatient] = useDeleteDoctorOwnPatient()

  const handleDelete = async () => {
    if (selectedIds.length) {
      Promise.all(
        selectedIds.map(
          async (patientId) =>
            await deleteDoctorsOwnPatient({
              variables: {
                patientId,
              },
            })
        )
      )

      setSuccessToast('successDeletePatients')
      clearSelectedIds()
    }
  }

  return (
    <>
      <Stack direction="row" alignItems="center" mb={1} spacing={1}>
        <BetmenListFilter
          searchTerm={filterInput.nameOrTAJ ?? ''}
          searchPlaceholder={t('patients:searchPatient')}
          handleSearch={(searchTerm) => handleSearch(searchTerm ?? '')}
        />
        <Button
          size="small"
          color="primary"
          variant="text"
          onClick={handleFilterOpen}
          sx={{ paddingX: '16px' }}
        >
          {t('patients:detailedSearch')}
        </Button>
      </Stack>

      <Grid
        container
        direction="row"
        display="flex"
        alignItems="center"
        sx={{
          backgroundColor: colorPalette.common.white,
          borderRadius: 1,
        }}
        mb={1}
        px={2}
      >
        <Grid item xs={1} display="flex" justifyContent="flex-end" pr={2}>
          <Checkbox
            checked={data.length > 0 && selectedIds.length === data.length}
            indeterminate={
              selectedIds.length > 0 && selectedIds.length !== data.length
            }
            onChange={handleSelectAll}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
          />
        </Grid>

        <Grid item xs={11}>
          <Tooltip title={t('patients:delete') as string}>
            <IconButton
              onClick={() => setConfirmDeletePatients(true)}
              size="large"
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Typography variant="smallItalic" ml={1}>
            {t('patients:selectedPatients', {
              count: selectedIds.length,
            })}
          </Typography>
        </Grid>
      </Grid>

      <ConfirmDialog
        isAlertingDialog
        valueState={[confirmDeletePatients, setConfirmDeletePatients]}
        text={t(`patients:confirmDeletePatients`)}
        onAccept={() => handleDelete()}
      />
    </>
  )
}

export { PatientListActions }
