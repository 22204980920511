import React, { useEffect } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material'
import { BnoCodeSelector } from '../../../../common/components/selectors/BnoCodeSelector'
import { useSelectedLanguage } from '../../../../common/hooks/useSelectedLanguage'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import { useHistory } from 'react-router-dom'
import { useTreatmentCreate } from '../hooks/useTreatmentCreate'
import { useTreatmentUpdate } from '../hooks/useTreatmentUpdate'
import {
  BnoCodeChore,
  CreateTreatmentInput,
  treatmentChore,
  UpdateTreatmentInput,
} from '../../../../models/graphql'
import { FormProvider, useForm } from 'react-hook-form'
import { FRAGMENT_TREATMENT_CHORE } from '../../../../operations/fragments'

type FormValues = {
  title: string
  description: string
  bnoCodes: Omit<BnoCodeChore, '__typename'>[]
}

type Props = {
  isOpen: boolean
  onClose: () => void
  patientId: string
  treatment?: treatmentChore
}

const AddTreatmentModal: React.FC<Props> = (props) => {
  const { isOpen, onClose, patientId, treatment } = props
  const history = useHistory()
  const { t } = useTranslation()
  const selectedLanguage = useSelectedLanguage()

  const [createTreatment, { loading }] = useTreatmentCreate({
    onCompleted: (data) => {
      history.push(`/doctor/patients/${patientId}/${data.createTreatment.id}`)
      onClose()
    },
  })

  const [updateTreatment] = useTreatmentUpdate({
    onCompleted: onClose,
    update(cache, { data }) {
      if (!data) {
        return
      }
      cache.modify({
        id: cache.identify({ __typename: 'PatientProfile', id: patientId }),
        fields: {
          treatments(existingTreatmentRefs = []) {
            const newTreatmentRef = cache.writeFragment({
              data: data.updateTreatment,
              fragment: FRAGMENT_TREATMENT_CHORE,
              fragmentName: 'treatmentChore',
            })
            return [...existingTreatmentRefs, newTreatmentRef]
          },
        },
      })
    },
  })

  const methods = useForm<FormValues>({
    defaultValues: {
      title: treatment?.title?.[selectedLanguage] ?? '',
      description: treatment?.description?.[selectedLanguage] ?? '',
      bnoCodes: treatment?.bnoCodes ?? [],
    },
  })

  const {
    formState: { errors },
    register,
    handleSubmit,
    reset,
  } = methods

  const onSubmit = handleSubmit((value) => {
    if (!treatment) {
      const treatmentData: CreateTreatmentInput = {
        patientId,
        bnoCodeIds: value.bnoCodes.map((item) => item.id),
        customTreatmentTitle: {
          hu: '',
          en: '',
        },
        customTreatmentDescription: {
          hu: '',
          en: '',
        },
      }
      if (value.title && treatmentData.customTreatmentTitle) {
        treatmentData.customTreatmentTitle.hu = value.title
        treatmentData.customTreatmentTitle.en = value.title
      }
      if (value.description && treatmentData.customTreatmentDescription) {
        treatmentData.customTreatmentDescription.hu = value.description
        treatmentData.customTreatmentDescription.en = value.description
      }
      return createTreatment({
        variables: {
          treatmentData,
        },
      })
    }

    const treatmentData: Omit<UpdateTreatmentInput, 'status'> = {
      treatmentId: treatment.id,
      bnoCodeIds: value.bnoCodes.map((item) => item.id),
    }

    if (value.title) {
      treatmentData.title = {
        en: treatment.title.en,
        hu: treatment.title.hu,
        [selectedLanguage]: value.title,
      }
    }
    if (value.description) {
      treatmentData.description = {
        en: treatment.description?.en ?? '',
        hu: treatment.description?.hu ?? '',
        [selectedLanguage]: value.description,
      }
    }

    return updateTreatment({
      variables: { treatmentData },
    })
  })

  const required = {
    value: true,
    message: t('messages:warnings.required'),
  }

  useEffect(() => {
    reset({
      title: treatment?.title?.[selectedLanguage] ?? '',
      description: treatment?.description?.[selectedLanguage] ?? '',
      bnoCodes: treatment?.bnoCodes ?? [],
    })
  }, [reset, selectedLanguage, treatment])

  return (
    <FormProvider {...methods}>
      <Dialog
        open={isOpen}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            onClose()
          }
        }}
      >
        <DialogTitle>{t('treatment:doctor.assignDialogTitle')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('treatment:doctor.addSchemaDialogText')}
          </DialogContentText>
          <Box>
            <Box mb={2}>
              <TextField
                {...register('title', { required })}
                size="small"
                placeholder={t('treatment:doctor.title')}
                variant="outlined"
                error={!!errors.title}
                helperText={errors.title?.message}
                fullWidth
                data-cy="AddTreatmentModal-TextField-title"
              />
            </Box>
            <Box mb={2}>
              <BnoCodeSelector
                name="bnoCodes"
                label={t('treatment:protocol.bnoCode')}
              />
            </Box>
            <Box mb={2}>
              <TextField
                {...register('description', { required })}
                size="small"
                multiline
                variant="outlined"
                maxRows={7}
                placeholder={t('treatment:doctor.description')}
                error={!!errors.description}
                helperText={errors.description?.message}
                minRows={7}
                fullWidth
                data-cy="AddTreatmentModal-TextField-description"
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            {t('common:cancel')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={onSubmit}
            disabled={loading}
            data-cy="AddTreatmentModal-Button-addTreatment"
          >
            {t('common:save')}
          </Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  )
}

export { AddTreatmentModal }
