import React from 'react'
import { PatientListItemType } from '../types/patientList.types'
import {
  Avatar,
  BetmenListItemCard,
  BetmenListItemCardCell,
  Status,
  StatusEnum,
} from '../../../../common/components'
import { useHistory } from 'react-router-dom'
import { useTranslation, useUserType } from '../../../../common/hooks'
import { Checkbox, Typography } from '@mui/material'
import { formatTAJ } from '../../../../utils'

interface PatientListItemProps {
  patient: PatientListItemType
  selectedPatientIds: string[]
  setSelectedPatientIds: (ids: string[]) => void
}
const PatientListItem: React.FC<PatientListItemProps> = ({
  patient,
  selectedPatientIds,
  setSelectedPatientIds,
}) => {
  const { t } = useTranslation()

  const history = useHistory()
  const userType = useUserType()

  const handlePatientClick = () => {
    if (patient.isActive) {
      history.push(`/${userType}/patients/${patient.id}`)
    }
  }

  const handlePatientCheckBoxClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation()
    if (selectedPatientIds.includes(patient.id)) {
      setSelectedPatientIds(
        selectedPatientIds.filter((id) => id !== patient.id)
      )
    } else {
      setSelectedPatientIds([...selectedPatientIds, patient.id])
    }
  }

  return (
    <BetmenListItemCard
      data-cy={`Patient-Box-${patient.tajNumber}`}
      click={() => handlePatientClick()}
    >
      <BetmenListItemCardCell justifyContent="flex-end" display="flex">
        {patient.isActive && (
          <Checkbox
            checked={selectedPatientIds.includes(patient.id)}
            onClick={(e) => handlePatientCheckBoxClick(e)}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
          />
        )}
      </BetmenListItemCardCell>
      <BetmenListItemCardCell display="flex" alignItems="center">
        <Avatar
          firstName={patient.firstName}
          lastName={patient.lastName}
          size="tiny"
        />
        <Typography variant="body2">
          {t('common:formattedNameFull', {
            firstName: patient.firstName,
            lastName: patient.lastName,
            title: patient.title,
          })}
        </Typography>
      </BetmenListItemCardCell>
      <BetmenListItemCardCell>
        {formatTAJ(patient.tajNumber)}
      </BetmenListItemCardCell>
      <BetmenListItemCardCell>
        {t('common:dateFormatted', {
          date: patient.birthDate,
        })}
      </BetmenListItemCardCell>
      <BetmenListItemCardCell>
        <Status
          status={patient.isActive ? StatusEnum.Active : StatusEnum.Deleted}
          title={patient.isActive ? undefined : t('patients:removed')}
        />
      </BetmenListItemCardCell>
    </BetmenListItemCard>
  )
}

export { PatientListItem }
