/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createAppointment
// ====================================================

export interface createAppointment_createAppointment_appointmentInfo {
  __typename: "AppointmentInfo";
  id: string;
}

export interface createAppointment_createAppointment_institution_rooms_name {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface createAppointment_createAppointment_institution_rooms_address {
  __typename: "OptionalMultiLangText";
  hu: string | null;
  en: string | null;
}

export interface createAppointment_createAppointment_institution_rooms_description {
  __typename: "OptionalMultiLangText";
  hu: string | null;
  en: string | null;
}

export interface createAppointment_createAppointment_institution_rooms {
  __typename: "Room";
  id: string;
  eesztUnitId: string | null;
  name: createAppointment_createAppointment_institution_rooms_name;
  address: createAppointment_createAppointment_institution_rooms_address | null;
  mapLink: string | null;
  description: createAppointment_createAppointment_institution_rooms_description | null;
  isActive: boolean;
  neakCode: string | null;
}

export interface createAppointment_createAppointment_institution {
  __typename: "Institution";
  id: string;
  eesztId: string | null;
  name: string;
  addressCounty: string | null;
  addressPostalCode: string | null;
  addressCity: string | null;
  addressAddress: string | null;
  concatenatedAddress: string | null;
  phoneNumber: string | null;
  email: string | null;
  website: string | null;
  mapLink: string | null;
  neak: string | null;
  isActive: boolean;
  isOwn: boolean;
  rooms: createAppointment_createAppointment_institution_rooms[];
}

export interface createAppointment_createAppointment_room_name {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface createAppointment_createAppointment_room_address {
  __typename: "OptionalMultiLangText";
  hu: string | null;
  en: string | null;
}

export interface createAppointment_createAppointment_room_description {
  __typename: "OptionalMultiLangText";
  hu: string | null;
  en: string | null;
}

export interface createAppointment_createAppointment_room {
  __typename: "Room";
  id: string;
  eesztUnitId: string | null;
  name: createAppointment_createAppointment_room_name;
  address: createAppointment_createAppointment_room_address | null;
  mapLink: string | null;
  description: createAppointment_createAppointment_room_description | null;
  isActive: boolean;
  neakCode: string | null;
}

export interface createAppointment_createAppointment {
  __typename: "Appointment";
  id: string;
  proposedDate: any;
  eesztBookingId: string | null;
  isBetmenBooked: boolean;
  appointmentInfo: createAppointment_createAppointment_appointmentInfo | null;
  institution: createAppointment_createAppointment_institution | null;
  room: createAppointment_createAppointment_room | null;
  status: AppointmentStatus;
  duration: string;
}

export interface createAppointment {
  createAppointment: createAppointment_createAppointment;
}

export interface createAppointmentVariables {
  appointmentInput: AppointmentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateAppointment
// ====================================================

export interface updateAppointment_updateAppointment_Appointment_institution {
  __typename: "Institution";
  id: string;
}

export interface updateAppointment_updateAppointment_Appointment_room {
  __typename: "Room";
  id: string;
}

export interface updateAppointment_updateAppointment_Appointment {
  __typename: "Appointment";
  id: string;
  proposedDate: any;
  institution: updateAppointment_updateAppointment_Appointment_institution | null;
  room: updateAppointment_updateAppointment_Appointment_room | null;
  status: AppointmentStatus;
}

export interface updateAppointment_updateAppointment_CalculatedSchedule_schedule_date {
  __typename: "StartEndDates";
  end: any | null;
  start: any;
}

export interface updateAppointment_updateAppointment_CalculatedSchedule_schedule_oldDate {
  __typename: "StartEndDates";
  end: any | null;
  start: any;
}

export interface updateAppointment_updateAppointment_CalculatedSchedule_schedule_appointmentInfo {
  __typename: "AppointmentInfo";
  id: string;
}

export interface updateAppointment_updateAppointment_CalculatedSchedule_schedule_doctorTitle {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface updateAppointment_updateAppointment_CalculatedSchedule_schedule {
  __typename: "PlainTreatmentElement";
  date: updateAppointment_updateAppointment_CalculatedSchedule_schedule_date;
  isModified: boolean | null;
  oldDate: updateAppointment_updateAppointment_CalculatedSchedule_schedule_oldDate | null;
  id: string;
  appointmentInfo: updateAppointment_updateAppointment_CalculatedSchedule_schedule_appointmentInfo;
  doctorTitle: updateAppointment_updateAppointment_CalculatedSchedule_schedule_doctorTitle | null;
}

export interface updateAppointment_updateAppointment_CalculatedSchedule_brokenDependingFrom_from_info_doctorTitle {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface updateAppointment_updateAppointment_CalculatedSchedule_brokenDependingFrom_from_info {
  __typename: "AppointmentInfo";
  doctorTitle: updateAppointment_updateAppointment_CalculatedSchedule_brokenDependingFrom_from_info_doctorTitle;
}

export interface updateAppointment_updateAppointment_CalculatedSchedule_brokenDependingFrom_from {
  __typename: "Appointment";
  info: updateAppointment_updateAppointment_CalculatedSchedule_brokenDependingFrom_from_info | null;
}

export interface updateAppointment_updateAppointment_CalculatedSchedule_brokenDependingFrom {
  __typename: "AppointmentDependency";
  id: string;
  type: AppointmentDependencyTypes;
  distance: string;
  from: updateAppointment_updateAppointment_CalculatedSchedule_brokenDependingFrom_from;
}

export interface updateAppointment_updateAppointment_CalculatedSchedule_brokenDependants_to_info_doctorTitle {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface updateAppointment_updateAppointment_CalculatedSchedule_brokenDependants_to_info {
  __typename: "AppointmentInfo";
  doctorTitle: updateAppointment_updateAppointment_CalculatedSchedule_brokenDependants_to_info_doctorTitle;
}

export interface updateAppointment_updateAppointment_CalculatedSchedule_brokenDependants_to {
  __typename: "Appointment";
  info: updateAppointment_updateAppointment_CalculatedSchedule_brokenDependants_to_info | null;
}

export interface updateAppointment_updateAppointment_CalculatedSchedule_brokenDependants {
  __typename: "AppointmentDependency";
  id: string;
  type: AppointmentDependencyTypes;
  distance: string;
  to: updateAppointment_updateAppointment_CalculatedSchedule_brokenDependants_to;
}

export interface updateAppointment_updateAppointment_CalculatedSchedule {
  __typename: "CalculatedSchedule";
  schedule: updateAppointment_updateAppointment_CalculatedSchedule_schedule[];
  brokenDependingFrom: updateAppointment_updateAppointment_CalculatedSchedule_brokenDependingFrom[];
  brokenDependants: updateAppointment_updateAppointment_CalculatedSchedule_brokenDependants[];
}

export type updateAppointment_updateAppointment = updateAppointment_updateAppointment_Appointment | updateAppointment_updateAppointment_CalculatedSchedule;

export interface updateAppointment {
  updateAppointment: updateAppointment_updateAppointment;
}

export interface updateAppointmentVariables {
  updateInput: AppointmentUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAppointmentWithDeps
// ====================================================

export interface UpdateAppointmentWithDeps_updateAppointmentWithDeps {
  __typename: "Appointment";
  createdAt: any;
}

export interface UpdateAppointmentWithDeps {
  updateAppointmentWithDeps: UpdateAppointmentWithDeps_updateAppointmentWithDeps;
}

export interface UpdateAppointmentWithDepsVariables {
  updateInput: AppointmentUpdateInput;
  reschedule: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateAppointmentGoodToKnow
// ====================================================

export interface updateAppointmentGoodToKnow_updateAppointmentGoodToKnow {
  __typename: "Appointment";
  id: string;
}

export interface updateAppointmentGoodToKnow {
  updateAppointmentGoodToKnow: updateAppointmentGoodToKnow_updateAppointmentGoodToKnow;
}

export interface updateAppointmentGoodToKnowVariables {
  appointmentId: string;
  updateGoodToKnowInput: AppointmentUpdateGoodToKnowInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateAppointmentOeno
// ====================================================

export interface updateAppointmentOeno_updateAppointmentOeno {
  __typename: "Appointment";
  id: string;
}

export interface updateAppointmentOeno {
  updateAppointmentOeno: updateAppointmentOeno_updateAppointmentOeno;
}

export interface updateAppointmentOenoVariables {
  appointmentId: string;
  oeno: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteAppointment
// ====================================================

export interface deleteAppointment_deleteAppointment {
  __typename: "Appointment";
  id: string;
  eesztBookingId: string | null;
}

export interface deleteAppointment {
  deleteAppointment: deleteAppointment_deleteAppointment;
}

export interface deleteAppointmentVariables {
  appointmentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteAppointments
// ====================================================

export interface deleteAppointments_deleteAppointments {
  __typename: "Appointment";
  id: string;
}

export interface deleteAppointments {
  deleteAppointments: deleteAppointments_deleteAppointments[];
}

export interface deleteAppointmentsVariables {
  appointmentIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getDashboardAppointments
// ====================================================

export interface getDashboardAppointments_getDashboardAppointments_appointments_room {
  __typename: "Room";
  id: string;
}

export interface getDashboardAppointments_getDashboardAppointments_appointments_info_doctorTitle {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface getDashboardAppointments_getDashboardAppointments_appointments_info {
  __typename: "AppointmentInfo";
  id: string;
  doctorTitle: getDashboardAppointments_getDashboardAppointments_appointments_info_doctorTitle;
}

export interface getDashboardAppointments_getDashboardAppointments_appointments_doctorProfile {
  __typename: "DoctorProfile";
  title: string;
  firstName: string;
  lastName: string;
}

export interface getDashboardAppointments_getDashboardAppointments_appointments_treatment_title {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface getDashboardAppointments_getDashboardAppointments_appointments_treatment_patient {
  __typename: "PatientProfile";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  tajNumber: string;
  phone: string;
}

export interface getDashboardAppointments_getDashboardAppointments_appointments_treatment {
  __typename: "Treatment";
  id: string;
  status: TreatmentStatus | null;
  title: getDashboardAppointments_getDashboardAppointments_appointments_treatment_title;
  patient: getDashboardAppointments_getDashboardAppointments_appointments_treatment_patient;
}

export interface getDashboardAppointments_getDashboardAppointments_appointments {
  __typename: "Appointment";
  id: string;
  status: AppointmentStatus;
  isBetmenBooked: boolean;
  eesztBookingId: string | null;
  room: getDashboardAppointments_getDashboardAppointments_appointments_room | null;
  proposedDate: any;
  info: getDashboardAppointments_getDashboardAppointments_appointments_info | null;
  doctorProfile: getDashboardAppointments_getDashboardAppointments_appointments_doctorProfile;
  treatment: getDashboardAppointments_getDashboardAppointments_appointments_treatment;
}

export interface getDashboardAppointments_getDashboardAppointments {
  __typename: "AppointmentList";
  appointments: getDashboardAppointments_getDashboardAppointments_appointments[];
  total: number;
}

export interface getDashboardAppointments {
  getDashboardAppointments: getDashboardAppointments_getDashboardAppointments;
}

export interface getDashboardAppointmentsVariables {
  startDate: string;
  endDate: string;
  paginationInput?: PaginationInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: triggerExpirePastAppointments
// ====================================================

export interface triggerExpirePastAppointments_triggerExpirePastAppointments {
  __typename: "Appointment";
  id: string;
}

export interface triggerExpirePastAppointments {
  triggerExpirePastAppointments: triggerExpirePastAppointments_triggerExpirePastAppointments[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createAppointmentDependency
// ====================================================

export interface createAppointmentDependency_createAppointmentDependency {
  __typename: "AppointmentDependency";
  id: string;
  distance: string;
  type: AppointmentDependencyTypes;
  isBroken: boolean;
  isCustom: boolean;
  fromId: string;
  toId: string;
}

export interface createAppointmentDependency {
  createAppointmentDependency: createAppointmentDependency_createAppointmentDependency;
}

export interface createAppointmentDependencyVariables {
  createAppointmentDependencyInput: CreateAppointmentDependencyInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateAppointmentDependency
// ====================================================

export interface updateAppointmentDependency_updateAppointmentDependency {
  __typename: "AppointmentDependency";
  id: string;
  distance: string;
  type: AppointmentDependencyTypes;
  isBroken: boolean;
  isCustom: boolean;
  fromId: string;
  toId: string;
}

export interface updateAppointmentDependency {
  updateAppointmentDependency: updateAppointmentDependency_updateAppointmentDependency;
}

export interface updateAppointmentDependencyVariables {
  updateAppointmentDependencyInput: UpdateAppointmentDependencyInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteAppointmentDependency
// ====================================================

export interface deleteAppointmentDependency_deleteAppointmentDependency {
  __typename: "AppointmentDependency";
  id: string;
}

export interface deleteAppointmentDependency {
  deleteAppointmentDependency: deleteAppointmentDependency_deleteAppointmentDependency;
}

export interface deleteAppointmentDependencyVariables {
  dependencyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listAppointmentInfos
// ====================================================

export interface listAppointmentInfos_listAppointmentInfos_patientTitle {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface listAppointmentInfos_listAppointmentInfos_doctorTodo {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface listAppointmentInfos_listAppointmentInfos_doctorTitle {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface listAppointmentInfos_listAppointmentInfos_goodToKnow {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface listAppointmentInfos_listAppointmentInfos_professions {
  __typename: "Profession";
  id: string;
  code: string;
  name: string;
}

export interface listAppointmentInfos_listAppointmentInfos {
  __typename: "AppointmentInfo";
  id: string;
  patientTitle: listAppointmentInfos_listAppointmentInfos_patientTitle;
  doctorTodo: listAppointmentInfos_listAppointmentInfos_doctorTodo;
  doctorTitle: listAppointmentInfos_listAppointmentInfos_doctorTitle;
  goodToKnow: listAppointmentInfos_listAppointmentInfos_goodToKnow;
  oeno: string | null;
  professions: listAppointmentInfos_listAppointmentInfos_professions[] | null;
  userId: string | null;
  eesztServiceId: string | null;
  beforeTreatmentNote: boolean | null;
}

export interface listAppointmentInfos {
  listAppointmentInfos: listAppointmentInfos_listAppointmentInfos[];
}

export interface listAppointmentInfosVariables {
  treatmentSchemaId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createAppointmentInfo
// ====================================================

export interface createAppointmentInfo_createAppointmentInfo_patientTitle {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface createAppointmentInfo_createAppointmentInfo_doctorTodo {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface createAppointmentInfo_createAppointmentInfo_doctorTitle {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface createAppointmentInfo_createAppointmentInfo_goodToKnow {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface createAppointmentInfo_createAppointmentInfo_professions {
  __typename: "Profession";
  id: string;
  code: string;
  name: string;
}

export interface createAppointmentInfo_createAppointmentInfo {
  __typename: "AppointmentInfo";
  id: string;
  patientTitle: createAppointmentInfo_createAppointmentInfo_patientTitle;
  doctorTodo: createAppointmentInfo_createAppointmentInfo_doctorTodo;
  doctorTitle: createAppointmentInfo_createAppointmentInfo_doctorTitle;
  goodToKnow: createAppointmentInfo_createAppointmentInfo_goodToKnow;
  oeno: string | null;
  professions: createAppointmentInfo_createAppointmentInfo_professions[] | null;
  userId: string | null;
  eesztServiceId: string | null;
  beforeTreatmentNote: boolean | null;
}

export interface createAppointmentInfo {
  createAppointmentInfo: createAppointmentInfo_createAppointmentInfo;
}

export interface createAppointmentInfoVariables {
  createAppointmentInfoInput: CreateAppointmentInfoInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateAppointmentInfo
// ====================================================

export interface updateAppointmentInfo_updateAppointmentInfo_patientTitle {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface updateAppointmentInfo_updateAppointmentInfo_doctorTodo {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface updateAppointmentInfo_updateAppointmentInfo_doctorTitle {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface updateAppointmentInfo_updateAppointmentInfo_goodToKnow {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface updateAppointmentInfo_updateAppointmentInfo_professions {
  __typename: "Profession";
  id: string;
  code: string;
  name: string;
}

export interface updateAppointmentInfo_updateAppointmentInfo {
  __typename: "AppointmentInfo";
  id: string;
  patientTitle: updateAppointmentInfo_updateAppointmentInfo_patientTitle;
  doctorTodo: updateAppointmentInfo_updateAppointmentInfo_doctorTodo;
  doctorTitle: updateAppointmentInfo_updateAppointmentInfo_doctorTitle;
  goodToKnow: updateAppointmentInfo_updateAppointmentInfo_goodToKnow;
  oeno: string | null;
  professions: updateAppointmentInfo_updateAppointmentInfo_professions[] | null;
  userId: string | null;
  eesztServiceId: string | null;
  beforeTreatmentNote: boolean | null;
}

export interface updateAppointmentInfo {
  updateAppointmentInfo: updateAppointmentInfo_updateAppointmentInfo;
}

export interface updateAppointmentInfoVariables {
  appointmentInfoInput: UpdateAppointmentInfoInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateScheduleAppointmentInfo
// ====================================================

export interface updateScheduleAppointmentInfo_updateScheduleAppointmentInfo_patientTitle {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface updateScheduleAppointmentInfo_updateScheduleAppointmentInfo_doctorTodo {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface updateScheduleAppointmentInfo_updateScheduleAppointmentInfo_doctorTitle {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface updateScheduleAppointmentInfo_updateScheduleAppointmentInfo_goodToKnow {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface updateScheduleAppointmentInfo_updateScheduleAppointmentInfo_professions {
  __typename: "Profession";
  id: string;
  code: string;
  name: string;
}

export interface updateScheduleAppointmentInfo_updateScheduleAppointmentInfo {
  __typename: "AppointmentInfo";
  id: string;
  patientTitle: updateScheduleAppointmentInfo_updateScheduleAppointmentInfo_patientTitle;
  doctorTodo: updateScheduleAppointmentInfo_updateScheduleAppointmentInfo_doctorTodo;
  doctorTitle: updateScheduleAppointmentInfo_updateScheduleAppointmentInfo_doctorTitle;
  goodToKnow: updateScheduleAppointmentInfo_updateScheduleAppointmentInfo_goodToKnow;
  oeno: string | null;
  professions: updateScheduleAppointmentInfo_updateScheduleAppointmentInfo_professions[] | null;
  userId: string | null;
  eesztServiceId: string | null;
  beforeTreatmentNote: boolean | null;
}

export interface updateScheduleAppointmentInfo {
  updateScheduleAppointmentInfo: updateScheduleAppointmentInfo_updateScheduleAppointmentInfo;
}

export interface updateScheduleAppointmentInfoVariables {
  scheduleId: string;
  appointmentInfoInput: UpdateScheduleAppointmentInfoInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteAppointmentInfo
// ====================================================

export interface deleteAppointmentInfo_deleteAppointmentInfo_patientTitle {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface deleteAppointmentInfo_deleteAppointmentInfo_doctorTodo {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface deleteAppointmentInfo_deleteAppointmentInfo_doctorTitle {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface deleteAppointmentInfo_deleteAppointmentInfo_goodToKnow {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface deleteAppointmentInfo_deleteAppointmentInfo_professions {
  __typename: "Profession";
  id: string;
  code: string;
  name: string;
}

export interface deleteAppointmentInfo_deleteAppointmentInfo {
  __typename: "AppointmentInfo";
  id: string;
  patientTitle: deleteAppointmentInfo_deleteAppointmentInfo_patientTitle;
  doctorTodo: deleteAppointmentInfo_deleteAppointmentInfo_doctorTodo;
  doctorTitle: deleteAppointmentInfo_deleteAppointmentInfo_doctorTitle;
  goodToKnow: deleteAppointmentInfo_deleteAppointmentInfo_goodToKnow;
  oeno: string | null;
  professions: deleteAppointmentInfo_deleteAppointmentInfo_professions[] | null;
  userId: string | null;
  eesztServiceId: string | null;
  beforeTreatmentNote: boolean | null;
}

export interface deleteAppointmentInfo {
  deleteAppointmentInfo: deleteAppointmentInfo_deleteAppointmentInfo;
}

export interface deleteAppointmentInfoVariables {
  appointmentInfoInput: DeleteAppointmentInfoInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAppointmentIcs
// ====================================================

export interface getAppointmentIcs_getAppointment {
  __typename: "Appointment";
  id: string;
  ics: string | null;
}

export interface getAppointmentIcs {
  getAppointment: getAppointmentIcs_getAppointment;
}

export interface getAppointmentIcsVariables {
  appointmentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPatientAppointment
// ====================================================

export interface getPatientAppointment_getAppointment_info_patientTitle {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface getPatientAppointment_getAppointment_info_goodToKnow {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface getPatientAppointment_getAppointment_info {
  __typename: "AppointmentInfo";
  patientTitle: getPatientAppointment_getAppointment_info_patientTitle;
  goodToKnow: getPatientAppointment_getAppointment_info_goodToKnow;
}

export interface getPatientAppointment_getAppointment_institution_rooms_name {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface getPatientAppointment_getAppointment_institution_rooms_address {
  __typename: "OptionalMultiLangText";
  hu: string | null;
  en: string | null;
}

export interface getPatientAppointment_getAppointment_institution_rooms_description {
  __typename: "OptionalMultiLangText";
  hu: string | null;
  en: string | null;
}

export interface getPatientAppointment_getAppointment_institution_rooms {
  __typename: "Room";
  id: string;
  eesztUnitId: string | null;
  name: getPatientAppointment_getAppointment_institution_rooms_name;
  address: getPatientAppointment_getAppointment_institution_rooms_address | null;
  mapLink: string | null;
  description: getPatientAppointment_getAppointment_institution_rooms_description | null;
  isActive: boolean;
  neakCode: string | null;
}

export interface getPatientAppointment_getAppointment_institution {
  __typename: "Institution";
  id: string;
  eesztId: string | null;
  name: string;
  addressCounty: string | null;
  addressPostalCode: string | null;
  addressCity: string | null;
  addressAddress: string | null;
  concatenatedAddress: string | null;
  phoneNumber: string | null;
  email: string | null;
  website: string | null;
  mapLink: string | null;
  neak: string | null;
  isActive: boolean;
  isOwn: boolean;
  rooms: getPatientAppointment_getAppointment_institution_rooms[];
}

export interface getPatientAppointment_getAppointment_room_name {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface getPatientAppointment_getAppointment_room_address {
  __typename: "OptionalMultiLangText";
  hu: string | null;
  en: string | null;
}

export interface getPatientAppointment_getAppointment_room_description {
  __typename: "OptionalMultiLangText";
  hu: string | null;
  en: string | null;
}

export interface getPatientAppointment_getAppointment_room {
  __typename: "Room";
  id: string;
  eesztUnitId: string | null;
  name: getPatientAppointment_getAppointment_room_name;
  address: getPatientAppointment_getAppointment_room_address | null;
  mapLink: string | null;
  description: getPatientAppointment_getAppointment_room_description | null;
  isActive: boolean;
  neakCode: string | null;
}

export interface getPatientAppointment_getAppointment_treatmentSchema_title {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface getPatientAppointment_getAppointment_treatmentSchema_description {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface getPatientAppointment_getAppointment_treatmentSchema {
  __typename: "TreatmentSchema";
  title: getPatientAppointment_getAppointment_treatmentSchema_title;
  description: getPatientAppointment_getAppointment_treatmentSchema_description | null;
}

export interface getPatientAppointment_getAppointment_treatment_firstAndLastDates {
  __typename: "FirstAndLastDates";
  firstAppointmentDate: any | null;
  lastAppointmentDate: any | null;
}

export interface getPatientAppointment_getAppointment_treatment_description {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface getPatientAppointment_getAppointment_treatment_title {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface getPatientAppointment_getAppointment_treatment_doctor {
  __typename: "DoctorProfile";
  id: string;
  title: string;
  firstName: string;
  lastName: string;
}

export interface getPatientAppointment_getAppointment_treatment {
  __typename: "Treatment";
  id: string;
  status: TreatmentStatus | null;
  firstAndLastDates: getPatientAppointment_getAppointment_treatment_firstAndLastDates | null;
  description: getPatientAppointment_getAppointment_treatment_description;
  title: getPatientAppointment_getAppointment_treatment_title;
  doctor: getPatientAppointment_getAppointment_treatment_doctor;
}

export interface getPatientAppointment_getAppointment_doctorProfile_user {
  __typename: "User";
  email: string;
  id: string;
}

export interface getPatientAppointment_getAppointment_doctorProfile_assistants_user {
  __typename: "User";
  id: string;
  email: string;
  userType: UserType;
  language: Language;
  lastLogin: any | null;
}

export interface getPatientAppointment_getAppointment_doctorProfile_assistants {
  __typename: "AssistantProfile";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  registrationNumber: string;
  gender: string;
  phone: string | null;
  createdAt: any;
  user: getPatientAppointment_getAppointment_doctorProfile_assistants_user;
}

export interface getPatientAppointment_getAppointment_doctorProfile {
  __typename: "DoctorProfile";
  id: string;
  title: string;
  firstName: string;
  lastName: string;
  phone: string | null;
  user: getPatientAppointment_getAppointment_doctorProfile_user | null;
  assistants: getPatientAppointment_getAppointment_doctorProfile_assistants[];
}

export interface getPatientAppointment_getAppointment {
  __typename: "Appointment";
  id: string;
  proposedDate: any;
  status: AppointmentStatus;
  ratingStars: number | null;
  ratingText: string | null;
  updatedAt: any;
  timeFrom: string | null;
  timeTo: string | null;
  isBetmenBooked: boolean;
  eesztBookingId: string | null;
  info: getPatientAppointment_getAppointment_info | null;
  institution: getPatientAppointment_getAppointment_institution | null;
  room: getPatientAppointment_getAppointment_room | null;
  treatmentSchema: getPatientAppointment_getAppointment_treatmentSchema | null;
  treatment: getPatientAppointment_getAppointment_treatment;
  doctorProfile: getPatientAppointment_getAppointment_doctorProfile;
}

export interface getPatientAppointment {
  getAppointment: getPatientAppointment_getAppointment;
}

export interface getPatientAppointmentVariables {
  appointmentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAssistantPatients
// ====================================================

export interface getAssistantPatients_getAssistantProfile_ownPatients_patient_user {
  __typename: "User";
  id: string;
  email: string;
}

export interface getAssistantPatients_getAssistantProfile_ownPatients_patient {
  __typename: "PatientProfile";
  id: string;
  user: getAssistantPatients_getAssistantProfile_ownPatients_patient_user | null;
  firstName: string;
  lastName: string;
  title: string;
  tajNumber: string;
  phone: string;
  birthDate: any;
  weight: number | null;
  height: number | null;
  isDeletableForDoctor: boolean;
}

export interface getAssistantPatients_getAssistantProfile_ownPatients {
  __typename: "DoctorOwnPatients";
  id: string;
  closedAt: any | null;
  createdAt: any;
  patient: getAssistantPatients_getAssistantProfile_ownPatients_patient | null;
}

export interface getAssistantPatients_getAssistantProfile {
  __typename: "AssistantProfile";
  id: string;
  ownPatients: getAssistantPatients_getAssistantProfile_ownPatients[];
}

export interface getAssistantPatients {
  getAssistantProfile: getAssistantPatients_getAssistantProfile;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateAssistantProfile
// ====================================================

export interface updateAssistantProfile_updateAssistantProfile {
  __typename: "AssistantProfile";
  id: string;
  title: string;
  phone: string | null;
}

export interface updateAssistantProfile {
  updateAssistantProfile: updateAssistantProfile_updateAssistantProfile;
}

export interface updateAssistantProfileVariables {
  updateAssistantInput: UpdateAssistantProfileInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: bnoCodes
// ====================================================

export interface bnoCodes_bnoCodes {
  __typename: "BnoCode";
  id: string;
  code: string;
  title: string;
}

export interface bnoCodes {
  bnoCodes: bnoCodes_bnoCodes[];
}

export interface bnoCodesVariables {
  searchTerm?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getDoctorPatientTreatments
// ====================================================

export interface getDoctorPatientTreatments_getPatientProfile_treatments_doctor {
  __typename: "DoctorProfile";
  id: string;
}

export interface getDoctorPatientTreatments_getPatientProfile_treatments_title {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface getDoctorPatientTreatments_getPatientProfile_treatments_description {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface getDoctorPatientTreatments_getPatientProfile_treatments_bnoCodes {
  __typename: "BnoCode";
  id: string;
  code: string;
  title: string;
}

export interface getDoctorPatientTreatments_getPatientProfile_treatments_firstAndLastDates {
  __typename: "FirstAndLastDates";
  firstAppointmentDate: any | null;
  lastAppointmentDate: any | null;
}

export interface getDoctorPatientTreatments_getPatientProfile_treatments_doctorAppointments {
  __typename: "Appointment";
  id: string;
  proposedDate: any;
}

export interface getDoctorPatientTreatments_getPatientProfile_treatments {
  __typename: "Treatment";
  id: string;
  status: TreatmentStatus | null;
  createdAt: any;
  updatedAt: any;
  comment: string | null;
  doctor: getDoctorPatientTreatments_getPatientProfile_treatments_doctor;
  title: getDoctorPatientTreatments_getPatientProfile_treatments_title;
  description: getDoctorPatientTreatments_getPatientProfile_treatments_description;
  bnoCodes: getDoctorPatientTreatments_getPatientProfile_treatments_bnoCodes[];
  firstAndLastDates: getDoctorPatientTreatments_getPatientProfile_treatments_firstAndLastDates | null;
  doctorAppointments: getDoctorPatientTreatments_getPatientProfile_treatments_doctorAppointments[] | null;
}

export interface getDoctorPatientTreatments_getPatientProfile {
  __typename: "PatientProfile";
  id: string;
  treatments: getDoctorPatientTreatments_getPatientProfile_treatments[];
}

export interface getDoctorPatientTreatments {
  getPatientProfile: getDoctorPatientTreatments_getPatientProfile;
}

export interface getDoctorPatientTreatmentsVariables {
  patientId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPatientProfileWithEhr
// ====================================================

export interface GetPatientProfileWithEhr_getPatientProfile_ehrDocumentList_documents_doctor {
  __typename: "EESZTDoctor";
  firstName: string;
  lastName: string;
  stampNo: string;
}

export interface GetPatientProfileWithEhr_getPatientProfile_ehrDocumentList_documents {
  __typename: "EESZTEhrDocument";
  EESZTId: string | null;
  hospitalDescr: string;
  documentType: string;
  documentId: string;
  doctor: GetPatientProfileWithEhr_getPatientProfile_ehrDocumentList_documents_doctor;
  timestamp: any;
}

export interface GetPatientProfileWithEhr_getPatientProfile_ehrDocumentList {
  __typename: "EESZTEhrDocumentList";
  total: number;
  hasDORError: boolean;
  documents: GetPatientProfileWithEhr_getPatientProfile_ehrDocumentList_documents[];
}

export interface GetPatientProfileWithEhr_getPatientProfile {
  __typename: "PatientProfile";
  id: string;
  ehrDocumentList: GetPatientProfileWithEhr_getPatientProfile_ehrDocumentList;
}

export interface GetPatientProfileWithEhr {
  getPatientProfile: GetPatientProfileWithEhr_getPatientProfile;
}

export interface GetPatientProfileWithEhrVariables {
  patientId: string;
  paginationInput?: PaginationInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: findPatientProfile
// ====================================================

export interface findPatientProfile_findPatientProfile_PatientProfile {
  __typename: "PatientProfile";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  tajNumber: string;
  phone: string;
  birthDate: any;
  gender: string;
}

export interface findPatientProfile_findPatientProfile_PatientProfileNotFoundError {
  __typename: "PatientProfileNotFoundError";
  message: string;
}

export type findPatientProfile_findPatientProfile = findPatientProfile_findPatientProfile_PatientProfile | findPatientProfile_findPatientProfile_PatientProfileNotFoundError;

export interface findPatientProfile {
  findPatientProfile: findPatientProfile_findPatientProfile;
}

export interface findPatientProfileVariables {
  tajNumber: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: invitePatient
// ====================================================

export interface invitePatient_invitePatient_user {
  __typename: "User";
  id: string;
  email: string;
}

export interface invitePatient_invitePatient {
  __typename: "PatientProfile";
  id: string;
  user: invitePatient_invitePatient_user | null;
  firstName: string;
  lastName: string;
  title: string;
  tajNumber: string;
  phone: string;
  birthDate: any;
}

export interface invitePatient {
  invitePatient: invitePatient_invitePatient;
}

export interface invitePatientVariables {
  createPatientArgs: CreatePatientProfileData;
  doctorUserIds?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: inviteExistingPatient
// ====================================================

export interface inviteExistingPatient_inviteExistingPatient_patient_user {
  __typename: "User";
  id: string;
  email: string;
}

export interface inviteExistingPatient_inviteExistingPatient_patient {
  __typename: "PatientProfile";
  id: string;
  user: inviteExistingPatient_inviteExistingPatient_patient_user | null;
  firstName: string;
  lastName: string;
  title: string;
  tajNumber: string;
  phone: string;
  birthDate: any;
}

export interface inviteExistingPatient_inviteExistingPatient {
  __typename: "DoctorOwnPatients";
  id: string;
  patient: inviteExistingPatient_inviteExistingPatient_patient | null;
}

export interface inviteExistingPatient {
  inviteExistingPatient: inviteExistingPatient_inviteExistingPatient[];
}

export interface inviteExistingPatientVariables {
  patientId: string;
  doctorUserIds?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getDoctorProfile
// ====================================================

export interface getDoctorProfile_getDoctorProfile_user {
  __typename: "User";
  email: string;
}

export interface getDoctorProfile_getDoctorProfile_professions {
  __typename: "Profession";
  id: string;
  code: string;
  name: string;
}

export interface getDoctorProfile_getDoctorProfile_institutionToDoctor_institution {
  __typename: "Institution";
  id: string;
  mapLink: string | null;
  name: string;
}

export interface getDoctorProfile_getDoctorProfile_institutionToDoctor {
  __typename: "InstitutionToDoctor";
  id: string;
  assistant: any | null;
  facebookId: string | null;
  phone: string | null;
  workingHours: string[] | null;
  institution: getDoctorProfile_getDoctorProfile_institutionToDoctor_institution;
}

export interface getDoctorProfile_getDoctorProfile {
  __typename: "DoctorProfile";
  id: string;
  phone: string | null;
  firstName: string;
  lastName: string;
  title: string;
  user: getDoctorProfile_getDoctorProfile_user | null;
  professions: getDoctorProfile_getDoctorProfile_professions[];
  institutionToDoctor: getDoctorProfile_getDoctorProfile_institutionToDoctor[];
}

export interface getDoctorProfile {
  getDoctorProfile: getDoctorProfile_getDoctorProfile;
}

export interface getDoctorProfileVariables {
  doctorProfileId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getDoctorProfilesForPatients
// ====================================================

export interface getDoctorProfilesForPatients_getDoctorProfilesForPatients_professions {
  __typename: "Profession";
  id: string;
  code: string;
  name: string;
}

export interface getDoctorProfilesForPatients_getDoctorProfilesForPatients_institutionToDoctor_institution {
  __typename: "Institution";
  id: string;
  mapLink: string | null;
  name: string;
}

export interface getDoctorProfilesForPatients_getDoctorProfilesForPatients_institutionToDoctor {
  __typename: "InstitutionToDoctor";
  id: string;
  assistant: any | null;
  facebookId: string | null;
  phone: string | null;
  workingHours: string[] | null;
  institution: getDoctorProfilesForPatients_getDoctorProfilesForPatients_institutionToDoctor_institution;
}

export interface getDoctorProfilesForPatients_getDoctorProfilesForPatients_user {
  __typename: "User";
  id: string;
  email: string;
}

export interface getDoctorProfilesForPatients_getDoctorProfilesForPatients_assistants_user {
  __typename: "User";
  id: string;
  email: string;
  userType: UserType;
  language: Language;
  lastLogin: any | null;
}

export interface getDoctorProfilesForPatients_getDoctorProfilesForPatients_assistants {
  __typename: "AssistantProfile";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  registrationNumber: string;
  gender: string;
  phone: string | null;
  createdAt: any;
  user: getDoctorProfilesForPatients_getDoctorProfilesForPatients_assistants_user;
}

export interface getDoctorProfilesForPatients_getDoctorProfilesForPatients {
  __typename: "DoctorProfile";
  id: string;
  phone: string | null;
  firstName: string;
  lastName: string;
  title: string;
  professions: getDoctorProfilesForPatients_getDoctorProfilesForPatients_professions[];
  institutionToDoctor: getDoctorProfilesForPatients_getDoctorProfilesForPatients_institutionToDoctor[];
  user: getDoctorProfilesForPatients_getDoctorProfilesForPatients_user | null;
  assistants: getDoctorProfilesForPatients_getDoctorProfilesForPatients_assistants[];
}

export interface getDoctorProfilesForPatients {
  getDoctorProfilesForPatients: getDoctorProfilesForPatients_getDoctorProfilesForPatients[];
}

export interface getDoctorProfilesForPatientsVariables {
  patientId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getDoctorProfilesForAssistant
// ====================================================

export interface getDoctorProfilesForAssistant_getDoctorProfilesForAssistant_professions {
  __typename: "Profession";
  id: string;
  code: string;
  name: string;
}

export interface getDoctorProfilesForAssistant_getDoctorProfilesForAssistant_institutionToDoctor_institution {
  __typename: "Institution";
  id: string;
  mapLink: string | null;
  name: string;
}

export interface getDoctorProfilesForAssistant_getDoctorProfilesForAssistant_institutionToDoctor {
  __typename: "InstitutionToDoctor";
  id: string;
  assistant: any | null;
  facebookId: string | null;
  phone: string | null;
  workingHours: string[] | null;
  institution: getDoctorProfilesForAssistant_getDoctorProfilesForAssistant_institutionToDoctor_institution;
}

export interface getDoctorProfilesForAssistant_getDoctorProfilesForAssistant_user {
  __typename: "User";
  id: string;
  email: string;
}

export interface getDoctorProfilesForAssistant_getDoctorProfilesForAssistant_assistants_user {
  __typename: "User";
  id: string;
  email: string;
  userType: UserType;
  language: Language;
  lastLogin: any | null;
}

export interface getDoctorProfilesForAssistant_getDoctorProfilesForAssistant_assistants {
  __typename: "AssistantProfile";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  registrationNumber: string;
  gender: string;
  phone: string | null;
  createdAt: any;
  user: getDoctorProfilesForAssistant_getDoctorProfilesForAssistant_assistants_user;
}

export interface getDoctorProfilesForAssistant_getDoctorProfilesForAssistant {
  __typename: "DoctorProfile";
  id: string;
  phone: string | null;
  firstName: string;
  lastName: string;
  title: string;
  professions: getDoctorProfilesForAssistant_getDoctorProfilesForAssistant_professions[];
  institutionToDoctor: getDoctorProfilesForAssistant_getDoctorProfilesForAssistant_institutionToDoctor[];
  user: getDoctorProfilesForAssistant_getDoctorProfilesForAssistant_user | null;
  assistants: getDoctorProfilesForAssistant_getDoctorProfilesForAssistant_assistants[];
}

export interface getDoctorProfilesForAssistant {
  getDoctorProfilesForAssistant: getDoctorProfilesForAssistant_getDoctorProfilesForAssistant[];
}

export interface getDoctorProfilesForAssistantVariables {
  assistantId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getDoctorInvites
// ====================================================

export interface getDoctorInvites_getDoctorProfile_invitations_doctors_doctorProfile {
  __typename: "DoctorProfile";
  title: string;
  firstName: string;
  lastName: string;
}

export interface getDoctorInvites_getDoctorProfile_invitations_doctors {
  __typename: "User";
  id: string;
  email: string;
  lastLogin: any | null;
  createdAt: any;
  doctorProfile: getDoctorInvites_getDoctorProfile_invitations_doctors_doctorProfile | null;
}

export interface getDoctorInvites_getDoctorProfile_invitations {
  __typename: "Invitations";
  doctors: getDoctorInvites_getDoctorProfile_invitations_doctors[] | null;
}

export interface getDoctorInvites_getDoctorProfile {
  __typename: "DoctorProfile";
  invitations: getDoctorInvites_getDoctorProfile_invitations;
}

export interface getDoctorInvites {
  getDoctorProfile: getDoctorInvites_getDoctorProfile;
}

export interface getDoctorInvitesVariables {
  doctorProfileId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getDoctorOwnPatients
// ====================================================

export interface getDoctorOwnPatients_getDoctorProfile_ownPatients_patient_user {
  __typename: "User";
  id: string;
  email: string;
}

export interface getDoctorOwnPatients_getDoctorProfile_ownPatients_patient {
  __typename: "PatientProfile";
  id: string;
  user: getDoctorOwnPatients_getDoctorProfile_ownPatients_patient_user | null;
  firstName: string;
  lastName: string;
  title: string;
  tajNumber: string;
  phone: string;
  birthDate: any;
  weight: number | null;
  height: number | null;
  isDeletableForDoctor: boolean;
}

export interface getDoctorOwnPatients_getDoctorProfile_ownPatients {
  __typename: "DoctorOwnPatients";
  id: string;
  closedAt: any | null;
  createdAt: any;
  patient: getDoctorOwnPatients_getDoctorProfile_ownPatients_patient | null;
}

export interface getDoctorOwnPatients_getDoctorProfile {
  __typename: "DoctorProfile";
  id: string;
  ownPatients: getDoctorOwnPatients_getDoctorProfile_ownPatients[];
}

export interface getDoctorOwnPatients {
  getDoctorProfile: getDoctorOwnPatients_getDoctorProfile;
}

export interface getDoctorOwnPatientsVariables {
  doctorProfileId: string;
  isActivePatient?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getDoctorProfiles
// ====================================================

export interface getDoctorProfiles_getDoctorProfiles_ownPatients {
  __typename: "DoctorOwnPatients";
  id: string;
}

export interface getDoctorProfiles_getDoctorProfiles_treatments_appointments {
  __typename: "Appointment";
  proposedDate: any;
  id: string;
}

export interface getDoctorProfiles_getDoctorProfiles_treatments {
  __typename: "Treatment";
  createdAt: any;
  id: string;
  appointments: getDoctorProfiles_getDoctorProfiles_treatments_appointments[];
}

export interface getDoctorProfiles_getDoctorProfiles {
  __typename: "DoctorProfile";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  ownPatients: getDoctorProfiles_getDoctorProfiles_ownPatients[];
  treatments: getDoctorProfiles_getDoctorProfiles_treatments[] | null;
}

export interface getDoctorProfiles {
  getDoctorProfiles: getDoctorProfiles_getDoctorProfiles[];
}

export interface getDoctorProfilesVariables {
  paginationInput: PaginationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateDoctorProfile
// ====================================================

export interface updateDoctorProfile_updateDoctorProfile_professions {
  __typename: "Profession";
  id: string;
  code: string;
  name: string;
}

export interface updateDoctorProfile_updateDoctorProfile_institutionToDoctor_institution {
  __typename: "Institution";
  id: string;
  mapLink: string | null;
  name: string;
}

export interface updateDoctorProfile_updateDoctorProfile_institutionToDoctor {
  __typename: "InstitutionToDoctor";
  id: string;
  assistant: any | null;
  facebookId: string | null;
  phone: string | null;
  workingHours: string[] | null;
  institution: updateDoctorProfile_updateDoctorProfile_institutionToDoctor_institution;
}

export interface updateDoctorProfile_updateDoctorProfile {
  __typename: "DoctorProfile";
  id: string;
  phone: string | null;
  title: string;
  professions: updateDoctorProfile_updateDoctorProfile_professions[];
  hasEESZTToken: boolean;
  institutionToDoctor: updateDoctorProfile_updateDoctorProfile_institutionToDoctor[];
}

export interface updateDoctorProfile {
  updateDoctorProfile: updateDoctorProfile_updateDoctorProfile;
}

export interface updateDoctorProfileVariables {
  doctorProfileId: string;
  updateDoctorInput: EditAbleDoctorData;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listAllProfessions
// ====================================================

export interface listAllProfessions_listAllProfessions {
  __typename: "Profession";
  id: string;
  code: string;
  name: string;
}

export interface listAllProfessions {
  listAllProfessions: listAllProfessions_listAllProfessions[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: inviteDoctor
// ====================================================

export interface inviteDoctor_inviteDoctor {
  __typename: "DoctorProfile";
  id: string;
}

export interface inviteDoctor {
  inviteDoctor: inviteDoctor_inviteDoctor;
}

export interface inviteDoctorVariables {
  createDoctorArgs: CreateDoctorProfileData;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listAllDoctors
// ====================================================

export interface listAllDoctors_listAllDoctors_user {
  __typename: "User";
  id: string;
}

export interface listAllDoctors_listAllDoctors {
  __typename: "DoctorProfile";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  stampNumber: string;
  userId: string;
  user: listAllDoctors_listAllDoctors_user | null;
}

export interface listAllDoctors {
  listAllDoctors: listAllDoctors_listAllDoctors[];
}

export interface listAllDoctorsVariables {
  doctorListFilterData?: DoctorProfileFilter | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: hasEESZTToken
// ====================================================

export interface hasEESZTToken_getDoctorProfile {
  __typename: "DoctorProfile";
  id: string;
  hasEESZTToken: boolean;
}

export interface hasEESZTToken {
  getDoctorProfile: hasEESZTToken_getDoctorProfile;
}

export interface hasEESZTTokenVariables {
  doctorProfileId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: generateFileDocuments
// ====================================================

export interface generateFileDocuments_downloadDocuments_document {
  __typename: "Document";
  id: string;
  type: DocumentTypeEnum;
  originalFileName: string;
}

export interface generateFileDocuments_downloadDocuments {
  __typename: "FileDocument";
  document: generateFileDocuments_downloadDocuments_document;
  base64EncodedFile: string;
}

export interface generateFileDocuments {
  downloadDocuments: generateFileDocuments_downloadDocuments[];
}

export interface generateFileDocumentsVariables {
  documentIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EesztStsLogin
// ====================================================

export interface EesztStsLogin_EesztStsLogin {
  __typename: "EESZTLoginResponse";
  success: boolean;
  expires: any;
}

export interface EesztStsLogin {
  EesztStsLogin: EesztStsLogin_EesztStsLogin;
}

export interface EesztStsLoginVariables {
  stsLoginInput: EESZTStsLoginInput;
  institutionId: string;
  institutionUnitId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EesztMobileTokenLogin
// ====================================================

export interface EesztMobileTokenLogin_EesztMobileTokenLogin {
  __typename: "EESZTLoginResponse";
  success: boolean;
  expires: any;
}

export interface EesztMobileTokenLogin {
  EesztMobileTokenLogin: EesztMobileTokenLogin_EesztMobileTokenLogin;
}

export interface EesztMobileTokenLoginVariables {
  mobileToken: string;
  institutionId: string;
  institutionUnitId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getEszigIdNoSaml
// ====================================================

export interface getEszigIdNoSaml {
  getEszigIdNoSaml: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EesztESZIGLogin
// ====================================================

export interface EesztESZIGLogin_EesztESZIGLogin {
  __typename: "EESZTLoginResponse";
  success: boolean;
  expires: any;
}

export interface EesztESZIGLogin {
  EesztESZIGLogin: EesztESZIGLogin_EesztESZIGLogin;
}

export interface EesztESZIGLoginVariables {
  token: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EesztLogout
// ====================================================

export interface EesztLogout_EesztLogout {
  __typename: "EESZTLogoutResponse";
  success: boolean;
}

export interface EesztLogout {
  EesztLogout: EesztLogout_EesztLogout;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getFreeSlotsByInstitution
// ====================================================

export interface getFreeSlotsByInstitution_getFreeSlotsByInstitution_institutionData {
  __typename: "IdentificationData";
  id: string;
  name: string;
}

export interface getFreeSlotsByInstitution_getFreeSlotsByInstitution_organizationData {
  __typename: "IdentificationData";
  id: string;
  name: string;
}

export interface getFreeSlotsByInstitution_getFreeSlotsByInstitution_consultationData {
  __typename: "ConsultationData";
  id: string;
  name: string;
  description: string;
  type: string;
  maxPatient: string;
}

export interface getFreeSlotsByInstitution_getFreeSlotsByInstitution {
  __typename: "EESZTSlot";
  date: string;
  startTime: string;
  endTime: string;
  institutionData: getFreeSlotsByInstitution_getFreeSlotsByInstitution_institutionData;
  organizationData: getFreeSlotsByInstitution_getFreeSlotsByInstitution_organizationData;
  consultationData: getFreeSlotsByInstitution_getFreeSlotsByInstitution_consultationData;
}

export interface getFreeSlotsByInstitution {
  getFreeSlotsByInstitution: getFreeSlotsByInstitution_getFreeSlotsByInstitution[];
}

export interface getFreeSlotsByInstitutionVariables {
  institutionId: string;
  institutionUnitId: string;
  serviceId: string;
  startDate: any;
  endDate: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EesztFeed
// ====================================================

export interface EesztFeed_EesztFeed_items {
  __typename: "EESZTRssFeedItem";
  title: string;
  description: string;
  link: string;
  guid: string;
  pubDate: string;
}

export interface EesztFeed_EesztFeed {
  __typename: "EESZTRssFeed";
  title: string;
  description: string;
  items: EesztFeed_EesztFeed_items[];
}

export interface EesztFeed {
  EesztFeed: EesztFeed_EesztFeed;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EesztTorSyncState
// ====================================================

export interface EesztTorSyncState_eesztTorSyncState {
  __typename: "EesztTorSyncState";
  synchronizationInProgress: boolean;
  lastSyncEndDate: any | null;
  lastSyncSuccessful: boolean | null;
}

export interface EesztTorSyncState {
  eesztTorSyncState: EesztTorSyncState_eesztTorSyncState;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: synchronizeEesztTorData
// ====================================================

export interface synchronizeEesztTorData {
  synchronizeEesztTorData: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listInstitutions
// ====================================================

export interface listInstitutions_listInstitutions_institutions_rooms_name {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface listInstitutions_listInstitutions_institutions_rooms_address {
  __typename: "OptionalMultiLangText";
  hu: string | null;
  en: string | null;
}

export interface listInstitutions_listInstitutions_institutions_rooms_description {
  __typename: "OptionalMultiLangText";
  hu: string | null;
  en: string | null;
}

export interface listInstitutions_listInstitutions_institutions_rooms {
  __typename: "Room";
  id: string;
  eesztUnitId: string | null;
  name: listInstitutions_listInstitutions_institutions_rooms_name;
  address: listInstitutions_listInstitutions_institutions_rooms_address | null;
  mapLink: string | null;
  description: listInstitutions_listInstitutions_institutions_rooms_description | null;
  isActive: boolean;
  neakCode: string | null;
}

export interface listInstitutions_listInstitutions_institutions {
  __typename: "Institution";
  id: string;
  eesztId: string | null;
  name: string;
  addressCounty: string | null;
  addressPostalCode: string | null;
  addressCity: string | null;
  addressAddress: string | null;
  concatenatedAddress: string | null;
  phoneNumber: string | null;
  email: string | null;
  website: string | null;
  mapLink: string | null;
  neak: string | null;
  isActive: boolean;
  isOwn: boolean;
  rooms: listInstitutions_listInstitutions_institutions_rooms[];
}

export interface listInstitutions_listInstitutions {
  __typename: "InstitutionList";
  institutions: listInstitutions_listInstitutions_institutions[];
  total: number;
}

export interface listInstitutions {
  listInstitutions: listInstitutions_listInstitutions;
}

export interface listInstitutionsVariables {
  ids?: string[] | null;
  hasEESZTCertificate?: boolean | null;
  nameSearchTerm?: string | null;
  paginationInput?: PaginationInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createRoom
// ====================================================

export interface createRoom_createRoom_name {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface createRoom_createRoom_address {
  __typename: "OptionalMultiLangText";
  hu: string | null;
  en: string | null;
}

export interface createRoom_createRoom_description {
  __typename: "OptionalMultiLangText";
  hu: string | null;
  en: string | null;
}

export interface createRoom_createRoom {
  __typename: "Room";
  id: string;
  eesztUnitId: string | null;
  name: createRoom_createRoom_name;
  address: createRoom_createRoom_address | null;
  mapLink: string | null;
  description: createRoom_createRoom_description | null;
  isActive: boolean;
  neakCode: string | null;
}

export interface createRoom {
  createRoom: createRoom_createRoom;
}

export interface createRoomVariables {
  createRoomInput: CreateRoomInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateRoom
// ====================================================

export interface updateRoom_updateRoom_name {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface updateRoom_updateRoom_address {
  __typename: "OptionalMultiLangText";
  hu: string | null;
  en: string | null;
}

export interface updateRoom_updateRoom_description {
  __typename: "OptionalMultiLangText";
  hu: string | null;
  en: string | null;
}

export interface updateRoom_updateRoom {
  __typename: "Room";
  id: string;
  eesztUnitId: string | null;
  name: updateRoom_updateRoom_name;
  address: updateRoom_updateRoom_address | null;
  mapLink: string | null;
  description: updateRoom_updateRoom_description | null;
  isActive: boolean;
  neakCode: string | null;
}

export interface updateRoom {
  updateRoom: updateRoom_updateRoom;
}

export interface updateRoomVariables {
  updateRoomInput: UpdateRoomInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createInstitution
// ====================================================

export interface createInstitution_createInstitution_rooms_name {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface createInstitution_createInstitution_rooms_address {
  __typename: "OptionalMultiLangText";
  hu: string | null;
  en: string | null;
}

export interface createInstitution_createInstitution_rooms_description {
  __typename: "OptionalMultiLangText";
  hu: string | null;
  en: string | null;
}

export interface createInstitution_createInstitution_rooms {
  __typename: "Room";
  id: string;
  eesztUnitId: string | null;
  name: createInstitution_createInstitution_rooms_name;
  address: createInstitution_createInstitution_rooms_address | null;
  mapLink: string | null;
  description: createInstitution_createInstitution_rooms_description | null;
  isActive: boolean;
  neakCode: string | null;
}

export interface createInstitution_createInstitution {
  __typename: "Institution";
  id: string;
  eesztId: string | null;
  name: string;
  addressCounty: string | null;
  addressPostalCode: string | null;
  addressCity: string | null;
  addressAddress: string | null;
  concatenatedAddress: string | null;
  phoneNumber: string | null;
  email: string | null;
  website: string | null;
  mapLink: string | null;
  neak: string | null;
  isActive: boolean;
  isOwn: boolean;
  rooms: createInstitution_createInstitution_rooms[];
}

export interface createInstitution {
  createInstitution: createInstitution_createInstitution;
}

export interface createInstitutionVariables {
  institutionInput: InstitutionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateInstitution
// ====================================================

export interface updateInstitution_updateInstitution_rooms_name {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface updateInstitution_updateInstitution_rooms_address {
  __typename: "OptionalMultiLangText";
  hu: string | null;
  en: string | null;
}

export interface updateInstitution_updateInstitution_rooms_description {
  __typename: "OptionalMultiLangText";
  hu: string | null;
  en: string | null;
}

export interface updateInstitution_updateInstitution_rooms {
  __typename: "Room";
  id: string;
  eesztUnitId: string | null;
  name: updateInstitution_updateInstitution_rooms_name;
  address: updateInstitution_updateInstitution_rooms_address | null;
  mapLink: string | null;
  description: updateInstitution_updateInstitution_rooms_description | null;
  isActive: boolean;
  neakCode: string | null;
}

export interface updateInstitution_updateInstitution {
  __typename: "Institution";
  id: string;
  eesztId: string | null;
  name: string;
  addressCounty: string | null;
  addressPostalCode: string | null;
  addressCity: string | null;
  addressAddress: string | null;
  concatenatedAddress: string | null;
  phoneNumber: string | null;
  email: string | null;
  website: string | null;
  mapLink: string | null;
  neak: string | null;
  isActive: boolean;
  isOwn: boolean;
  rooms: updateInstitution_updateInstitution_rooms[];
}

export interface updateInstitution {
  updateInstitution: updateInstitution_updateInstitution;
}

export interface updateInstitutionVariables {
  id: string;
  institutionInput: InstitutionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: assignAdminsToInstitution
// ====================================================

export interface assignAdminsToInstitution_assignAdminsToInstitution {
  __typename: "Institution";
  id: string;
}

export interface assignAdminsToInstitution {
  assignAdminsToInstitution: assignAdminsToInstitution_assignAdminsToInstitution;
}

export interface assignAdminsToInstitutionVariables {
  institutionId: string;
  userIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: institutionAdminProfiles
// ====================================================

export interface institutionAdminProfiles_institutionAdminProfiles_user {
  __typename: "User";
  id: string;
}

export interface institutionAdminProfiles_institutionAdminProfiles {
  __typename: "DoctorProfile" | "AssistantProfile" | "PatientProfile" | "AdminProfile";
  id: string;
  title: string;
  firstName: string;
  lastName: string;
  user: institutionAdminProfiles_institutionAdminProfiles_user | null;
}

export interface institutionAdminProfiles {
  institutionAdminProfiles: institutionAdminProfiles_institutionAdminProfiles[];
}

export interface institutionAdminProfilesVariables {
  institutionId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: uploadCertificate
// ====================================================

export interface uploadCertificate {
  uploadCertificate: boolean;
}

export interface uploadCertificateVariables {
  institutionId: string;
  certificate: any;
  passphrase: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: checkCertificate
// ====================================================

export interface checkCertificate {
  checkCertificate: boolean;
}

export interface checkCertificateVariables {
  institutionId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPartners
// ====================================================

export interface getPartners_getPartners_user_doctorProfile_professions {
  __typename: "Profession";
  id: string;
  code: string;
  name: string;
}

export interface getPartners_getPartners_user_doctorProfile {
  __typename: "DoctorProfile";
  id: string;
  firstName: string;
  lastName: string;
  professions: getPartners_getPartners_user_doctorProfile_professions[];
  title: string;
  phone: string | null;
}

export interface getPartners_getPartners_user_patientProfile {
  __typename: "PatientProfile";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  tajNumber: string;
  phone: string;
  birthDate: any;
}

export interface getPartners_getPartners_user_assistantProfile {
  __typename: "AssistantProfile";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  registrationNumber: string;
  phone: string | null;
}

export interface getPartners_getPartners_user {
  __typename: "User";
  userType: UserType;
  id: string;
  doctorProfile: getPartners_getPartners_user_doctorProfile | null;
  patientProfile: getPartners_getPartners_user_patientProfile | null;
  assistantProfile: getPartners_getPartners_user_assistantProfile | null;
  email: string;
}

export interface getPartners_getPartners_newMessages {
  __typename: "Message";
  id: string;
  message: string;
  createdAt: any;
  readAt: any | null;
  recipientUserId: string;
  senderUserId: string;
}

export interface getPartners_getPartners {
  __typename: "MessagePartner";
  id: string;
  user: getPartners_getPartners_user;
  newMessages: getPartners_getPartners_newMessages[];
  lastMessageAt: any | null;
}

export interface getPartners {
  getPartners: getPartners_getPartners[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPartnerMessages
// ====================================================

export interface getPartnerMessages_getPartnerMessages {
  __typename: "Message";
  id: string;
  message: string;
  createdAt: any;
  readAt: any | null;
  recipientUserId: string;
  senderUserId: string;
}

export interface getPartnerMessages {
  getPartnerMessages: getPartnerMessages_getPartnerMessages[];
}

export interface getPartnerMessagesVariables {
  partnerUserId: string;
  paginationInput?: PaginationInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: sendMessage
// ====================================================

export interface sendMessage_sendMessage {
  __typename: "Message";
  id: string;
  message: string;
  createdAt: any;
  readAt: any | null;
  recipientUserId: string;
  senderUserId: string;
}

export interface sendMessage {
  sendMessage: sendMessage_sendMessage;
}

export interface sendMessageVariables {
  message: string;
  recipientUserId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: markMessagesAsRead
// ====================================================

export interface markMessagesAsRead_markMessagesAsRead {
  __typename: "Message";
  id: string;
  readAt: any | null;
}

export interface markMessagesAsRead {
  markMessagesAsRead: markMessagesAsRead_markMessagesAsRead[];
}

export interface markMessagesAsReadVariables {
  senderUserId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: newMessage
// ====================================================

export interface newMessage_newMessage {
  __typename: "Message";
  id: string;
  message: string;
  createdAt: any;
  readAt: any | null;
  recipientUserId: string;
  senderUserId: string;
}

export interface newMessage {
  newMessage: newMessage_newMessage;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getNotifications
// ====================================================

export interface getNotifications_getNotifications {
  __typename: "Notification";
  id: string;
  type: NotificationTypes;
  data: any | null;
  isRead: boolean;
  createdAt: any;
  sentAt: any | null;
}

export interface getNotifications {
  getNotifications: getNotifications_getNotifications[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: markNotificationAsRead
// ====================================================

export interface markNotificationAsRead_markNotificationAsRead {
  __typename: "Notification";
  id: string;
  type: NotificationTypes;
  data: any | null;
  isRead: boolean;
  createdAt: any;
  sentAt: any | null;
}

export interface markNotificationAsRead {
  markNotificationAsRead: markNotificationAsRead_markNotificationAsRead;
}

export interface markNotificationAsReadVariables {
  notificationId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: markAllNotificationAsRead
// ====================================================

export interface markAllNotificationAsRead {
  markAllNotificationAsRead: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: newNotification
// ====================================================

export interface newNotification_newNotification {
  __typename: "Notification";
  id: string;
  type: NotificationTypes;
  data: any | null;
  isRead: boolean;
  createdAt: any;
  sentAt: any | null;
}

export interface newNotification {
  newNotification: newNotification_newNotification;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: triggerDoctorNextWeekTasks
// ====================================================

export interface triggerDoctorNextWeekTasks {
  triggerDoctorNextWeekTasks: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: triggerPatientNextWeekAppointments
// ====================================================

export interface triggerPatientNextWeekAppointments {
  triggerPatientNextWeekAppointments: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: triggerPatientDailyAppointmentReminder
// ====================================================

export interface triggerPatientDailyAppointmentReminder {
  triggerPatientDailyAppointmentReminder: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getNotificationSettings
// ====================================================

export interface getNotificationSettings_getNotificationSettings {
  __typename: "NotificationSettings";
  id: string;
  allowEmailSending: boolean;
  allowSmsSending: boolean;
  type: NotificationTypes;
}

export interface getNotificationSettings {
  getNotificationSettings: getNotificationSettings_getNotificationSettings[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateNotificationSettings
// ====================================================

export interface updateNotificationSettings_updateNotificationSettings {
  __typename: "NotificationSettings";
  id: string;
  allowEmailSending: boolean;
  allowSmsSending: boolean;
  type: NotificationTypes;
}

export interface updateNotificationSettings {
  updateNotificationSettings: updateNotificationSettings_updateNotificationSettings;
}

export interface updateNotificationSettingsVariables {
  newSettings: NotificationSettingsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPatientProfile
// ====================================================

export interface getPatientProfile_getPatientProfile_emergencyContacts {
  __typename: "EmergencyContact";
  name: string | null;
  phone: string | null;
}

export interface getPatientProfile_getPatientProfile {
  __typename: "PatientProfile";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  tajNumber: string;
  phone: string;
  gender: string;
  bloodType: BloodType | null;
  takenMedicines: string[] | null;
  medicineIntolerance: string[] | null;
  emergencyContacts: getPatientProfile_getPatientProfile_emergencyContacts[] | null;
  address: string | null;
  birthDate: any;
  emailSettings: any | null;
  weight: number | null;
  height: number | null;
}

export interface getPatientProfile {
  getPatientProfile: getPatientProfile_getPatientProfile;
}

export interface getPatientProfileVariables {
  patientId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updatePatientProfile
// ====================================================

export interface updatePatientProfile_updatePatientProfile {
  __typename: "PatientProfile";
  id: string;
}

export interface updatePatientProfile {
  updatePatientProfile: updatePatientProfile_updatePatientProfile;
}

export interface updatePatientProfileVariables {
  updatePatientInput: EditAblePatientData;
  patientId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listProfiles
// ====================================================

export interface listProfiles_listProfiles_user {
  __typename: "User";
  id: string;
}

export interface listProfiles_listProfiles {
  __typename: "DoctorProfile" | "AssistantProfile" | "PatientProfile" | "AdminProfile";
  id: string;
  title: string;
  firstName: string;
  lastName: string;
  user: listProfiles_listProfiles_user | null;
}

export interface listProfiles {
  listProfiles: listProfiles_listProfiles[];
}

export interface listProfilesVariables {
  types: UserType[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getTreatment
// ====================================================

export interface getTreatment_getTreatment_doctor_professions {
  __typename: "Profession";
  id: string;
  code: string;
  name: string;
}

export interface getTreatment_getTreatment_doctor_user {
  __typename: "User";
  id: string;
  email: string;
}

export interface getTreatment_getTreatment_doctor_institutionToDoctor_institution {
  __typename: "Institution";
  id: string;
  mapLink: string | null;
  name: string;
}

export interface getTreatment_getTreatment_doctor_institutionToDoctor {
  __typename: "InstitutionToDoctor";
  id: string;
  assistant: any | null;
  facebookId: string | null;
  phone: string | null;
  workingHours: string[] | null;
  institution: getTreatment_getTreatment_doctor_institutionToDoctor_institution;
}

export interface getTreatment_getTreatment_doctor_assistants_user {
  __typename: "User";
  id: string;
  email: string;
  userType: UserType;
  language: Language;
  lastLogin: any | null;
}

export interface getTreatment_getTreatment_doctor_assistants {
  __typename: "AssistantProfile";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  registrationNumber: string;
  gender: string;
  phone: string | null;
  createdAt: any;
  user: getTreatment_getTreatment_doctor_assistants_user;
}

export interface getTreatment_getTreatment_doctor {
  __typename: "DoctorProfile";
  id: string;
  phone: string | null;
  title: string;
  firstName: string;
  lastName: string;
  stampNumber: string;
  userId: string;
  professions: getTreatment_getTreatment_doctor_professions[];
  user: getTreatment_getTreatment_doctor_user | null;
  institutionToDoctor: getTreatment_getTreatment_doctor_institutionToDoctor[];
  assistants: getTreatment_getTreatment_doctor_assistants[];
}

export interface getTreatment_getTreatment_title {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface getTreatment_getTreatment_description {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface getTreatment_getTreatment_bnoCodes {
  __typename: "BnoCode";
  id: string;
  code: string;
  title: string;
}

export interface getTreatment_getTreatment_firstAndLastDates {
  __typename: "FirstAndLastDates";
  firstAppointmentDate: any | null;
  lastAppointmentDate: any | null;
}

export interface getTreatment_getTreatment_treatmentLogs_title {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface getTreatment_getTreatment_treatmentLogs_user {
  __typename: "LogUser";
  title: string | null;
  firstName: string;
  lastName: string;
}

export interface getTreatment_getTreatment_treatmentLogs {
  __typename: "TreatmentLogDisplay";
  id: string;
  date: any;
  type: TreatmentLogTypeEnum | null;
  title: getTreatment_getTreatment_treatmentLogs_title | null;
  user: getTreatment_getTreatment_treatmentLogs_user | null;
  diff: any | null;
}

export interface getTreatment_getTreatment_appointments_appointmentInfo {
  __typename: "AppointmentInfo";
  id: string;
}

export interface getTreatment_getTreatment_appointments_institution_rooms_name {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface getTreatment_getTreatment_appointments_institution_rooms_address {
  __typename: "OptionalMultiLangText";
  hu: string | null;
  en: string | null;
}

export interface getTreatment_getTreatment_appointments_institution_rooms_description {
  __typename: "OptionalMultiLangText";
  hu: string | null;
  en: string | null;
}

export interface getTreatment_getTreatment_appointments_institution_rooms {
  __typename: "Room";
  id: string;
  eesztUnitId: string | null;
  name: getTreatment_getTreatment_appointments_institution_rooms_name;
  address: getTreatment_getTreatment_appointments_institution_rooms_address | null;
  mapLink: string | null;
  description: getTreatment_getTreatment_appointments_institution_rooms_description | null;
  isActive: boolean;
  neakCode: string | null;
}

export interface getTreatment_getTreatment_appointments_institution {
  __typename: "Institution";
  id: string;
  eesztId: string | null;
  name: string;
  addressCounty: string | null;
  addressPostalCode: string | null;
  addressCity: string | null;
  addressAddress: string | null;
  concatenatedAddress: string | null;
  phoneNumber: string | null;
  email: string | null;
  website: string | null;
  mapLink: string | null;
  neak: string | null;
  isActive: boolean;
  isOwn: boolean;
  rooms: getTreatment_getTreatment_appointments_institution_rooms[];
}

export interface getTreatment_getTreatment_appointments_room_name {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface getTreatment_getTreatment_appointments_room_address {
  __typename: "OptionalMultiLangText";
  hu: string | null;
  en: string | null;
}

export interface getTreatment_getTreatment_appointments_room_description {
  __typename: "OptionalMultiLangText";
  hu: string | null;
  en: string | null;
}

export interface getTreatment_getTreatment_appointments_room {
  __typename: "Room";
  id: string;
  eesztUnitId: string | null;
  name: getTreatment_getTreatment_appointments_room_name;
  address: getTreatment_getTreatment_appointments_room_address | null;
  mapLink: string | null;
  description: getTreatment_getTreatment_appointments_room_description | null;
  isActive: boolean;
  neakCode: string | null;
}

export interface getTreatment_getTreatment_appointments_eesztBookingTransaction {
  __typename: "EesztAppointmentBookingTransaction";
  status: EesztAppointmentBookingTransactionStatusEnum | null;
  message: string | null;
}

export interface getTreatment_getTreatment_appointments_treatmentSchema_title {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface getTreatment_getTreatment_appointments_treatmentSchema_description {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface getTreatment_getTreatment_appointments_treatmentSchema_bnoCodes {
  __typename: "BnoCode";
  id: string;
  code: string;
  title: string;
}

export interface getTreatment_getTreatment_appointments_treatmentSchema {
  __typename: "TreatmentSchema";
  id: string;
  title: getTreatment_getTreatment_appointments_treatmentSchema_title;
  description: getTreatment_getTreatment_appointments_treatmentSchema_description | null;
  disabled: boolean;
  url: string | null;
  snowmedCode: string | null;
  bnoCodes: getTreatment_getTreatment_appointments_treatmentSchema_bnoCodes[];
}

export interface getTreatment_getTreatment_appointments_doctor_professions {
  __typename: "Profession";
  id: string;
  code: string;
  name: string;
}

export interface getTreatment_getTreatment_appointments_doctor_user {
  __typename: "User";
  id: string;
  email: string;
}

export interface getTreatment_getTreatment_appointments_doctor_institutionToDoctor_institution {
  __typename: "Institution";
  id: string;
  mapLink: string | null;
  name: string;
}

export interface getTreatment_getTreatment_appointments_doctor_institutionToDoctor {
  __typename: "InstitutionToDoctor";
  id: string;
  assistant: any | null;
  facebookId: string | null;
  phone: string | null;
  workingHours: string[] | null;
  institution: getTreatment_getTreatment_appointments_doctor_institutionToDoctor_institution;
}

export interface getTreatment_getTreatment_appointments_doctor_assistants_user {
  __typename: "User";
  id: string;
  email: string;
  userType: UserType;
  language: Language;
  lastLogin: any | null;
}

export interface getTreatment_getTreatment_appointments_doctor_assistants {
  __typename: "AssistantProfile";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  registrationNumber: string;
  gender: string;
  phone: string | null;
  createdAt: any;
  user: getTreatment_getTreatment_appointments_doctor_assistants_user;
}

export interface getTreatment_getTreatment_appointments_doctor {
  __typename: "DoctorProfile";
  id: string;
  phone: string | null;
  title: string;
  firstName: string;
  lastName: string;
  stampNumber: string;
  userId: string;
  professions: getTreatment_getTreatment_appointments_doctor_professions[];
  user: getTreatment_getTreatment_appointments_doctor_user | null;
  institutionToDoctor: getTreatment_getTreatment_appointments_doctor_institutionToDoctor[];
  assistants: getTreatment_getTreatment_appointments_doctor_assistants[];
}

export interface getTreatment_getTreatment_appointments_info_goodToKnow {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface getTreatment_getTreatment_appointments_info_patientTitle {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface getTreatment_getTreatment_appointments_info_doctorTitle {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface getTreatment_getTreatment_appointments_info_doctorTodo {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface getTreatment_getTreatment_appointments_info {
  __typename: "AppointmentInfo";
  eesztServiceId: string | null;
  goodToKnow: getTreatment_getTreatment_appointments_info_goodToKnow;
  patientTitle: getTreatment_getTreatment_appointments_info_patientTitle;
  doctorTitle: getTreatment_getTreatment_appointments_info_doctorTitle;
  doctorTodo: getTreatment_getTreatment_appointments_info_doctorTodo;
  beforeTreatmentNote: boolean | null;
  oeno: string | null;
}

export interface getTreatment_getTreatment_appointments_dependent {
  __typename: "AppointmentDependency";
  id: string;
  distance: string;
  type: AppointmentDependencyTypes;
  isBroken: boolean;
  isCustom: boolean;
  fromId: string;
  toId: string;
}

export interface getTreatment_getTreatment_appointments {
  __typename: "Appointment";
  id: string;
  proposedDate: any;
  eesztBookingId: string | null;
  isBetmenBooked: boolean;
  appointmentInfo: getTreatment_getTreatment_appointments_appointmentInfo | null;
  institution: getTreatment_getTreatment_appointments_institution | null;
  room: getTreatment_getTreatment_appointments_room | null;
  status: AppointmentStatus;
  createdAt: any;
  updatedAt: any;
  ratingText: string | null;
  ratingStars: number | null;
  timeFrom: string | null;
  timeTo: string | null;
  katId: string | null;
  eesztBookingTransaction: getTreatment_getTreatment_appointments_eesztBookingTransaction | null;
  treatmentSchema: getTreatment_getTreatment_appointments_treatmentSchema | null;
  doctor: getTreatment_getTreatment_appointments_doctor;
  info: getTreatment_getTreatment_appointments_info | null;
  dependent: getTreatment_getTreatment_appointments_dependent[];
}

export interface getTreatment_getTreatment_patient_user {
  __typename: "User";
  id: string;
  email: string;
}

export interface getTreatment_getTreatment_patient {
  __typename: "PatientProfile";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  tajNumber: string;
  phone: string;
  user: getTreatment_getTreatment_patient_user | null;
}

export interface getTreatment_getTreatment_surveys_surveySchema_professions {
  __typename: "Profession";
  id: string;
  code: string;
  name: string;
}

export interface getTreatment_getTreatment_surveys_surveySchema {
  __typename: "SurveySchema";
  id: string;
  title: string;
  description: string;
  categories: SurveyCategoryEnum[];
  isGlobal: boolean;
  status: SurveySchemaStatusEnum;
  professions: getTreatment_getTreatment_surveys_surveySchema_professions[];
}

export interface getTreatment_getTreatment_surveys {
  __typename: "Survey";
  id: string;
  fillableFrom: any;
  fillableUntil: any | null;
  surveySchema: getTreatment_getTreatment_surveys_surveySchema;
  createdByTreatmentSchemaId: string | null;
}

export interface getTreatment_getTreatment {
  __typename: "Treatment";
  id: string;
  status: TreatmentStatus | null;
  createdAt: any;
  updatedAt: any;
  comment: string | null;
  doctor: getTreatment_getTreatment_doctor;
  title: getTreatment_getTreatment_title;
  description: getTreatment_getTreatment_description;
  bnoCodes: getTreatment_getTreatment_bnoCodes[];
  firstAndLastDates: getTreatment_getTreatment_firstAndLastDates | null;
  pocId: string | null;
  treatmentLogs: getTreatment_getTreatment_treatmentLogs[] | null;
  appointments: getTreatment_getTreatment_appointments[];
  patient: getTreatment_getTreatment_patient;
  surveys: getTreatment_getTreatment_surveys[];
}

export interface getTreatment {
  getTreatment: getTreatment_getTreatment;
}

export interface getTreatmentVariables {
  treatmentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPatientHomeData
// ====================================================

export interface getPatientHomeData_getAppointments_appointments_info_patientTitle {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface getPatientHomeData_getAppointments_appointments_info_goodToKnow {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface getPatientHomeData_getAppointments_appointments_info {
  __typename: "AppointmentInfo";
  patientTitle: getPatientHomeData_getAppointments_appointments_info_patientTitle;
  goodToKnow: getPatientHomeData_getAppointments_appointments_info_goodToKnow;
}

export interface getPatientHomeData_getAppointments_appointments_institution_rooms_name {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface getPatientHomeData_getAppointments_appointments_institution_rooms_address {
  __typename: "OptionalMultiLangText";
  hu: string | null;
  en: string | null;
}

export interface getPatientHomeData_getAppointments_appointments_institution_rooms_description {
  __typename: "OptionalMultiLangText";
  hu: string | null;
  en: string | null;
}

export interface getPatientHomeData_getAppointments_appointments_institution_rooms {
  __typename: "Room";
  id: string;
  eesztUnitId: string | null;
  name: getPatientHomeData_getAppointments_appointments_institution_rooms_name;
  address: getPatientHomeData_getAppointments_appointments_institution_rooms_address | null;
  mapLink: string | null;
  description: getPatientHomeData_getAppointments_appointments_institution_rooms_description | null;
  isActive: boolean;
  neakCode: string | null;
}

export interface getPatientHomeData_getAppointments_appointments_institution {
  __typename: "Institution";
  id: string;
  eesztId: string | null;
  name: string;
  addressCounty: string | null;
  addressPostalCode: string | null;
  addressCity: string | null;
  addressAddress: string | null;
  concatenatedAddress: string | null;
  phoneNumber: string | null;
  email: string | null;
  website: string | null;
  mapLink: string | null;
  neak: string | null;
  isActive: boolean;
  isOwn: boolean;
  rooms: getPatientHomeData_getAppointments_appointments_institution_rooms[];
}

export interface getPatientHomeData_getAppointments_appointments_room_name {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface getPatientHomeData_getAppointments_appointments_room_address {
  __typename: "OptionalMultiLangText";
  hu: string | null;
  en: string | null;
}

export interface getPatientHomeData_getAppointments_appointments_room_description {
  __typename: "OptionalMultiLangText";
  hu: string | null;
  en: string | null;
}

export interface getPatientHomeData_getAppointments_appointments_room {
  __typename: "Room";
  id: string;
  eesztUnitId: string | null;
  name: getPatientHomeData_getAppointments_appointments_room_name;
  address: getPatientHomeData_getAppointments_appointments_room_address | null;
  mapLink: string | null;
  description: getPatientHomeData_getAppointments_appointments_room_description | null;
  isActive: boolean;
  neakCode: string | null;
}

export interface getPatientHomeData_getAppointments_appointments_treatment_description {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface getPatientHomeData_getAppointments_appointments_treatment_title {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface getPatientHomeData_getAppointments_appointments_treatment_doctor {
  __typename: "DoctorProfile";
  id: string;
  title: string;
  firstName: string;
  lastName: string;
}

export interface getPatientHomeData_getAppointments_appointments_treatment {
  __typename: "Treatment";
  id: string;
  status: TreatmentStatus | null;
  description: getPatientHomeData_getAppointments_appointments_treatment_description;
  title: getPatientHomeData_getAppointments_appointments_treatment_title;
  doctor: getPatientHomeData_getAppointments_appointments_treatment_doctor;
}

export interface getPatientHomeData_getAppointments_appointments_doctor_user {
  __typename: "User";
  email: string;
  id: string;
}

export interface getPatientHomeData_getAppointments_appointments_doctor_assistants_user {
  __typename: "User";
  id: string;
  email: string;
  userType: UserType;
  language: Language;
  lastLogin: any | null;
}

export interface getPatientHomeData_getAppointments_appointments_doctor_assistants {
  __typename: "AssistantProfile";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  registrationNumber: string;
  gender: string;
  phone: string | null;
  createdAt: any;
  user: getPatientHomeData_getAppointments_appointments_doctor_assistants_user;
}

export interface getPatientHomeData_getAppointments_appointments_doctor {
  __typename: "DoctorProfile";
  id: string;
  title: string;
  firstName: string;
  lastName: string;
  phone: string | null;
  user: getPatientHomeData_getAppointments_appointments_doctor_user | null;
  assistants: getPatientHomeData_getAppointments_appointments_doctor_assistants[];
}

export interface getPatientHomeData_getAppointments_appointments_dependent {
  __typename: "AppointmentDependency";
  id: string;
  distance: string;
  isBroken: boolean;
  fromId: string;
  toId: string;
}

export interface getPatientHomeData_getAppointments_appointments {
  __typename: "Appointment";
  id: string;
  proposedDate: any;
  status: AppointmentStatus;
  isBetmenBooked: boolean;
  eesztBookingId: string | null;
  info: getPatientHomeData_getAppointments_appointments_info | null;
  institution: getPatientHomeData_getAppointments_appointments_institution | null;
  room: getPatientHomeData_getAppointments_appointments_room | null;
  treatment: getPatientHomeData_getAppointments_appointments_treatment;
  doctor: getPatientHomeData_getAppointments_appointments_doctor;
  dependent: getPatientHomeData_getAppointments_appointments_dependent[];
}

export interface getPatientHomeData_getAppointments {
  __typename: "AppointmentList";
  appointments: getPatientHomeData_getAppointments_appointments[];
  total: number;
}

export interface getPatientHomeData {
  getAppointments: getPatientHomeData_getAppointments;
}

export interface getPatientHomeDataVariables {
  patientId: string;
  paginationInput?: PaginationInput | null;
  searchTerm?: string | null;
  treatmentId?: string | null;
  isPast?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getTreatmentsToPatientDashboard
// ====================================================

export interface getTreatmentsToPatientDashboard_getTreatmentsToPatientDashboard_title {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface getTreatmentsToPatientDashboard_getTreatmentsToPatientDashboard {
  __typename: "Treatment";
  id: string;
  status: TreatmentStatus | null;
  title: getTreatmentsToPatientDashboard_getTreatmentsToPatientDashboard_title;
}

export interface getTreatmentsToPatientDashboard {
  getTreatmentsToPatientDashboard: getTreatmentsToPatientDashboard_getTreatmentsToPatientDashboard[];
}

export interface getTreatmentsToPatientDashboardVariables {
  patientId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getKatEvents
// ====================================================

export interface getKatEvents_getKatEvents_room {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface getKatEvents_getKatEvents {
  __typename: "EESZTKatEvent";
  katId: string;
  taj: string;
  eventType: string;
  status: string;
  room: getKatEvents_getKatEvents_room;
  eventStart: string;
  doctor: string;
}

export interface getKatEvents {
  getKatEvents: getKatEvents_getKatEvents[];
}

export interface getKatEventsVariables {
  tajNumber: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPatientAppointmentsCount
// ====================================================

export interface getPatientAppointmentsCount_getPatientProfile_treatments_appointments_info_patientTitle {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface getPatientAppointmentsCount_getPatientProfile_treatments_appointments_info {
  __typename: "AppointmentInfo";
  patientTitle: getPatientAppointmentsCount_getPatientProfile_treatments_appointments_info_patientTitle;
}

export interface getPatientAppointmentsCount_getPatientProfile_treatments_appointments {
  __typename: "Appointment";
  id: string;
  proposedDate: any;
  info: getPatientAppointmentsCount_getPatientProfile_treatments_appointments_info | null;
}

export interface getPatientAppointmentsCount_getPatientProfile_treatments {
  __typename: "Treatment";
  id: string;
  appointments: getPatientAppointmentsCount_getPatientProfile_treatments_appointments[];
}

export interface getPatientAppointmentsCount_getPatientProfile {
  __typename: "PatientProfile";
  treatments: getPatientAppointmentsCount_getPatientProfile_treatments[];
}

export interface getPatientAppointmentsCount {
  getPatientProfile: getPatientAppointmentsCount_getPatientProfile;
}

export interface getPatientAppointmentsCountVariables {
  patientId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createTreatment
// ====================================================

export interface createTreatment_createTreatment_doctor {
  __typename: "DoctorProfile";
  id: string;
}

export interface createTreatment_createTreatment_title {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface createTreatment_createTreatment_description {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface createTreatment_createTreatment_bnoCodes {
  __typename: "BnoCode";
  id: string;
  code: string;
  title: string;
}

export interface createTreatment_createTreatment {
  __typename: "Treatment";
  id: string;
  status: TreatmentStatus | null;
  createdAt: any;
  updatedAt: any;
  comment: string | null;
  doctor: createTreatment_createTreatment_doctor;
  title: createTreatment_createTreatment_title;
  description: createTreatment_createTreatment_description;
  bnoCodes: createTreatment_createTreatment_bnoCodes[];
}

export interface createTreatment {
  createTreatment: createTreatment_createTreatment;
}

export interface createTreatmentVariables {
  treatmentData: CreateTreatmentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addProtocol
// ====================================================

export interface addProtocol_addProtocol_doctor {
  __typename: "DoctorProfile";
  id: string;
}

export interface addProtocol_addProtocol_title {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface addProtocol_addProtocol_description {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface addProtocol_addProtocol_bnoCodes {
  __typename: "BnoCode";
  id: string;
  code: string;
  title: string;
}

export interface addProtocol_addProtocol {
  __typename: "Treatment";
  id: string;
  status: TreatmentStatus | null;
  createdAt: any;
  updatedAt: any;
  comment: string | null;
  doctor: addProtocol_addProtocol_doctor;
  title: addProtocol_addProtocol_title;
  description: addProtocol_addProtocol_description;
  bnoCodes: addProtocol_addProtocol_bnoCodes[];
}

export interface addProtocol {
  addProtocol: addProtocol_addProtocol;
}

export interface addProtocolVariables {
  treatmentData: AddProtocolInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updatePoc
// ====================================================

export interface updatePoc_updatePoc {
  __typename: "EESZTPocId";
  pocId: string;
}

export interface updatePoc {
  updatePoc: updatePoc_updatePoc;
}

export interface updatePocVariables {
  pocId: string;
  katId: string;
  appointmentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteProtocol
// ====================================================

export interface deleteProtocol_deleteProtocol_doctor_professions {
  __typename: "Profession";
  id: string;
  code: string;
  name: string;
}

export interface deleteProtocol_deleteProtocol_doctor_user {
  __typename: "User";
  id: string;
  email: string;
}

export interface deleteProtocol_deleteProtocol_doctor_institutionToDoctor_institution {
  __typename: "Institution";
  id: string;
  mapLink: string | null;
  name: string;
}

export interface deleteProtocol_deleteProtocol_doctor_institutionToDoctor {
  __typename: "InstitutionToDoctor";
  id: string;
  assistant: any | null;
  facebookId: string | null;
  phone: string | null;
  workingHours: string[] | null;
  institution: deleteProtocol_deleteProtocol_doctor_institutionToDoctor_institution;
}

export interface deleteProtocol_deleteProtocol_doctor_assistants_user {
  __typename: "User";
  id: string;
  email: string;
  userType: UserType;
  language: Language;
  lastLogin: any | null;
}

export interface deleteProtocol_deleteProtocol_doctor_assistants {
  __typename: "AssistantProfile";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  registrationNumber: string;
  gender: string;
  phone: string | null;
  createdAt: any;
  user: deleteProtocol_deleteProtocol_doctor_assistants_user;
}

export interface deleteProtocol_deleteProtocol_doctor {
  __typename: "DoctorProfile";
  id: string;
  phone: string | null;
  title: string;
  firstName: string;
  lastName: string;
  stampNumber: string;
  userId: string;
  professions: deleteProtocol_deleteProtocol_doctor_professions[];
  user: deleteProtocol_deleteProtocol_doctor_user | null;
  institutionToDoctor: deleteProtocol_deleteProtocol_doctor_institutionToDoctor[];
  assistants: deleteProtocol_deleteProtocol_doctor_assistants[];
}

export interface deleteProtocol_deleteProtocol_title {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface deleteProtocol_deleteProtocol_description {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface deleteProtocol_deleteProtocol_bnoCodes {
  __typename: "BnoCode";
  id: string;
  code: string;
  title: string;
}

export interface deleteProtocol_deleteProtocol_firstAndLastDates {
  __typename: "FirstAndLastDates";
  firstAppointmentDate: any | null;
  lastAppointmentDate: any | null;
}

export interface deleteProtocol_deleteProtocol_treatmentLogs_title {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface deleteProtocol_deleteProtocol_treatmentLogs_user {
  __typename: "LogUser";
  title: string | null;
  firstName: string;
  lastName: string;
}

export interface deleteProtocol_deleteProtocol_treatmentLogs {
  __typename: "TreatmentLogDisplay";
  id: string;
  date: any;
  type: TreatmentLogTypeEnum | null;
  title: deleteProtocol_deleteProtocol_treatmentLogs_title | null;
  user: deleteProtocol_deleteProtocol_treatmentLogs_user | null;
  diff: any | null;
}

export interface deleteProtocol_deleteProtocol_appointments_appointmentInfo {
  __typename: "AppointmentInfo";
  id: string;
}

export interface deleteProtocol_deleteProtocol_appointments_institution_rooms_name {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface deleteProtocol_deleteProtocol_appointments_institution_rooms_address {
  __typename: "OptionalMultiLangText";
  hu: string | null;
  en: string | null;
}

export interface deleteProtocol_deleteProtocol_appointments_institution_rooms_description {
  __typename: "OptionalMultiLangText";
  hu: string | null;
  en: string | null;
}

export interface deleteProtocol_deleteProtocol_appointments_institution_rooms {
  __typename: "Room";
  id: string;
  eesztUnitId: string | null;
  name: deleteProtocol_deleteProtocol_appointments_institution_rooms_name;
  address: deleteProtocol_deleteProtocol_appointments_institution_rooms_address | null;
  mapLink: string | null;
  description: deleteProtocol_deleteProtocol_appointments_institution_rooms_description | null;
  isActive: boolean;
  neakCode: string | null;
}

export interface deleteProtocol_deleteProtocol_appointments_institution {
  __typename: "Institution";
  id: string;
  eesztId: string | null;
  name: string;
  addressCounty: string | null;
  addressPostalCode: string | null;
  addressCity: string | null;
  addressAddress: string | null;
  concatenatedAddress: string | null;
  phoneNumber: string | null;
  email: string | null;
  website: string | null;
  mapLink: string | null;
  neak: string | null;
  isActive: boolean;
  isOwn: boolean;
  rooms: deleteProtocol_deleteProtocol_appointments_institution_rooms[];
}

export interface deleteProtocol_deleteProtocol_appointments_room_name {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface deleteProtocol_deleteProtocol_appointments_room_address {
  __typename: "OptionalMultiLangText";
  hu: string | null;
  en: string | null;
}

export interface deleteProtocol_deleteProtocol_appointments_room_description {
  __typename: "OptionalMultiLangText";
  hu: string | null;
  en: string | null;
}

export interface deleteProtocol_deleteProtocol_appointments_room {
  __typename: "Room";
  id: string;
  eesztUnitId: string | null;
  name: deleteProtocol_deleteProtocol_appointments_room_name;
  address: deleteProtocol_deleteProtocol_appointments_room_address | null;
  mapLink: string | null;
  description: deleteProtocol_deleteProtocol_appointments_room_description | null;
  isActive: boolean;
  neakCode: string | null;
}

export interface deleteProtocol_deleteProtocol_appointments_eesztBookingTransaction {
  __typename: "EesztAppointmentBookingTransaction";
  status: EesztAppointmentBookingTransactionStatusEnum | null;
  message: string | null;
}

export interface deleteProtocol_deleteProtocol_appointments_treatmentSchema_title {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface deleteProtocol_deleteProtocol_appointments_treatmentSchema_description {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface deleteProtocol_deleteProtocol_appointments_treatmentSchema_bnoCodes {
  __typename: "BnoCode";
  id: string;
  code: string;
  title: string;
}

export interface deleteProtocol_deleteProtocol_appointments_treatmentSchema {
  __typename: "TreatmentSchema";
  id: string;
  title: deleteProtocol_deleteProtocol_appointments_treatmentSchema_title;
  description: deleteProtocol_deleteProtocol_appointments_treatmentSchema_description | null;
  disabled: boolean;
  url: string | null;
  snowmedCode: string | null;
  bnoCodes: deleteProtocol_deleteProtocol_appointments_treatmentSchema_bnoCodes[];
}

export interface deleteProtocol_deleteProtocol_appointments_doctor_professions {
  __typename: "Profession";
  id: string;
  code: string;
  name: string;
}

export interface deleteProtocol_deleteProtocol_appointments_doctor_user {
  __typename: "User";
  id: string;
  email: string;
}

export interface deleteProtocol_deleteProtocol_appointments_doctor_institutionToDoctor_institution {
  __typename: "Institution";
  id: string;
  mapLink: string | null;
  name: string;
}

export interface deleteProtocol_deleteProtocol_appointments_doctor_institutionToDoctor {
  __typename: "InstitutionToDoctor";
  id: string;
  assistant: any | null;
  facebookId: string | null;
  phone: string | null;
  workingHours: string[] | null;
  institution: deleteProtocol_deleteProtocol_appointments_doctor_institutionToDoctor_institution;
}

export interface deleteProtocol_deleteProtocol_appointments_doctor_assistants_user {
  __typename: "User";
  id: string;
  email: string;
  userType: UserType;
  language: Language;
  lastLogin: any | null;
}

export interface deleteProtocol_deleteProtocol_appointments_doctor_assistants {
  __typename: "AssistantProfile";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  registrationNumber: string;
  gender: string;
  phone: string | null;
  createdAt: any;
  user: deleteProtocol_deleteProtocol_appointments_doctor_assistants_user;
}

export interface deleteProtocol_deleteProtocol_appointments_doctor {
  __typename: "DoctorProfile";
  id: string;
  phone: string | null;
  title: string;
  firstName: string;
  lastName: string;
  stampNumber: string;
  userId: string;
  professions: deleteProtocol_deleteProtocol_appointments_doctor_professions[];
  user: deleteProtocol_deleteProtocol_appointments_doctor_user | null;
  institutionToDoctor: deleteProtocol_deleteProtocol_appointments_doctor_institutionToDoctor[];
  assistants: deleteProtocol_deleteProtocol_appointments_doctor_assistants[];
}

export interface deleteProtocol_deleteProtocol_appointments_info_goodToKnow {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface deleteProtocol_deleteProtocol_appointments_info_patientTitle {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface deleteProtocol_deleteProtocol_appointments_info_doctorTitle {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface deleteProtocol_deleteProtocol_appointments_info_doctorTodo {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface deleteProtocol_deleteProtocol_appointments_info {
  __typename: "AppointmentInfo";
  id: string;
  eesztServiceId: string | null;
  goodToKnow: deleteProtocol_deleteProtocol_appointments_info_goodToKnow;
  patientTitle: deleteProtocol_deleteProtocol_appointments_info_patientTitle;
  doctorTitle: deleteProtocol_deleteProtocol_appointments_info_doctorTitle;
  doctorTodo: deleteProtocol_deleteProtocol_appointments_info_doctorTodo;
  beforeTreatmentNote: boolean | null;
  oeno: string | null;
}

export interface deleteProtocol_deleteProtocol_appointments_dependent {
  __typename: "AppointmentDependency";
  id: string;
  distance: string;
  type: AppointmentDependencyTypes;
  isBroken: boolean;
  isCustom: boolean;
  fromId: string;
  toId: string;
}

export interface deleteProtocol_deleteProtocol_appointments {
  __typename: "Appointment";
  id: string;
  proposedDate: any;
  eesztBookingId: string | null;
  isBetmenBooked: boolean;
  appointmentInfo: deleteProtocol_deleteProtocol_appointments_appointmentInfo | null;
  institution: deleteProtocol_deleteProtocol_appointments_institution | null;
  room: deleteProtocol_deleteProtocol_appointments_room | null;
  status: AppointmentStatus;
  createdAt: any;
  updatedAt: any;
  ratingText: string | null;
  ratingStars: number | null;
  timeFrom: string | null;
  timeTo: string | null;
  katId: string | null;
  eesztBookingTransaction: deleteProtocol_deleteProtocol_appointments_eesztBookingTransaction | null;
  treatmentSchema: deleteProtocol_deleteProtocol_appointments_treatmentSchema | null;
  doctor: deleteProtocol_deleteProtocol_appointments_doctor;
  info: deleteProtocol_deleteProtocol_appointments_info | null;
  dependent: deleteProtocol_deleteProtocol_appointments_dependent[];
}

export interface deleteProtocol_deleteProtocol_patient_user {
  __typename: "User";
  id: string;
  email: string;
}

export interface deleteProtocol_deleteProtocol_patient {
  __typename: "PatientProfile";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  tajNumber: string;
  phone: string;
  user: deleteProtocol_deleteProtocol_patient_user | null;
}

export interface deleteProtocol_deleteProtocol_surveys_surveySchema_professions {
  __typename: "Profession";
  id: string;
  code: string;
  name: string;
}

export interface deleteProtocol_deleteProtocol_surveys_surveySchema {
  __typename: "SurveySchema";
  id: string;
  title: string;
  description: string;
  categories: SurveyCategoryEnum[];
  isGlobal: boolean;
  status: SurveySchemaStatusEnum;
  professions: deleteProtocol_deleteProtocol_surveys_surveySchema_professions[];
}

export interface deleteProtocol_deleteProtocol_surveys {
  __typename: "Survey";
  id: string;
  fillableFrom: any;
  fillableUntil: any | null;
  surveySchema: deleteProtocol_deleteProtocol_surveys_surveySchema;
  createdByTreatmentSchemaId: string | null;
}

export interface deleteProtocol_deleteProtocol {
  __typename: "Treatment";
  id: string;
  status: TreatmentStatus | null;
  createdAt: any;
  updatedAt: any;
  comment: string | null;
  doctor: deleteProtocol_deleteProtocol_doctor;
  title: deleteProtocol_deleteProtocol_title;
  description: deleteProtocol_deleteProtocol_description;
  bnoCodes: deleteProtocol_deleteProtocol_bnoCodes[];
  firstAndLastDates: deleteProtocol_deleteProtocol_firstAndLastDates | null;
  pocId: string | null;
  treatmentLogs: deleteProtocol_deleteProtocol_treatmentLogs[] | null;
  appointments: deleteProtocol_deleteProtocol_appointments[];
  patient: deleteProtocol_deleteProtocol_patient;
  surveys: deleteProtocol_deleteProtocol_surveys[];
}

export interface deleteProtocol {
  deleteProtocol: deleteProtocol_deleteProtocol;
}

export interface deleteProtocolVariables {
  treatmentData: DeleteProtocolInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: sendDraftTreatment
// ====================================================

export interface sendDraftTreatment_sendDraftTreatment {
  __typename: "Treatment";
  id: string;
  status: TreatmentStatus | null;
}

export interface sendDraftTreatment {
  sendDraftTreatment: sendDraftTreatment_sendDraftTreatment;
}

export interface sendDraftTreatmentVariables {
  treatmentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: cancelTreatment
// ====================================================

export interface cancelTreatment_cancelTreatment {
  __typename: "Treatment";
  id: string;
  status: TreatmentStatus | null;
}

export interface cancelTreatment {
  cancelTreatment: cancelTreatment_cancelTreatment;
}

export interface cancelTreatmentVariables {
  treatmentData: CancelTreatmentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateTreatment
// ====================================================

export interface updateTreatment_updateTreatment_doctor {
  __typename: "DoctorProfile";
  id: string;
}

export interface updateTreatment_updateTreatment_title {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface updateTreatment_updateTreatment_description {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface updateTreatment_updateTreatment_bnoCodes {
  __typename: "BnoCode";
  id: string;
  code: string;
  title: string;
}

export interface updateTreatment_updateTreatment {
  __typename: "Treatment";
  id: string;
  status: TreatmentStatus | null;
  createdAt: any;
  updatedAt: any;
  comment: string | null;
  doctor: updateTreatment_updateTreatment_doctor;
  title: updateTreatment_updateTreatment_title;
  description: updateTreatment_updateTreatment_description;
  bnoCodes: updateTreatment_updateTreatment_bnoCodes[];
}

export interface updateTreatment {
  updateTreatment: updateTreatment_updateTreatment;
}

export interface updateTreatmentVariables {
  treatmentData: UpdateTreatmentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteDraftTreatment
// ====================================================

export interface deleteDraftTreatment {
  deleteDraftTreatment: boolean;
}

export interface deleteDraftTreatmentVariables {
  treatmentData: UpdateTreatmentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: triggerExpiringTreatmentRemainder
// ====================================================

export interface triggerExpiringTreatmentRemainder {
  triggerExpiringTreatmentRemainder: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addSurveyToTreatment
// ====================================================

export interface addSurveyToTreatment_addSurveyToTreatment {
  __typename: "Survey";
  id: string;
}

export interface addSurveyToTreatment {
  addSurveyToTreatment: addSurveyToTreatment_addSurveyToTreatment;
}

export interface addSurveyToTreatmentVariables {
  treatmentId: string;
  surveySchemaId: string;
  patientUserId: string;
  fillableFrom: any;
  fillableUntil: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeSurveyFromTreatment
// ====================================================

export interface removeSurveyFromTreatment_removeSurveyFromTreatment {
  __typename: "Survey";
  id: string;
}

export interface removeSurveyFromTreatment {
  removeSurveyFromTreatment: removeSurveyFromTreatment_removeSurveyFromTreatment;
}

export interface removeSurveyFromTreatmentVariables {
  treatmentId: string;
  surveyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateSurveyFillableFromDate
// ====================================================

export interface updateSurveyFillableFromDate_updateSurveyFillableFromDate {
  __typename: "Survey";
  id: string;
}

export interface updateSurveyFillableFromDate {
  updateSurveyFillableFromDate: updateSurveyFillableFromDate_updateSurveyFillableFromDate;
}

export interface updateSurveyFillableFromDateVariables {
  surveyId: string;
  fillableFromDate: any;
  fillableUntilDate: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: initiatePasswordReset
// ====================================================

export interface initiatePasswordReset {
  initiatePasswordReset: boolean;
}

export interface initiatePasswordResetVariables {
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setNewPassword
// ====================================================

export interface setNewPassword {
  setNewPassword: boolean;
}

export interface setNewPasswordVariables {
  token: string;
  password: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: Login
// ====================================================

export interface Login_login {
  __typename: "LoginResponse";
  /**
   * It will always be NULL in case 2FA is enabled.
   */
  jwtToken: string | null;
  isFirstLogin: boolean;
}

export interface Login {
  login: Login_login;
}

export interface LoginVariables {
  email: string;
  password: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LoginWithTwoFactorToken
// ====================================================

export interface LoginWithTwoFactorToken_loginWithTwoFactorToken {
  __typename: "LoginWithTwoFactorTokenResponse";
  jwtToken: string;
}

export interface LoginWithTwoFactorToken {
  loginWithTwoFactorToken: LoginWithTwoFactorToken_loginWithTwoFactorToken;
}

export interface LoginWithTwoFactorTokenVariables {
  loginWithTwoFactorTokenInput: LoginWithTwoFactorTokenInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Me
// ====================================================

export interface Me_me_institutionPermissions {
  __typename: "InstitutionPermission";
  institutionId: string;
}

export interface Me_me_profile_AssistantProfile {
  __typename: "AssistantProfile" | "PatientProfile" | "AdminProfile";
  id: string;
  title: string;
  firstName: string;
  lastName: string;
  phone: string | null;
}

export interface Me_me_profile_DoctorProfile_institutionToDoctor_institution {
  __typename: "Institution";
  id: string;
  name: string;
}

export interface Me_me_profile_DoctorProfile_institutionToDoctor {
  __typename: "InstitutionToDoctor";
  id: string;
  phone: string | null;
  workingHours: string[] | null;
  facebookId: string | null;
  assistant: any | null;
  institution: Me_me_profile_DoctorProfile_institutionToDoctor_institution;
}

export interface Me_me_profile_DoctorProfile_professions {
  __typename: "Profession";
  id: string;
  code: string;
  name: string;
}

export interface Me_me_profile_DoctorProfile {
  __typename: "DoctorProfile";
  id: string;
  title: string;
  firstName: string;
  lastName: string;
  phone: string | null;
  institutionToDoctor: Me_me_profile_DoctorProfile_institutionToDoctor[];
  professions: Me_me_profile_DoctorProfile_professions[];
}

export type Me_me_profile = Me_me_profile_AssistantProfile | Me_me_profile_DoctorProfile;

export interface Me_me {
  __typename: "User";
  id: string;
  email: string;
  userType: UserType;
  language: Language;
  isAdmin: boolean;
  isLockedOut: boolean;
  assistantDoctorIds: string[];
  institutionPermissions: Me_me_institutionPermissions[];
  profile: Me_me_profile;
}

export interface Me {
  me: Me_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangeLanguage
// ====================================================

export interface ChangeLanguage_changeLanguage {
  __typename: "User";
  id: string;
  language: Language;
}

export interface ChangeLanguage {
  changeLanguage: ChangeLanguage_changeLanguage;
}

export interface ChangeLanguageVariables {
  language: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getInvitedDoctorsByAdmin
// ====================================================

export interface getInvitedDoctorsByAdmin_getInvitedDoctorsByAdmin_doctorProfile {
  __typename: "DoctorProfile";
  title: string;
  firstName: string;
  lastName: string;
}

export interface getInvitedDoctorsByAdmin_getInvitedDoctorsByAdmin {
  __typename: "User";
  id: string;
  email: string;
  lastLogin: any | null;
  createdAt: any;
  doctorProfile: getInvitedDoctorsByAdmin_getInvitedDoctorsByAdmin_doctorProfile | null;
}

export interface getInvitedDoctorsByAdmin {
  getInvitedDoctorsByAdmin: getInvitedDoctorsByAdmin_getInvitedDoctorsByAdmin[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteInvitation
// ====================================================

export interface deleteInvitation {
  deleteInvitation: boolean;
}

export interface deleteInvitationVariables {
  deleteUserId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getDoctorOwnAssistants
// ====================================================

export interface getDoctorOwnAssistants_getDoctorOwnAssistants_assistant_user {
  __typename: "User";
  id: string;
  email: string;
  userType: UserType;
  language: Language;
  lastLogin: any | null;
}

export interface getDoctorOwnAssistants_getDoctorOwnAssistants_assistant {
  __typename: "AssistantProfile";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  registrationNumber: string;
  gender: string;
  phone: string | null;
  createdAt: any;
  user: getDoctorOwnAssistants_getDoctorOwnAssistants_assistant_user;
}

export interface getDoctorOwnAssistants_getDoctorOwnAssistants {
  __typename: "DoctorOwnAssistants";
  id: string;
  assistant: getDoctorOwnAssistants_getDoctorOwnAssistants_assistant;
  closedAt: any | null;
}

export interface getDoctorOwnAssistants {
  getDoctorOwnAssistants: getDoctorOwnAssistants_getDoctorOwnAssistants[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addAssistantToDoctor
// ====================================================

export interface addAssistantToDoctor_addAssistantToDoctor_assistant_user {
  __typename: "User";
  id: string;
  email: string;
  userType: UserType;
  language: Language;
  lastLogin: any | null;
}

export interface addAssistantToDoctor_addAssistantToDoctor_assistant {
  __typename: "AssistantProfile";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  registrationNumber: string;
  gender: string;
  phone: string | null;
  createdAt: any;
  user: addAssistantToDoctor_addAssistantToDoctor_assistant_user;
}

export interface addAssistantToDoctor_addAssistantToDoctor {
  __typename: "DoctorOwnAssistants";
  id: string;
  assistant: addAssistantToDoctor_addAssistantToDoctor_assistant;
  closedAt: any | null;
}

export interface addAssistantToDoctor {
  addAssistantToDoctor: addAssistantToDoctor_addAssistantToDoctor;
}

export interface addAssistantToDoctorVariables {
  assistantId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeAssistantFromDoctor
// ====================================================

export interface removeAssistantFromDoctor_removeAssistantFromDoctor_assistant_user {
  __typename: "User";
  id: string;
  email: string;
  userType: UserType;
  language: Language;
  lastLogin: any | null;
}

export interface removeAssistantFromDoctor_removeAssistantFromDoctor_assistant {
  __typename: "AssistantProfile";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  registrationNumber: string;
  gender: string;
  phone: string | null;
  createdAt: any;
  user: removeAssistantFromDoctor_removeAssistantFromDoctor_assistant_user;
}

export interface removeAssistantFromDoctor_removeAssistantFromDoctor {
  __typename: "DoctorOwnAssistants";
  id: string;
  assistant: removeAssistantFromDoctor_removeAssistantFromDoctor_assistant;
  closedAt: any | null;
}

export interface removeAssistantFromDoctor {
  removeAssistantFromDoctor: removeAssistantFromDoctor_removeAssistantFromDoctor;
}

export interface removeAssistantFromDoctorVariables {
  assistantId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: inviteAssistant
// ====================================================

export interface inviteAssistant_inviteAssistant_user {
  __typename: "User";
  id: string;
  email: string;
  userType: UserType;
  language: Language;
  lastLogin: any | null;
}

export interface inviteAssistant_inviteAssistant {
  __typename: "AssistantProfile";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  registrationNumber: string;
  gender: string;
  phone: string | null;
  createdAt: any;
  user: inviteAssistant_inviteAssistant_user;
}

export interface inviteAssistant {
  inviteAssistant: inviteAssistant_inviteAssistant;
}

export interface inviteAssistantVariables {
  createAssistantArgs: CreateAssistantProfileData;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: findAssistantProfile
// ====================================================

export interface findAssistantProfile_findAssistantProfile_AssistantProfile_user {
  __typename: "User";
  id: string;
  email: string;
  userType: UserType;
  language: Language;
  lastLogin: any | null;
}

export interface findAssistantProfile_findAssistantProfile_AssistantProfile {
  __typename: "AssistantProfile";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  registrationNumber: string;
  gender: string;
  phone: string | null;
  createdAt: any;
  user: findAssistantProfile_findAssistantProfile_AssistantProfile_user;
}

export interface findAssistantProfile_findAssistantProfile_AssistantProfileNotFoundError {
  __typename: "AssistantProfileNotFoundError";
  message: string;
}

export type findAssistantProfile_findAssistantProfile = findAssistantProfile_findAssistantProfile_AssistantProfile | findAssistantProfile_findAssistantProfile_AssistantProfileNotFoundError;

export interface findAssistantProfile {
  findAssistantProfile: findAssistantProfile_findAssistantProfile;
}

export interface findAssistantProfileVariables {
  registrationNumber: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPatients
// ====================================================

export interface getPatients_listPatientsForDoctor_patients_patient_user {
  __typename: "User";
  id: string;
  email: string;
}

export interface getPatients_listPatientsForDoctor_patients_patient {
  __typename: "PatientProfile";
  id: string;
  user: getPatients_listPatientsForDoctor_patients_patient_user | null;
  firstName: string;
  lastName: string;
  title: string;
  tajNumber: string;
  phone: string;
  birthDate: any;
  weight: number | null;
  height: number | null;
  isDeletableForDoctor: boolean;
}

export interface getPatients_listPatientsForDoctor_patients {
  __typename: "DoctorOwnPatients";
  id: string;
  closedAt: any | null;
  createdAt: any;
  patient: getPatients_listPatientsForDoctor_patients_patient | null;
}

export interface getPatients_listPatientsForDoctor {
  __typename: "PatientProfileList";
  patients: getPatients_listPatientsForDoctor_patients[];
  total: number;
}

export interface getPatients {
  listPatientsForDoctor: getPatients_listPatientsForDoctor;
}

export interface getPatientsVariables {
  doctorId: string;
  filterData?: PatientFilter | null;
  paginationInput?: PaginationInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getDoctorPatientProfile
// ====================================================

export interface getDoctorPatientProfile_getPatientProfile_emergencyContacts {
  __typename: "EmergencyContact";
  name: string | null;
  phone: string | null;
}

export interface getDoctorPatientProfile_getPatientProfile_doctors_professions {
  __typename: "Profession";
  id: string;
  code: string;
  name: string;
}

export interface getDoctorPatientProfile_getPatientProfile_doctors {
  __typename: "DoctorProfile";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  professions: getDoctorPatientProfile_getPatientProfile_doctors_professions[];
}

export interface getDoctorPatientProfile_getPatientProfile_user {
  __typename: "User";
  id: string;
  email: string;
}

export interface getDoctorPatientProfile_getPatientProfile {
  __typename: "PatientProfile";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  tajNumber: string;
  phone: string;
  gender: string;
  takenMedicines: string[] | null;
  medicineIntolerance: string[] | null;
  emergencyContacts: getDoctorPatientProfile_getPatientProfile_emergencyContacts[] | null;
  address: string | null;
  birthDate: any;
  emailSettings: any | null;
  bloodType: BloodType | null;
  bloodTypeAttachmentEESZTId: string | null;
  weight: number | null;
  height: number | null;
  isDeletableForDoctor: boolean;
  doctors: getDoctorPatientProfile_getPatientProfile_doctors[];
  user: getDoctorPatientProfile_getPatientProfile_user | null;
}

export interface getDoctorPatientProfile {
  getPatientProfile: getDoctorPatientProfile_getPatientProfile;
}

export interface getDoctorPatientProfileVariables {
  patientId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteDoctorsOwnPatient
// ====================================================

export interface deleteDoctorsOwnPatient_deleteDoctorsOwnPatient {
  __typename: "DoctorOwnPatients";
  id: string;
  closedAt: any | null;
}

export interface deleteDoctorsOwnPatient {
  deleteDoctorsOwnPatient: deleteDoctorsOwnPatient_deleteDoctorsOwnPatient | null;
}

export interface deleteDoctorsOwnPatientVariables {
  patientId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updatePatientsBloodType
// ====================================================

export interface updatePatientsBloodType_updatePatientsBloodType {
  __typename: "PatientProfile";
  id: string;
  bloodType: BloodType | null;
  bloodTypeAttachmentEESZTId: string | null;
}

export interface updatePatientsBloodType {
  updatePatientsBloodType: updatePatientsBloodType_updatePatientsBloodType;
}

export interface updatePatientsBloodTypeVariables {
  patientId: string;
  bloodType: BloodType;
  bloodTypeAttachmentEESZTId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getSurveyQuestions
// ====================================================

export interface getSurveyQuestions_getSurveyQuestions_surveyQuestions_data_scaleData_options {
  __typename: "ScaleOption";
  value: number;
  label: string | null;
}

export interface getSurveyQuestions_getSurveyQuestions_surveyQuestions_data_scaleData {
  __typename: "ScaleData";
  options: getSurveyQuestions_getSurveyQuestions_surveyQuestions_data_scaleData_options[];
}

export interface getSurveyQuestions_getSurveyQuestions_surveyQuestions_data_fileUploadData {
  __typename: "FileUploadData";
  maxItemsCount: number;
  maxFileSize: SurveyQuestionFileSize;
  validFileTypes: SurveyQuestionValidFileType[];
}

export interface getSurveyQuestions_getSurveyQuestions_surveyQuestions_data {
  __typename: "QuestionData";
  multipleChoice: boolean | null;
  scaleData: getSurveyQuestions_getSurveyQuestions_surveyQuestions_data_scaleData | null;
  fileUploadData: getSurveyQuestions_getSurveyQuestions_surveyQuestions_data_fileUploadData | null;
}

export interface getSurveyQuestions_getSurveyQuestions_surveyQuestions_mcSurveyQuestionChoices {
  __typename: "AnswerOption";
  id: string | null;
  name: string;
}

export interface getSurveyQuestions_getSurveyQuestions_surveyQuestions_sclSurveyQuestionAspects {
  __typename: "AnswerOption";
  id: string | null;
  name: string;
}

export interface getSurveyQuestions_getSurveyQuestions_surveyQuestions_user {
  __typename: "User";
  id: string;
}

export interface getSurveyQuestions_getSurveyQuestions_surveyQuestions {
  __typename: "SurveyQuestion";
  id: string;
  title: string;
  description: string | null;
  type: SurveyQuestionEnum;
  surveySchemaId: string | null;
  isGlobal: boolean;
  data: getSurveyQuestions_getSurveyQuestions_surveyQuestions_data | null;
  mcSurveyQuestionChoices: getSurveyQuestions_getSurveyQuestions_surveyQuestions_mcSurveyQuestionChoices[];
  sclSurveyQuestionAspects: getSurveyQuestions_getSurveyQuestions_surveyQuestions_sclSurveyQuestionAspects[];
  user: getSurveyQuestions_getSurveyQuestions_surveyQuestions_user;
}

export interface getSurveyQuestions_getSurveyQuestions {
  __typename: "SurveyQuestionList";
  surveyQuestions: getSurveyQuestions_getSurveyQuestions_surveyQuestions[];
  total: number;
}

export interface getSurveyQuestions {
  getSurveyQuestions: getSurveyQuestions_getSurveyQuestions;
}

export interface getSurveyQuestionsVariables {
  filterInput?: SurveyQuestionFilterInput | null;
  paginationInput?: PaginationInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createSurveyQuestion
// ====================================================

export interface createSurveyQuestion_createSurveyQuestion {
  __typename: "SurveyQuestion";
  id: string;
}

export interface createSurveyQuestion {
  createSurveyQuestion: createSurveyQuestion_createSurveyQuestion;
}

export interface createSurveyQuestionVariables {
  surveyQuestionData: CreateSurveyQuestionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateSurveyQuestion
// ====================================================

export interface updateSurveyQuestion_updateSurveyQuestion {
  __typename: "SurveyQuestion";
  id: string;
}

export interface updateSurveyQuestion {
  updateSurveyQuestion: updateSurveyQuestion_updateSurveyQuestion;
}

export interface updateSurveyQuestionVariables {
  surveyQuestionData: UpdateSurveyQuestionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteSurveyQuestion
// ====================================================

export interface deleteSurveyQuestion_deleteSurveyQuestion {
  __typename: "SurveyQuestion";
  id: string;
}

export interface deleteSurveyQuestion {
  deleteSurveyQuestion: deleteSurveyQuestion_deleteSurveyQuestion;
}

export interface deleteSurveyQuestionVariables {
  surveyQuestionId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getSurveySchemas
// ====================================================

export interface getSurveySchemas_getSurveySchemas_surveySchemas_referenceUrls {
  __typename: "SurveySchemaReferenceUrl";
  name: string;
  url: string;
}

export interface getSurveySchemas_getSurveySchemas_surveySchemas_bnoCodes {
  __typename: "BnoCode";
  id: string;
  code: string;
  title: string;
}

export interface getSurveySchemas_getSurveySchemas_surveySchemas_professions {
  __typename: "Profession";
  id: string;
  code: string;
  name: string;
}

export interface getSurveySchemas_getSurveySchemas_surveySchemas_institutions {
  __typename: "Institution";
  id: string;
  name: string;
}

export interface getSurveySchemas_getSurveySchemas_surveySchemas_sharedWithUsers_doctorProfile {
  __typename: "DoctorProfile";
  title: string;
  firstName: string;
  lastName: string;
  userId: string;
}

export interface getSurveySchemas_getSurveySchemas_surveySchemas_sharedWithUsers {
  __typename: "User";
  id: string;
  doctorProfile: getSurveySchemas_getSurveySchemas_surveySchemas_sharedWithUsers_doctorProfile | null;
}

export interface getSurveySchemas_getSurveySchemas_surveySchemas_surveySchemaQuestions {
  __typename: "SurveySchemaQuestion";
  id: string;
}

export interface getSurveySchemas_getSurveySchemas_surveySchemas_user_doctorProfile {
  __typename: "DoctorProfile";
  title: string;
  firstName: string;
  lastName: string;
}

export interface getSurveySchemas_getSurveySchemas_surveySchemas_user {
  __typename: "User";
  id: string;
  doctorProfile: getSurveySchemas_getSurveySchemas_surveySchemas_user_doctorProfile | null;
}

export interface getSurveySchemas_getSurveySchemas_surveySchemas {
  __typename: "SurveySchema";
  id: string;
  title: string;
  description: string;
  patientDescription: string;
  categories: SurveyCategoryEnum[];
  isGlobal: boolean;
  status: SurveySchemaStatusEnum;
  canBeDeleted: boolean;
  referenceUrls: getSurveySchemas_getSurveySchemas_surveySchemas_referenceUrls[] | null;
  snomedCode: string | null;
  bnoCodes: getSurveySchemas_getSurveySchemas_surveySchemas_bnoCodes[];
  professions: getSurveySchemas_getSurveySchemas_surveySchemas_professions[];
  institutions: getSurveySchemas_getSurveySchemas_surveySchemas_institutions[];
  sharedWithUsers: getSurveySchemas_getSurveySchemas_surveySchemas_sharedWithUsers[];
  surveySchemaQuestions: getSurveySchemas_getSurveySchemas_surveySchemas_surveySchemaQuestions[];
  user: getSurveySchemas_getSurveySchemas_surveySchemas_user;
}

export interface getSurveySchemas_getSurveySchemas {
  __typename: "SurveySchemaList";
  surveySchemas: getSurveySchemas_getSurveySchemas_surveySchemas[];
  total: number;
}

export interface getSurveySchemas {
  getSurveySchemas: getSurveySchemas_getSurveySchemas;
}

export interface getSurveySchemasVariables {
  filterData?: SurveySchemaFilter | null;
  paginationInput?: PaginationInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getSurveySchemaById
// ====================================================

export interface getSurveySchemaById_getSurveySchemaById_referenceUrls {
  __typename: "SurveySchemaReferenceUrl";
  name: string;
  url: string;
}

export interface getSurveySchemaById_getSurveySchemaById_bnoCodes {
  __typename: "BnoCode";
  id: string;
  code: string;
  title: string;
}

export interface getSurveySchemaById_getSurveySchemaById_professions {
  __typename: "Profession";
  id: string;
  code: string;
  name: string;
}

export interface getSurveySchemaById_getSurveySchemaById_institutions {
  __typename: "Institution";
  id: string;
  name: string;
}

export interface getSurveySchemaById_getSurveySchemaById_sharedWithUsers_doctorProfile {
  __typename: "DoctorProfile";
  title: string;
  firstName: string;
  lastName: string;
  userId: string;
}

export interface getSurveySchemaById_getSurveySchemaById_sharedWithUsers {
  __typename: "User";
  id: string;
  doctorProfile: getSurveySchemaById_getSurveySchemaById_sharedWithUsers_doctorProfile | null;
}

export interface getSurveySchemaById_getSurveySchemaById_user {
  __typename: "User";
  id: string;
}

export interface getSurveySchemaById_getSurveySchemaById_surveySchemaQuestions_data_nextSectionInformation {
  __typename: "NextSectionInformation";
  choiceId: string;
  choiceIndex: number;
  nextSectionOrder: number;
}

export interface getSurveySchemaById_getSurveySchemaById_surveySchemaQuestions_data {
  __typename: "SurveyQuestion2SurveySchemaData";
  nextSectionInformation: getSurveySchemaById_getSurveySchemaById_surveySchemaQuestions_data_nextSectionInformation[];
}

export interface getSurveySchemaById_getSurveySchemaById_surveySchemaQuestions_surveyQuestion_data_scaleData_options {
  __typename: "ScaleOption";
  value: number;
  label: string | null;
}

export interface getSurveySchemaById_getSurveySchemaById_surveySchemaQuestions_surveyQuestion_data_scaleData {
  __typename: "ScaleData";
  options: getSurveySchemaById_getSurveySchemaById_surveySchemaQuestions_surveyQuestion_data_scaleData_options[];
}

export interface getSurveySchemaById_getSurveySchemaById_surveySchemaQuestions_surveyQuestion_data_fileUploadData {
  __typename: "FileUploadData";
  maxItemsCount: number;
  maxFileSize: SurveyQuestionFileSize;
  validFileTypes: SurveyQuestionValidFileType[];
}

export interface getSurveySchemaById_getSurveySchemaById_surveySchemaQuestions_surveyQuestion_data {
  __typename: "QuestionData";
  multipleChoice: boolean | null;
  scaleData: getSurveySchemaById_getSurveySchemaById_surveySchemaQuestions_surveyQuestion_data_scaleData | null;
  fileUploadData: getSurveySchemaById_getSurveySchemaById_surveySchemaQuestions_surveyQuestion_data_fileUploadData | null;
}

export interface getSurveySchemaById_getSurveySchemaById_surveySchemaQuestions_surveyQuestion_mcSurveyQuestionChoices {
  __typename: "AnswerOption";
  id: string | null;
  name: string;
}

export interface getSurveySchemaById_getSurveySchemaById_surveySchemaQuestions_surveyQuestion_sclSurveyQuestionAspects {
  __typename: "AnswerOption";
  id: string | null;
  name: string;
}

export interface getSurveySchemaById_getSurveySchemaById_surveySchemaQuestions_surveyQuestion {
  __typename: "SurveyQuestion";
  id: string;
  title: string;
  description: string | null;
  type: SurveyQuestionEnum;
  surveySchemaId: string | null;
  isGlobal: boolean;
  data: getSurveySchemaById_getSurveySchemaById_surveySchemaQuestions_surveyQuestion_data | null;
  mcSurveyQuestionChoices: getSurveySchemaById_getSurveySchemaById_surveySchemaQuestions_surveyQuestion_mcSurveyQuestionChoices[];
  sclSurveyQuestionAspects: getSurveySchemaById_getSurveySchemaById_surveySchemaQuestions_surveyQuestion_sclSurveyQuestionAspects[];
}

export interface getSurveySchemaById_getSurveySchemaById_surveySchemaQuestions {
  __typename: "SurveySchemaQuestion";
  id: string;
  required: boolean;
  orderInSection: number;
  section: number;
  sectionName: string | null;
  data: getSurveySchemaById_getSurveySchemaById_surveySchemaQuestions_data | null;
  surveyQuestion: getSurveySchemaById_getSurveySchemaById_surveySchemaQuestions_surveyQuestion;
}

export interface getSurveySchemaById_getSurveySchemaById {
  __typename: "SurveySchema";
  id: string;
  title: string;
  description: string;
  patientDescription: string;
  categories: SurveyCategoryEnum[];
  isGlobal: boolean;
  status: SurveySchemaStatusEnum;
  canBeDeleted: boolean;
  referenceUrls: getSurveySchemaById_getSurveySchemaById_referenceUrls[] | null;
  snomedCode: string | null;
  bnoCodes: getSurveySchemaById_getSurveySchemaById_bnoCodes[];
  professions: getSurveySchemaById_getSurveySchemaById_professions[];
  institutions: getSurveySchemaById_getSurveySchemaById_institutions[];
  sharedWithUsers: getSurveySchemaById_getSurveySchemaById_sharedWithUsers[];
  user: getSurveySchemaById_getSurveySchemaById_user;
  surveySchemaQuestions: getSurveySchemaById_getSurveySchemaById_surveySchemaQuestions[];
}

export interface getSurveySchemaById {
  getSurveySchemaById: getSurveySchemaById_getSurveySchemaById;
}

export interface getSurveySchemaByIdVariables {
  surveySchemaId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createSurveySchema
// ====================================================

export interface createSurveySchema_createSurveySchema {
  __typename: "SurveySchema";
  id: string;
}

export interface createSurveySchema {
  createSurveySchema: createSurveySchema_createSurveySchema;
}

export interface createSurveySchemaVariables {
  surveySchemaData: CreateSurveySchemaInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateSurveySchema
// ====================================================

export interface updateSurveySchema_updateSurveySchema {
  __typename: "SurveySchema";
  id: string;
}

export interface updateSurveySchema {
  updateSurveySchema: updateSurveySchema_updateSurveySchema;
}

export interface updateSurveySchemaVariables {
  surveySchemaData: UpdateSurveySchemaInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateSurveySchemaStatus
// ====================================================

export interface updateSurveySchemaStatus_updateSurveySchemaStatus {
  __typename: "SurveySchema";
  id: string;
}

export interface updateSurveySchemaStatus {
  updateSurveySchemaStatus: updateSurveySchemaStatus_updateSurveySchemaStatus;
}

export interface updateSurveySchemaStatusVariables {
  surveySchemaId: string;
  status: SurveySchemaStatusEnum;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteSurveySchema
// ====================================================

export interface deleteSurveySchema_deleteSurveySchema {
  __typename: "SurveySchema";
  id: string;
}

export interface deleteSurveySchema {
  deleteSurveySchema: deleteSurveySchema_deleteSurveySchema;
}

export interface deleteSurveySchemaVariables {
  surveySchemaId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: sendSurveyToUsers
// ====================================================

export interface sendSurveyToUsers_sendSurveyToUsers {
  __typename: "Survey";
  id: string;
}

export interface sendSurveyToUsers {
  sendSurveyToUsers: sendSurveyToUsers_sendSurveyToUsers[];
}

export interface sendSurveyToUsersVariables {
  surveySchemaId: string;
  userIds: string[];
  fillableFrom?: any | null;
  fillableUntil?: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteSurveysFromTreatment
// ====================================================

export interface deleteSurveysFromTreatment {
  deleteSurveysFromTreatment: boolean;
}

export interface deleteSurveysFromTreatmentVariables {
  treatmentId: string;
  surveyIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: shareSurveySchema
// ====================================================

export interface shareSurveySchema_shareSurveySchema {
  __typename: "SurveySchema";
  id: string;
}

export interface shareSurveySchema {
  shareSurveySchema: shareSurveySchema_shareSurveySchema;
}

export interface shareSurveySchemaVariables {
  shareSurveySchemaInput: ShareSurveySchemaInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: duplicateSurveySchema
// ====================================================

export interface duplicateSurveySchema_duplicateSurveySchema {
  __typename: "SurveySchema";
  id: string;
}

export interface duplicateSurveySchema {
  duplicateSurveySchema: duplicateSurveySchema_duplicateSurveySchema;
}

export interface duplicateSurveySchemaVariables {
  surveySchemaId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetIndividualSurveyResults
// ====================================================

export interface GetIndividualSurveyResults_getIndividualSurveyResults_surveys_doctor_institutionToDoctor_institution {
  __typename: "Institution";
  name: string;
}

export interface GetIndividualSurveyResults_getIndividualSurveyResults_surveys_doctor_institutionToDoctor {
  __typename: "InstitutionToDoctor";
  institution: GetIndividualSurveyResults_getIndividualSurveyResults_surveys_doctor_institutionToDoctor_institution;
}

export interface GetIndividualSurveyResults_getIndividualSurveyResults_surveys_doctor {
  __typename: "DoctorProfile";
  title: string;
  firstName: string;
  lastName: string;
  institutionToDoctor: GetIndividualSurveyResults_getIndividualSurveyResults_surveys_doctor_institutionToDoctor[];
}

export interface GetIndividualSurveyResults_getIndividualSurveyResults_surveys_patient {
  __typename: "PatientProfile";
  firstName: string;
  lastName: string;
  tajNumber: string;
}

export interface GetIndividualSurveyResults_getIndividualSurveyResults_surveys_surveyQuestionAnswers_surveyQuestion {
  __typename: "SurveyQuestion";
  id: string;
}

export interface GetIndividualSurveyResults_getIndividualSurveyResults_surveys_surveyQuestionAnswers_mcQuestionAnswer {
  __typename: "McQuestionAnswer";
  selectedChoiceIds: string[] | null;
}

export interface GetIndividualSurveyResults_getIndividualSurveyResults_surveys_surveyQuestionAnswers_scaleQuestionAnswer_aspectAnswers {
  __typename: "AspectAnswer";
  aspectId: string;
  scaleValue: number;
}

export interface GetIndividualSurveyResults_getIndividualSurveyResults_surveys_surveyQuestionAnswers_scaleQuestionAnswer {
  __typename: "ScaleQuestionAnswer";
  aspectAnswers: GetIndividualSurveyResults_getIndividualSurveyResults_surveys_surveyQuestionAnswers_scaleQuestionAnswer_aspectAnswers[] | null;
}

export interface GetIndividualSurveyResults_getIndividualSurveyResults_surveys_surveyQuestionAnswers_simpleTextQuestionAnswer {
  __typename: "SimpleTextQuestionAnswer";
  text: string | null;
}

export interface GetIndividualSurveyResults_getIndividualSurveyResults_surveys_surveyQuestionAnswers_fileUploadQuestionAnswer_documents {
  __typename: "DocumentAnswer";
  documentId: string;
  fileName: string;
}

export interface GetIndividualSurveyResults_getIndividualSurveyResults_surveys_surveyQuestionAnswers_fileUploadQuestionAnswer {
  __typename: "FileUploadQuestionAnswer";
  documents: GetIndividualSurveyResults_getIndividualSurveyResults_surveys_surveyQuestionAnswers_fileUploadQuestionAnswer_documents[] | null;
}

export interface GetIndividualSurveyResults_getIndividualSurveyResults_surveys_surveyQuestionAnswers {
  __typename: "SurveyQuestionAnswer";
  surveyQuestion: GetIndividualSurveyResults_getIndividualSurveyResults_surveys_surveyQuestionAnswers_surveyQuestion | null;
  mcQuestionAnswer: GetIndividualSurveyResults_getIndividualSurveyResults_surveys_surveyQuestionAnswers_mcQuestionAnswer | null;
  scaleQuestionAnswer: GetIndividualSurveyResults_getIndividualSurveyResults_surveys_surveyQuestionAnswers_scaleQuestionAnswer | null;
  simpleTextQuestionAnswer: GetIndividualSurveyResults_getIndividualSurveyResults_surveys_surveyQuestionAnswers_simpleTextQuestionAnswer | null;
  fileUploadQuestionAnswer: GetIndividualSurveyResults_getIndividualSurveyResults_surveys_surveyQuestionAnswers_fileUploadQuestionAnswer | null;
}

export interface GetIndividualSurveyResults_getIndividualSurveyResults_surveys {
  __typename: "Survey";
  fillableFrom: any;
  finishedAt: any | null;
  doctor: GetIndividualSurveyResults_getIndividualSurveyResults_surveys_doctor | null;
  patient: GetIndividualSurveyResults_getIndividualSurveyResults_surveys_patient | null;
  surveyQuestionAnswers: GetIndividualSurveyResults_getIndividualSurveyResults_surveys_surveyQuestionAnswers[];
}

export interface GetIndividualSurveyResults_getIndividualSurveyResults {
  __typename: "IndividualResults";
  total: number;
  surveys: GetIndividualSurveyResults_getIndividualSurveyResults_surveys[];
  csvInBase64: string;
  xlsxInBase64: string;
}

export interface GetIndividualSurveyResults {
  getIndividualSurveyResults: GetIndividualSurveyResults_getIndividualSurveyResults;
}

export interface GetIndividualSurveyResultsVariables {
  surveySchemaId: string;
  isAnonymous: boolean;
  paginationInput?: PaginationInput | null;
  dateIntervalInput?: DateIntervalInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: aggregatedSurveyResults
// ====================================================

export interface aggregatedSurveyResults_getAggregatedSurveyResults_surveyQuestionResultList_MultipleChoiceQuestionResult_choiceResults {
  __typename: "ChoiceResult";
  id: string;
  name: string;
  total: number;
}

export interface aggregatedSurveyResults_getAggregatedSurveyResults_surveyQuestionResultList_MultipleChoiceQuestionResult {
  __typename: "MultipleChoiceQuestionResult";
  questionId: string;
  totalAnswers: number;
  choiceResults: aggregatedSurveyResults_getAggregatedSurveyResults_surveyQuestionResultList_MultipleChoiceQuestionResult_choiceResults[];
}

export interface aggregatedSurveyResults_getAggregatedSurveyResults_surveyQuestionResultList_ScaleQuestionResult_scaleOptionResultsWithoutScaleAspect {
  __typename: "ScaleOptionResult";
  value: number;
  label: string | null;
  total: number;
}

export interface aggregatedSurveyResults_getAggregatedSurveyResults_surveyQuestionResultList_ScaleQuestionResult_scaleAspectResults_scaleOptionResults {
  __typename: "ScaleOptionResult";
  value: number;
  label: string | null;
  total: number;
}

export interface aggregatedSurveyResults_getAggregatedSurveyResults_surveyQuestionResultList_ScaleQuestionResult_scaleAspectResults {
  __typename: "ScaleAspectResult";
  totalAnswers: number;
  id: string;
  name: string;
  scaleOptionResults: aggregatedSurveyResults_getAggregatedSurveyResults_surveyQuestionResultList_ScaleQuestionResult_scaleAspectResults_scaleOptionResults[];
}

export interface aggregatedSurveyResults_getAggregatedSurveyResults_surveyQuestionResultList_ScaleQuestionResult {
  __typename: "ScaleQuestionResult";
  questionId: string;
  /**
   * It is null in case number of scale aspects >= 1
   */
  totalAnswersWithoutScaleAspect: number | null;
  /**
   * It is empty in case number of scale aspects >= 1
   */
  scaleOptionResultsWithoutScaleAspect: aggregatedSurveyResults_getAggregatedSurveyResults_surveyQuestionResultList_ScaleQuestionResult_scaleOptionResultsWithoutScaleAspect[];
  /**
   * It is empty in case number of scale aspects < 1
   */
  scaleAspectResults: aggregatedSurveyResults_getAggregatedSurveyResults_surveyQuestionResultList_ScaleQuestionResult_scaleAspectResults[];
}

export interface aggregatedSurveyResults_getAggregatedSurveyResults_surveyQuestionResultList_SimpleTextQuestionResult {
  __typename: "SimpleTextQuestionResult";
  questionId: string;
  totalAnswers: number;
  answers: string[];
}

export interface aggregatedSurveyResults_getAggregatedSurveyResults_surveyQuestionResultList_FileUploadQuestionResult_documents {
  __typename: "DocumentAnswer";
  documentId: string;
  fileName: string;
}

export interface aggregatedSurveyResults_getAggregatedSurveyResults_surveyQuestionResultList_FileUploadQuestionResult {
  __typename: "FileUploadQuestionResult";
  questionId: string;
  totalSubmitters: number;
  documents: aggregatedSurveyResults_getAggregatedSurveyResults_surveyQuestionResultList_FileUploadQuestionResult_documents[];
}

export type aggregatedSurveyResults_getAggregatedSurveyResults_surveyQuestionResultList = aggregatedSurveyResults_getAggregatedSurveyResults_surveyQuestionResultList_MultipleChoiceQuestionResult | aggregatedSurveyResults_getAggregatedSurveyResults_surveyQuestionResultList_ScaleQuestionResult | aggregatedSurveyResults_getAggregatedSurveyResults_surveyQuestionResultList_SimpleTextQuestionResult | aggregatedSurveyResults_getAggregatedSurveyResults_surveyQuestionResultList_FileUploadQuestionResult;

export interface aggregatedSurveyResults_getAggregatedSurveyResults {
  __typename: "AggregatedSurveyResults";
  surveyQuestionResultList: aggregatedSurveyResults_getAggregatedSurveyResults_surveyQuestionResultList[];
  totalSubmitters: number;
  csvInBase64: string;
  xlsxInBase64: string;
}

export interface aggregatedSurveyResults {
  getAggregatedSurveyResults: aggregatedSurveyResults_getAggregatedSurveyResults;
}

export interface aggregatedSurveyResultsVariables {
  surveySchemaId: string;
  dateIntervalInput?: DateIntervalInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getSurveySchemaForResults
// ====================================================

export interface getSurveySchemaForResults_getSurveySchemaById_referenceUrls {
  __typename: "SurveySchemaReferenceUrl";
  name: string;
  url: string;
}

export interface getSurveySchemaForResults_getSurveySchemaById_bnoCodes {
  __typename: "BnoCode";
  id: string;
  code: string;
  title: string;
}

export interface getSurveySchemaForResults_getSurveySchemaById_professions {
  __typename: "Profession";
  id: string;
  code: string;
  name: string;
}

export interface getSurveySchemaForResults_getSurveySchemaById_institutions {
  __typename: "Institution";
  id: string;
  name: string;
}

export interface getSurveySchemaForResults_getSurveySchemaById_sharedWithUsers_doctorProfile {
  __typename: "DoctorProfile";
  title: string;
  firstName: string;
  lastName: string;
  userId: string;
}

export interface getSurveySchemaForResults_getSurveySchemaById_sharedWithUsers {
  __typename: "User";
  id: string;
  doctorProfile: getSurveySchemaForResults_getSurveySchemaById_sharedWithUsers_doctorProfile | null;
}

export interface getSurveySchemaForResults_getSurveySchemaById_user {
  __typename: "User";
  id: string;
}

export interface getSurveySchemaForResults_getSurveySchemaById_surveySchemaQuestions_data_nextSectionInformation {
  __typename: "NextSectionInformation";
  choiceId: string;
  choiceIndex: number;
  nextSectionOrder: number;
}

export interface getSurveySchemaForResults_getSurveySchemaById_surveySchemaQuestions_data {
  __typename: "SurveyQuestion2SurveySchemaData";
  nextSectionInformation: getSurveySchemaForResults_getSurveySchemaById_surveySchemaQuestions_data_nextSectionInformation[];
}

export interface getSurveySchemaForResults_getSurveySchemaById_surveySchemaQuestions_surveyQuestion_data_scaleData_options {
  __typename: "ScaleOption";
  value: number;
  label: string | null;
}

export interface getSurveySchemaForResults_getSurveySchemaById_surveySchemaQuestions_surveyQuestion_data_scaleData {
  __typename: "ScaleData";
  options: getSurveySchemaForResults_getSurveySchemaById_surveySchemaQuestions_surveyQuestion_data_scaleData_options[];
}

export interface getSurveySchemaForResults_getSurveySchemaById_surveySchemaQuestions_surveyQuestion_data_fileUploadData {
  __typename: "FileUploadData";
  maxItemsCount: number;
  maxFileSize: SurveyQuestionFileSize;
  validFileTypes: SurveyQuestionValidFileType[];
}

export interface getSurveySchemaForResults_getSurveySchemaById_surveySchemaQuestions_surveyQuestion_data {
  __typename: "QuestionData";
  multipleChoice: boolean | null;
  scaleData: getSurveySchemaForResults_getSurveySchemaById_surveySchemaQuestions_surveyQuestion_data_scaleData | null;
  fileUploadData: getSurveySchemaForResults_getSurveySchemaById_surveySchemaQuestions_surveyQuestion_data_fileUploadData | null;
}

export interface getSurveySchemaForResults_getSurveySchemaById_surveySchemaQuestions_surveyQuestion_mcSurveyQuestionChoices {
  __typename: "AnswerOption";
  id: string | null;
  name: string;
}

export interface getSurveySchemaForResults_getSurveySchemaById_surveySchemaQuestions_surveyQuestion_sclSurveyQuestionAspects {
  __typename: "AnswerOption";
  id: string | null;
  name: string;
}

export interface getSurveySchemaForResults_getSurveySchemaById_surveySchemaQuestions_surveyQuestion {
  __typename: "SurveyQuestion";
  id: string;
  title: string;
  description: string | null;
  type: SurveyQuestionEnum;
  surveySchemaId: string | null;
  isGlobal: boolean;
  data: getSurveySchemaForResults_getSurveySchemaById_surveySchemaQuestions_surveyQuestion_data | null;
  mcSurveyQuestionChoices: getSurveySchemaForResults_getSurveySchemaById_surveySchemaQuestions_surveyQuestion_mcSurveyQuestionChoices[];
  sclSurveyQuestionAspects: getSurveySchemaForResults_getSurveySchemaById_surveySchemaQuestions_surveyQuestion_sclSurveyQuestionAspects[];
}

export interface getSurveySchemaForResults_getSurveySchemaById_surveySchemaQuestions {
  __typename: "SurveySchemaQuestion";
  id: string;
  required: boolean;
  orderInSection: number;
  section: number;
  sectionName: string | null;
  data: getSurveySchemaForResults_getSurveySchemaById_surveySchemaQuestions_data | null;
  surveyQuestion: getSurveySchemaForResults_getSurveySchemaById_surveySchemaQuestions_surveyQuestion;
}

export interface getSurveySchemaForResults_getSurveySchemaById {
  __typename: "SurveySchema";
  id: string;
  title: string;
  description: string;
  patientDescription: string;
  categories: SurveyCategoryEnum[];
  isGlobal: boolean;
  status: SurveySchemaStatusEnum;
  canBeDeleted: boolean;
  referenceUrls: getSurveySchemaForResults_getSurveySchemaById_referenceUrls[] | null;
  snomedCode: string | null;
  bnoCodes: getSurveySchemaForResults_getSurveySchemaById_bnoCodes[];
  professions: getSurveySchemaForResults_getSurveySchemaById_professions[];
  institutions: getSurveySchemaForResults_getSurveySchemaById_institutions[];
  sharedWithUsers: getSurveySchemaForResults_getSurveySchemaById_sharedWithUsers[];
  user: getSurveySchemaForResults_getSurveySchemaById_user;
  surveySchemaQuestions: getSurveySchemaForResults_getSurveySchemaById_surveySchemaQuestions[];
}

export interface getSurveySchemaForResults {
  getSurveySchemaById: getSurveySchemaForResults_getSurveySchemaById;
}

export interface getSurveySchemaForResultsVariables {
  surveySchemaId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getTreatmentSchemas
// ====================================================

export interface getTreatmentSchemas_getTreatmentSchemas_treatmentSchemas_description {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface getTreatmentSchemas_getTreatmentSchemas_treatmentSchemas_title {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface getTreatmentSchemas_getTreatmentSchemas_treatmentSchemas_professions {
  __typename: "Profession";
  id: string;
  code: string;
  name: string;
}

export interface getTreatmentSchemas_getTreatmentSchemas_treatmentSchemas_institutions {
  __typename: "Institution";
  id: string;
  name: string;
}

export interface getTreatmentSchemas_getTreatmentSchemas_treatmentSchemas_user_doctorProfile {
  __typename: "DoctorProfile";
  title: string;
  firstName: string;
  lastName: string;
}

export interface getTreatmentSchemas_getTreatmentSchemas_treatmentSchemas_user {
  __typename: "User";
  id: string;
  doctorProfile: getTreatmentSchemas_getTreatmentSchemas_treatmentSchemas_user_doctorProfile | null;
}

export interface getTreatmentSchemas_getTreatmentSchemas_treatmentSchemas_sharedWith_doctorProfile {
  __typename: "DoctorProfile";
  title: string;
  firstName: string;
  lastName: string;
}

export interface getTreatmentSchemas_getTreatmentSchemas_treatmentSchemas_sharedWith {
  __typename: "User";
  id: string;
  doctorProfile: getTreatmentSchemas_getTreatmentSchemas_treatmentSchemas_sharedWith_doctorProfile | null;
}

export interface getTreatmentSchemas_getTreatmentSchemas_treatmentSchemas_bnoCodes {
  __typename: "BnoCode";
  id: string;
  code: string;
  title: string;
}

export interface getTreatmentSchemas_getTreatmentSchemas_treatmentSchemas {
  __typename: "TreatmentSchema";
  id: string;
  description: getTreatmentSchemas_getTreatmentSchemas_treatmentSchemas_description | null;
  title: getTreatmentSchemas_getTreatmentSchemas_treatmentSchemas_title;
  professions: getTreatmentSchemas_getTreatmentSchemas_treatmentSchemas_professions[];
  institutions: getTreatmentSchemas_getTreatmentSchemas_treatmentSchemas_institutions[];
  disabled: boolean;
  user: getTreatmentSchemas_getTreatmentSchemas_treatmentSchemas_user;
  sharedWith: getTreatmentSchemas_getTreatmentSchemas_treatmentSchemas_sharedWith[];
  url: string | null;
  bnoCodes: getTreatmentSchemas_getTreatmentSchemas_treatmentSchemas_bnoCodes[];
  snowmedCode: string | null;
  isGlobal: boolean | null;
  optimalDuration: number | null;
  isDeletable: boolean;
}

export interface getTreatmentSchemas_getTreatmentSchemas {
  __typename: "TreatmentSchemaList";
  treatmentSchemas: getTreatmentSchemas_getTreatmentSchemas_treatmentSchemas[];
  total: number;
}

export interface getTreatmentSchemas {
  getTreatmentSchemas: getTreatmentSchemas_getTreatmentSchemas;
}

export interface getTreatmentSchemasVariables {
  treatmentSchemaFilter?: TreatmentSchemaFilter | null;
  paginationInput?: PaginationInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getTreatmentSchema
// ====================================================

export interface getTreatmentSchema_getTreatmentSchema_description {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface getTreatmentSchema_getTreatmentSchema_title {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface getTreatmentSchema_getTreatmentSchema_professions {
  __typename: "Profession";
  id: string;
  code: string;
  name: string;
}

export interface getTreatmentSchema_getTreatmentSchema_institutions {
  __typename: "Institution";
  id: string;
  name: string;
}

export interface getTreatmentSchema_getTreatmentSchema_user_doctorProfile {
  __typename: "DoctorProfile";
  title: string;
  firstName: string;
  lastName: string;
}

export interface getTreatmentSchema_getTreatmentSchema_user {
  __typename: "User";
  id: string;
  doctorProfile: getTreatmentSchema_getTreatmentSchema_user_doctorProfile | null;
}

export interface getTreatmentSchema_getTreatmentSchema_sharedWith_doctorProfile {
  __typename: "DoctorProfile";
  title: string;
  firstName: string;
  lastName: string;
}

export interface getTreatmentSchema_getTreatmentSchema_sharedWith {
  __typename: "User";
  id: string;
  doctorProfile: getTreatmentSchema_getTreatmentSchema_sharedWith_doctorProfile | null;
}

export interface getTreatmentSchema_getTreatmentSchema_bnoCodes {
  __typename: "BnoCode";
  id: string;
  code: string;
  title: string;
}

export interface getTreatmentSchema_getTreatmentSchema_surveySchemas_surveySchema {
  __typename: "SurveySchema";
  id: string;
  title: string;
}

export interface getTreatmentSchema_getTreatmentSchema_surveySchemas {
  __typename: "TreatmentSchemaToSurvey";
  id: string;
  customDays: number | null;
  surveySchema: getTreatmentSchema_getTreatmentSchema_surveySchemas_surveySchema;
}

export interface getTreatmentSchema_getTreatmentSchema_schedules_appointmentInfo_goodToKnow {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface getTreatmentSchema_getTreatmentSchema_schedules_appointmentInfo_doctorTitle {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface getTreatmentSchema_getTreatmentSchema_schedules_appointmentInfo_patientTitle {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface getTreatmentSchema_getTreatmentSchema_schedules_appointmentInfo_doctorTodo {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface getTreatmentSchema_getTreatmentSchema_schedules_appointmentInfo_professions {
  __typename: "Profession";
  id: string;
  name: string;
}

export interface getTreatmentSchema_getTreatmentSchema_schedules_appointmentInfo {
  __typename: "AppointmentInfo";
  id: string;
  goodToKnow: getTreatmentSchema_getTreatmentSchema_schedules_appointmentInfo_goodToKnow;
  doctorTitle: getTreatmentSchema_getTreatmentSchema_schedules_appointmentInfo_doctorTitle;
  patientTitle: getTreatmentSchema_getTreatmentSchema_schedules_appointmentInfo_patientTitle;
  doctorTodo: getTreatmentSchema_getTreatmentSchema_schedules_appointmentInfo_doctorTodo;
  professions: getTreatmentSchema_getTreatmentSchema_schedules_appointmentInfo_professions[] | null;
  beforeTreatmentNote: boolean | null;
  oeno: string | null;
}

export interface getTreatmentSchema_getTreatmentSchema_schedules_doctorTitle {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface getTreatmentSchema_getTreatmentSchema_schedules_date {
  __typename: "StartEndDates";
  start: any;
  end: any | null;
}

export interface getTreatmentSchema_getTreatmentSchema_schedules_dependencies {
  __typename: "Dependency";
  id: string;
  fromId: string;
  distance: string;
  type: AppointmentDependencyTypes;
}

export interface getTreatmentSchema_getTreatmentSchema_schedules_dependents {
  __typename: "Dependency";
  id: string;
  fromId: string;
  distance: string;
}

export interface getTreatmentSchema_getTreatmentSchema_schedules {
  __typename: "PlainTreatmentElement";
  id: string;
  createdAt: any;
  customDays: number | null;
  appointmentInfo: getTreatmentSchema_getTreatmentSchema_schedules_appointmentInfo;
  doctorTitle: getTreatmentSchema_getTreatmentSchema_schedules_doctorTitle | null;
  date: getTreatmentSchema_getTreatmentSchema_schedules_date;
  dependencies: getTreatmentSchema_getTreatmentSchema_schedules_dependencies[];
  dependents: getTreatmentSchema_getTreatmentSchema_schedules_dependents[];
}

export interface getTreatmentSchema_getTreatmentSchema {
  __typename: "TreatmentSchema";
  id: string;
  description: getTreatmentSchema_getTreatmentSchema_description | null;
  title: getTreatmentSchema_getTreatmentSchema_title;
  professions: getTreatmentSchema_getTreatmentSchema_professions[];
  institutions: getTreatmentSchema_getTreatmentSchema_institutions[];
  disabled: boolean;
  user: getTreatmentSchema_getTreatmentSchema_user;
  sharedWith: getTreatmentSchema_getTreatmentSchema_sharedWith[];
  url: string | null;
  bnoCodes: getTreatmentSchema_getTreatmentSchema_bnoCodes[];
  snowmedCode: string | null;
  isGlobal: boolean | null;
  optimalDuration: number | null;
  isDeletable: boolean;
  surveySchemas: getTreatmentSchema_getTreatmentSchema_surveySchemas[];
  schedules: getTreatmentSchema_getTreatmentSchema_schedules[];
}

export interface getTreatmentSchema {
  getTreatmentSchema: getTreatmentSchema_getTreatmentSchema;
}

export interface getTreatmentSchemaVariables {
  treatmentSchemaId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createNewTreatmentSchema
// ====================================================

export interface createNewTreatmentSchema_createNewTreatmentSchema {
  __typename: "TreatmentSchema";
  id: string;
}

export interface createNewTreatmentSchema {
  createNewTreatmentSchema: createNewTreatmentSchema_createNewTreatmentSchema;
}

export interface createNewTreatmentSchemaVariables {
  schema: TreatmentSchemaCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addSchedule
// ====================================================

export interface addSchedule_addSchedule {
  __typename: "SchemaSchedule";
  id: string;
}

export interface addSchedule {
  addSchedule: addSchedule_addSchedule;
}

export interface addScheduleVariables {
  treatmentSchemaId: string;
  appointmentInfoId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createSchedules
// ====================================================

export interface createSchedules_createSchedules {
  __typename: "SchemaSchedule";
  id: string;
}

export interface createSchedules {
  createSchedules: createSchedules_createSchedules[];
}

export interface createSchedulesVariables {
  treatmentSchemaId: string;
  appointmentInfoIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeSchedules
// ====================================================

export interface removeSchedules {
  removeSchedules: number;
}

export interface removeSchedulesVariables {
  scheduleIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeSchedule
// ====================================================

export interface removeSchedule_removeSchedule {
  __typename: "SchemaSchedule";
  id: string;
}

export interface removeSchedule {
  removeSchedule: removeSchedule_removeSchedule;
}

export interface removeScheduleVariables {
  scheduleId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addSchemaDependency
// ====================================================

export interface addSchemaDependency_addSchemaDependency {
  __typename: "SchemaDependency";
  id: string;
}

export interface addSchemaDependency {
  addSchemaDependency: addSchemaDependency_addSchemaDependency;
}

export interface addSchemaDependencyVariables {
  dependencyInput: CreateDependencyInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeSchemaDependency
// ====================================================

export interface removeSchemaDependency_removeSchemaDependency {
  __typename: "SchemaDependency";
  id: string;
}

export interface removeSchemaDependency {
  removeSchemaDependency: removeSchemaDependency_removeSchemaDependency;
}

export interface removeSchemaDependencyVariables {
  treatmentSchemaId: string;
  dependencyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateSchemaDependency
// ====================================================

export interface updateSchemaDependency_updateSchemaDependency {
  __typename: "SchemaDependency";
  id: string;
}

export interface updateSchemaDependency {
  updateSchemaDependency: updateSchemaDependency_updateSchemaDependency;
}

export interface updateSchemaDependencyVariables {
  dependencyInput: UpdateDependencyInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeTreatmentSchema
// ====================================================

export interface removeTreatmentSchema_removeTreatmentSchema {
  __typename: "TreatmentSchema";
  id: string;
}

export interface removeTreatmentSchema {
  removeTreatmentSchema: removeTreatmentSchema_removeTreatmentSchema;
}

export interface removeTreatmentSchemaVariables {
  treatmentSchemaId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateTreatmentSchema
// ====================================================

export interface updateTreatmentSchema_updateTreatmentSchema_description {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface updateTreatmentSchema_updateTreatmentSchema_title {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface updateTreatmentSchema_updateTreatmentSchema_professions {
  __typename: "Profession";
  id: string;
  code: string;
  name: string;
}

export interface updateTreatmentSchema_updateTreatmentSchema_institutions {
  __typename: "Institution";
  id: string;
  name: string;
}

export interface updateTreatmentSchema_updateTreatmentSchema_user_doctorProfile {
  __typename: "DoctorProfile";
  title: string;
  firstName: string;
  lastName: string;
}

export interface updateTreatmentSchema_updateTreatmentSchema_user {
  __typename: "User";
  id: string;
  doctorProfile: updateTreatmentSchema_updateTreatmentSchema_user_doctorProfile | null;
}

export interface updateTreatmentSchema_updateTreatmentSchema_sharedWith_doctorProfile {
  __typename: "DoctorProfile";
  title: string;
  firstName: string;
  lastName: string;
}

export interface updateTreatmentSchema_updateTreatmentSchema_sharedWith {
  __typename: "User";
  id: string;
  doctorProfile: updateTreatmentSchema_updateTreatmentSchema_sharedWith_doctorProfile | null;
}

export interface updateTreatmentSchema_updateTreatmentSchema_bnoCodes {
  __typename: "BnoCode";
  id: string;
  code: string;
  title: string;
}

export interface updateTreatmentSchema_updateTreatmentSchema {
  __typename: "TreatmentSchema";
  id: string;
  description: updateTreatmentSchema_updateTreatmentSchema_description | null;
  title: updateTreatmentSchema_updateTreatmentSchema_title;
  professions: updateTreatmentSchema_updateTreatmentSchema_professions[];
  institutions: updateTreatmentSchema_updateTreatmentSchema_institutions[];
  disabled: boolean;
  user: updateTreatmentSchema_updateTreatmentSchema_user;
  sharedWith: updateTreatmentSchema_updateTreatmentSchema_sharedWith[];
  url: string | null;
  bnoCodes: updateTreatmentSchema_updateTreatmentSchema_bnoCodes[];
  snowmedCode: string | null;
  isGlobal: boolean | null;
  optimalDuration: number | null;
}

export interface updateTreatmentSchema {
  updateTreatmentSchema: updateTreatmentSchema_updateTreatmentSchema;
}

export interface updateTreatmentSchemaVariables {
  treatmentData: UpdateSchemaInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateTreatmentSchemaStatus
// ====================================================

export interface updateTreatmentSchemaStatus_updateTreatmentSchemaStatus {
  __typename: "TreatmentSchema";
  id: string;
  disabled: boolean;
  isDeletable: boolean;
}

export interface updateTreatmentSchemaStatus {
  updateTreatmentSchemaStatus: updateTreatmentSchemaStatus_updateTreatmentSchemaStatus;
}

export interface updateTreatmentSchemaStatusVariables {
  treatmentSchemaId: string;
  publish: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: duplicateTreatmentSchema
// ====================================================

export interface duplicateTreatmentSchema_duplicateTreatmentSchema {
  __typename: "TreatmentSchema";
  id: string;
}

export interface duplicateTreatmentSchema {
  duplicateTreatmentSchema: duplicateTreatmentSchema_duplicateTreatmentSchema;
}

export interface duplicateTreatmentSchemaVariables {
  treatmentSchemaId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: shareTreatmentSchema
// ====================================================

export interface shareTreatmentSchema_shareTreatmentSchema_description {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface shareTreatmentSchema_shareTreatmentSchema_title {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface shareTreatmentSchema_shareTreatmentSchema_professions {
  __typename: "Profession";
  id: string;
  code: string;
  name: string;
}

export interface shareTreatmentSchema_shareTreatmentSchema_institutions {
  __typename: "Institution";
  id: string;
  name: string;
}

export interface shareTreatmentSchema_shareTreatmentSchema_user_doctorProfile {
  __typename: "DoctorProfile";
  title: string;
  firstName: string;
  lastName: string;
}

export interface shareTreatmentSchema_shareTreatmentSchema_user {
  __typename: "User";
  id: string;
  doctorProfile: shareTreatmentSchema_shareTreatmentSchema_user_doctorProfile | null;
}

export interface shareTreatmentSchema_shareTreatmentSchema_sharedWith_doctorProfile {
  __typename: "DoctorProfile";
  title: string;
  firstName: string;
  lastName: string;
}

export interface shareTreatmentSchema_shareTreatmentSchema_sharedWith {
  __typename: "User";
  id: string;
  doctorProfile: shareTreatmentSchema_shareTreatmentSchema_sharedWith_doctorProfile | null;
}

export interface shareTreatmentSchema_shareTreatmentSchema_bnoCodes {
  __typename: "BnoCode";
  id: string;
  code: string;
  title: string;
}

export interface shareTreatmentSchema_shareTreatmentSchema {
  __typename: "TreatmentSchema";
  id: string;
  description: shareTreatmentSchema_shareTreatmentSchema_description | null;
  title: shareTreatmentSchema_shareTreatmentSchema_title;
  professions: shareTreatmentSchema_shareTreatmentSchema_professions[];
  institutions: shareTreatmentSchema_shareTreatmentSchema_institutions[];
  disabled: boolean;
  user: shareTreatmentSchema_shareTreatmentSchema_user;
  sharedWith: shareTreatmentSchema_shareTreatmentSchema_sharedWith[];
  url: string | null;
  bnoCodes: shareTreatmentSchema_shareTreatmentSchema_bnoCodes[];
  snowmedCode: string | null;
  isGlobal: boolean | null;
  optimalDuration: number | null;
}

export interface shareTreatmentSchema {
  shareTreatmentSchema: shareTreatmentSchema_shareTreatmentSchema;
}

export interface shareTreatmentSchemaVariables {
  treatmentData: ShareSchemaInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: unSubscribeTreatmentSchema
// ====================================================

export interface unSubscribeTreatmentSchema_unSubscribeTreatmentSchema_description {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface unSubscribeTreatmentSchema_unSubscribeTreatmentSchema_title {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface unSubscribeTreatmentSchema_unSubscribeTreatmentSchema_professions {
  __typename: "Profession";
  id: string;
  code: string;
  name: string;
}

export interface unSubscribeTreatmentSchema_unSubscribeTreatmentSchema_institutions {
  __typename: "Institution";
  id: string;
  name: string;
}

export interface unSubscribeTreatmentSchema_unSubscribeTreatmentSchema_user_doctorProfile {
  __typename: "DoctorProfile";
  title: string;
  firstName: string;
  lastName: string;
}

export interface unSubscribeTreatmentSchema_unSubscribeTreatmentSchema_user {
  __typename: "User";
  id: string;
  doctorProfile: unSubscribeTreatmentSchema_unSubscribeTreatmentSchema_user_doctorProfile | null;
}

export interface unSubscribeTreatmentSchema_unSubscribeTreatmentSchema_sharedWith_doctorProfile {
  __typename: "DoctorProfile";
  title: string;
  firstName: string;
  lastName: string;
}

export interface unSubscribeTreatmentSchema_unSubscribeTreatmentSchema_sharedWith {
  __typename: "User";
  id: string;
  doctorProfile: unSubscribeTreatmentSchema_unSubscribeTreatmentSchema_sharedWith_doctorProfile | null;
}

export interface unSubscribeTreatmentSchema_unSubscribeTreatmentSchema_bnoCodes {
  __typename: "BnoCode";
  id: string;
  code: string;
  title: string;
}

export interface unSubscribeTreatmentSchema_unSubscribeTreatmentSchema {
  __typename: "TreatmentSchema";
  id: string;
  description: unSubscribeTreatmentSchema_unSubscribeTreatmentSchema_description | null;
  title: unSubscribeTreatmentSchema_unSubscribeTreatmentSchema_title;
  professions: unSubscribeTreatmentSchema_unSubscribeTreatmentSchema_professions[];
  institutions: unSubscribeTreatmentSchema_unSubscribeTreatmentSchema_institutions[];
  disabled: boolean;
  user: unSubscribeTreatmentSchema_unSubscribeTreatmentSchema_user;
  sharedWith: unSubscribeTreatmentSchema_unSubscribeTreatmentSchema_sharedWith[];
  url: string | null;
  bnoCodes: unSubscribeTreatmentSchema_unSubscribeTreatmentSchema_bnoCodes[];
  snowmedCode: string | null;
  isGlobal: boolean | null;
  optimalDuration: number | null;
}

export interface unSubscribeTreatmentSchema {
  unSubscribeTreatmentSchema: unSubscribeTreatmentSchema_unSubscribeTreatmentSchema;
}

export interface unSubscribeTreatmentSchemaVariables {
  treatmentData: UnsubscribeSchemaInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setCustomDaysOfSchedule
// ====================================================

export interface setCustomDaysOfSchedule_setCustomDaysOfSchedule {
  __typename: "SchemaSchedule";
  id: string;
  customDays: number | null;
}

export interface setCustomDaysOfSchedule {
  setCustomDaysOfSchedule: setCustomDaysOfSchedule_setCustomDaysOfSchedule;
}

export interface setCustomDaysOfScheduleVariables {
  scheduleId: string;
  customDays?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addSurveySchemaToTreatmentSchema
// ====================================================

export interface addSurveySchemaToTreatmentSchema_addSurveySchemaToTreatmentSchema {
  __typename: "TreatmentSchemaToSurvey";
  id: string;
}

export interface addSurveySchemaToTreatmentSchema {
  addSurveySchemaToTreatmentSchema: addSurveySchemaToTreatmentSchema_addSurveySchemaToTreatmentSchema;
}

export interface addSurveySchemaToTreatmentSchemaVariables {
  treatmentSchemaId: string;
  surveySchemaId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeSurveySchemaFromTreatmentSchema
// ====================================================

export interface removeSurveySchemaFromTreatmentSchema_removeSurveySchemaFromTreatmentSchema {
  __typename: "TreatmentSchemaToSurvey";
  id: string;
}

export interface removeSurveySchemaFromTreatmentSchema {
  removeSurveySchemaFromTreatmentSchema: removeSurveySchemaFromTreatmentSchema_removeSurveySchemaFromTreatmentSchema;
}

export interface removeSurveySchemaFromTreatmentSchemaVariables {
  treatmentSchemaToSurveyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setCustomDaysOfTreatmentSchemaSurvey
// ====================================================

export interface setCustomDaysOfTreatmentSchemaSurvey_setCustomDaysOfTreatmentSchemaSurvey {
  __typename: "TreatmentSchemaToSurvey";
  id: string;
  customDays: number | null;
}

export interface setCustomDaysOfTreatmentSchemaSurvey {
  setCustomDaysOfTreatmentSchemaSurvey: setCustomDaysOfTreatmentSchemaSurvey_setCustomDaysOfTreatmentSchemaSurvey;
}

export interface setCustomDaysOfTreatmentSchemaSurveyVariables {
  treatmentSchemaToSurveyId: string;
  customDays?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAppointmentCountByUserIdAndInFuture
// ====================================================

export interface getAppointmentCountByUserIdAndInFuture {
  appointmentCountByIsOwnAndInFuture: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getSurveyCountByUserIdAndNotFinished
// ====================================================

export interface getSurveyCountByUserIdAndNotFinished {
  surveyCountByIsOwnAndNotFinished: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: isSurveyExpireSoonByIsOwnAndNotFinished
// ====================================================

export interface isSurveyExpireSoonByIsOwnAndNotFinished {
  isSurveyExpireSoonByIsOwnAndNotFinished: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getSurveys
// ====================================================

export interface getSurveys_getOwnSurveys_surveys_surveySchema {
  __typename: "SurveySchema";
  id: string;
  title: string;
  categories: SurveyCategoryEnum[];
}

export interface getSurveys_getOwnSurveys_surveys_doctor {
  __typename: "DoctorProfile";
  id: string;
  title: string;
  firstName: string;
  lastName: string;
}

export interface getSurveys_getOwnSurveys_surveys {
  __typename: "Survey";
  id: string;
  status: SurveyStatusEnum;
  fillableUntil: any | null;
  finishedAt: any | null;
  surveySchema: getSurveys_getOwnSurveys_surveys_surveySchema;
  doctor: getSurveys_getOwnSurveys_surveys_doctor | null;
}

export interface getSurveys_getOwnSurveys {
  __typename: "SurveyList";
  surveys: getSurveys_getOwnSurveys_surveys[];
  total: number;
}

export interface getSurveys {
  getOwnSurveys: getSurveys_getOwnSurveys;
}

export interface getSurveysVariables {
  filterData: SurveyFilter;
  paginationInput?: PaginationInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getSurveyById
// ====================================================

export interface getSurveyById_getSurvey_surveySchema_surveySchemaQuestions_data_nextSectionInformation {
  __typename: "NextSectionInformation";
  choiceId: string;
  choiceIndex: number;
  nextSectionOrder: number;
}

export interface getSurveyById_getSurvey_surveySchema_surveySchemaQuestions_data {
  __typename: "SurveyQuestion2SurveySchemaData";
  nextSectionInformation: getSurveyById_getSurvey_surveySchema_surveySchemaQuestions_data_nextSectionInformation[];
}

export interface getSurveyById_getSurvey_surveySchema_surveySchemaQuestions_surveyQuestion_data_scaleData_options {
  __typename: "ScaleOption";
  value: number;
  label: string | null;
}

export interface getSurveyById_getSurvey_surveySchema_surveySchemaQuestions_surveyQuestion_data_scaleData {
  __typename: "ScaleData";
  options: getSurveyById_getSurvey_surveySchema_surveySchemaQuestions_surveyQuestion_data_scaleData_options[];
}

export interface getSurveyById_getSurvey_surveySchema_surveySchemaQuestions_surveyQuestion_data_fileUploadData {
  __typename: "FileUploadData";
  maxItemsCount: number;
  maxFileSize: SurveyQuestionFileSize;
  validFileTypes: SurveyQuestionValidFileType[];
}

export interface getSurveyById_getSurvey_surveySchema_surveySchemaQuestions_surveyQuestion_data {
  __typename: "QuestionData";
  multipleChoice: boolean | null;
  scaleData: getSurveyById_getSurvey_surveySchema_surveySchemaQuestions_surveyQuestion_data_scaleData | null;
  fileUploadData: getSurveyById_getSurvey_surveySchema_surveySchemaQuestions_surveyQuestion_data_fileUploadData | null;
}

export interface getSurveyById_getSurvey_surveySchema_surveySchemaQuestions_surveyQuestion_mcSurveyQuestionChoices {
  __typename: "AnswerOption";
  id: string | null;
  name: string;
}

export interface getSurveyById_getSurvey_surveySchema_surveySchemaQuestions_surveyQuestion_sclSurveyQuestionAspects {
  __typename: "AnswerOption";
  id: string | null;
  name: string;
}

export interface getSurveyById_getSurvey_surveySchema_surveySchemaQuestions_surveyQuestion {
  __typename: "SurveyQuestion";
  id: string;
  title: string;
  description: string | null;
  type: SurveyQuestionEnum;
  surveySchemaId: string | null;
  isGlobal: boolean;
  data: getSurveyById_getSurvey_surveySchema_surveySchemaQuestions_surveyQuestion_data | null;
  mcSurveyQuestionChoices: getSurveyById_getSurvey_surveySchema_surveySchemaQuestions_surveyQuestion_mcSurveyQuestionChoices[];
  sclSurveyQuestionAspects: getSurveyById_getSurvey_surveySchema_surveySchemaQuestions_surveyQuestion_sclSurveyQuestionAspects[];
}

export interface getSurveyById_getSurvey_surveySchema_surveySchemaQuestions {
  __typename: "SurveySchemaQuestion";
  id: string;
  required: boolean;
  orderInSection: number;
  section: number;
  sectionName: string | null;
  data: getSurveyById_getSurvey_surveySchema_surveySchemaQuestions_data | null;
  surveyQuestion: getSurveyById_getSurvey_surveySchema_surveySchemaQuestions_surveyQuestion;
}

export interface getSurveyById_getSurvey_surveySchema {
  __typename: "SurveySchema";
  id: string;
  title: string;
  patientDescription: string;
  surveySchemaQuestions: getSurveyById_getSurvey_surveySchema_surveySchemaQuestions[];
}

export interface getSurveyById_getSurvey_surveyQuestionAnswers_mcQuestionAnswer {
  __typename: "McQuestionAnswer";
  selectedChoiceIds: string[] | null;
}

export interface getSurveyById_getSurvey_surveyQuestionAnswers_scaleQuestionAnswer_aspectAnswers {
  __typename: "AspectAnswer";
  aspectId: string;
  scaleValue: number;
}

export interface getSurveyById_getSurvey_surveyQuestionAnswers_scaleQuestionAnswer {
  __typename: "ScaleQuestionAnswer";
  aspectAnswers: getSurveyById_getSurvey_surveyQuestionAnswers_scaleQuestionAnswer_aspectAnswers[] | null;
}

export interface getSurveyById_getSurvey_surveyQuestionAnswers_simpleTextQuestionAnswer {
  __typename: "SimpleTextQuestionAnswer";
  text: string | null;
}

export interface getSurveyById_getSurvey_surveyQuestionAnswers_fileUploadQuestionAnswer_documents {
  __typename: "DocumentAnswer";
  documentId: string;
  fileName: string;
}

export interface getSurveyById_getSurvey_surveyQuestionAnswers_fileUploadQuestionAnswer {
  __typename: "FileUploadQuestionAnswer";
  documents: getSurveyById_getSurvey_surveyQuestionAnswers_fileUploadQuestionAnswer_documents[] | null;
}

export interface getSurveyById_getSurvey_surveyQuestionAnswers {
  __typename: "SurveyQuestionAnswer";
  surveyQuestionId: string;
  mcQuestionAnswer: getSurveyById_getSurvey_surveyQuestionAnswers_mcQuestionAnswer | null;
  scaleQuestionAnswer: getSurveyById_getSurvey_surveyQuestionAnswers_scaleQuestionAnswer | null;
  simpleTextQuestionAnswer: getSurveyById_getSurvey_surveyQuestionAnswers_simpleTextQuestionAnswer | null;
  fileUploadQuestionAnswer: getSurveyById_getSurvey_surveyQuestionAnswers_fileUploadQuestionAnswer | null;
}

export interface getSurveyById_getSurvey {
  __typename: "Survey";
  id: string;
  status: SurveyStatusEnum;
  fillableFrom: any;
  fillableUntil: any | null;
  surveySchema: getSurveyById_getSurvey_surveySchema;
  surveyQuestionAnswers: getSurveyById_getSurvey_surveyQuestionAnswers[];
}

export interface getSurveyById {
  getSurvey: getSurveyById_getSurvey;
}

export interface getSurveyByIdVariables {
  surveyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: upsertSurveyQuestionAnswer
// ====================================================

export interface upsertSurveyQuestionAnswer_upsertSurveyQuestionAnswer {
  __typename: "SurveyQuestionAnswer";
  id: string;
}

export interface upsertSurveyQuestionAnswer {
  upsertSurveyQuestionAnswer: upsertSurveyQuestionAnswer_upsertSurveyQuestionAnswer;
}

export interface upsertSurveyQuestionAnswerVariables {
  inputSurveyQuestionAnswer: InputSurveyQuestionAnswer;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: finish
// ====================================================

export interface finish_finishSurvey {
  __typename: "Survey";
  id: string;
}

export interface finish {
  finishSurvey: finish_finishSurvey;
}

export interface finishVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: uploadFile
// ====================================================

export interface uploadFile {
  uploadDocument: string;
}

export interface uploadFileVariables {
  documentType: string;
  file: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserLanguageChore
// ====================================================

export interface UserLanguageChore {
  __typename: "User";
  language: Language;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: multiLangText
// ====================================================

export interface multiLangText {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: optionalMultiLangText
// ====================================================

export interface optionalMultiLangText {
  __typename: "OptionalMultiLangText";
  hu: string | null;
  en: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: roomChore
// ====================================================

export interface roomChore_name {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface roomChore_address {
  __typename: "OptionalMultiLangText";
  hu: string | null;
  en: string | null;
}

export interface roomChore_description {
  __typename: "OptionalMultiLangText";
  hu: string | null;
  en: string | null;
}

export interface roomChore {
  __typename: "Room";
  id: string;
  eesztUnitId: string | null;
  name: roomChore_name;
  address: roomChore_address | null;
  mapLink: string | null;
  description: roomChore_description | null;
  isActive: boolean;
  neakCode: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: institutionChore
// ====================================================

export interface institutionChore_rooms_name {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface institutionChore_rooms_address {
  __typename: "OptionalMultiLangText";
  hu: string | null;
  en: string | null;
}

export interface institutionChore_rooms_description {
  __typename: "OptionalMultiLangText";
  hu: string | null;
  en: string | null;
}

export interface institutionChore_rooms {
  __typename: "Room";
  id: string;
  eesztUnitId: string | null;
  name: institutionChore_rooms_name;
  address: institutionChore_rooms_address | null;
  mapLink: string | null;
  description: institutionChore_rooms_description | null;
  isActive: boolean;
  neakCode: string | null;
}

export interface institutionChore {
  __typename: "Institution";
  id: string;
  eesztId: string | null;
  name: string;
  addressCounty: string | null;
  addressPostalCode: string | null;
  addressCity: string | null;
  addressAddress: string | null;
  concatenatedAddress: string | null;
  phoneNumber: string | null;
  email: string | null;
  website: string | null;
  mapLink: string | null;
  neak: string | null;
  isActive: boolean;
  isOwn: boolean;
  rooms: institutionChore_rooms[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: appointmentChore
// ====================================================

export interface appointmentChore_appointmentInfo {
  __typename: "AppointmentInfo";
  id: string;
}

export interface appointmentChore_institution_rooms_name {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface appointmentChore_institution_rooms_address {
  __typename: "OptionalMultiLangText";
  hu: string | null;
  en: string | null;
}

export interface appointmentChore_institution_rooms_description {
  __typename: "OptionalMultiLangText";
  hu: string | null;
  en: string | null;
}

export interface appointmentChore_institution_rooms {
  __typename: "Room";
  id: string;
  eesztUnitId: string | null;
  name: appointmentChore_institution_rooms_name;
  address: appointmentChore_institution_rooms_address | null;
  mapLink: string | null;
  description: appointmentChore_institution_rooms_description | null;
  isActive: boolean;
  neakCode: string | null;
}

export interface appointmentChore_institution {
  __typename: "Institution";
  id: string;
  eesztId: string | null;
  name: string;
  addressCounty: string | null;
  addressPostalCode: string | null;
  addressCity: string | null;
  addressAddress: string | null;
  concatenatedAddress: string | null;
  phoneNumber: string | null;
  email: string | null;
  website: string | null;
  mapLink: string | null;
  neak: string | null;
  isActive: boolean;
  isOwn: boolean;
  rooms: appointmentChore_institution_rooms[];
}

export interface appointmentChore_room_name {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface appointmentChore_room_address {
  __typename: "OptionalMultiLangText";
  hu: string | null;
  en: string | null;
}

export interface appointmentChore_room_description {
  __typename: "OptionalMultiLangText";
  hu: string | null;
  en: string | null;
}

export interface appointmentChore_room {
  __typename: "Room";
  id: string;
  eesztUnitId: string | null;
  name: appointmentChore_room_name;
  address: appointmentChore_room_address | null;
  mapLink: string | null;
  description: appointmentChore_room_description | null;
  isActive: boolean;
  neakCode: string | null;
}

export interface appointmentChore {
  __typename: "Appointment";
  id: string;
  proposedDate: any;
  eesztBookingId: string | null;
  isBetmenBooked: boolean;
  appointmentInfo: appointmentChore_appointmentInfo | null;
  institution: appointmentChore_institution | null;
  room: appointmentChore_room | null;
  status: AppointmentStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: surveyChore
// ====================================================

export interface surveyChore_surveySchema_professions {
  __typename: "Profession";
  id: string;
  code: string;
  name: string;
}

export interface surveyChore_surveySchema {
  __typename: "SurveySchema";
  id: string;
  title: string;
  description: string;
  categories: SurveyCategoryEnum[];
  isGlobal: boolean;
  status: SurveySchemaStatusEnum;
  professions: surveyChore_surveySchema_professions[];
}

export interface surveyChore {
  __typename: "Survey";
  id: string;
  fillableFrom: any;
  fillableUntil: any | null;
  surveySchema: surveyChore_surveySchema;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BnoCodeChore
// ====================================================

export interface BnoCodeChore {
  __typename: "BnoCode";
  id: string;
  code: string;
  title: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: treatmentSchemaChore
// ====================================================

export interface treatmentSchemaChore_title {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface treatmentSchemaChore_description {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface treatmentSchemaChore_bnoCodes {
  __typename: "BnoCode";
  id: string;
  code: string;
  title: string;
}

export interface treatmentSchemaChore {
  __typename: "TreatmentSchema";
  id: string;
  title: treatmentSchemaChore_title;
  description: treatmentSchemaChore_description | null;
  disabled: boolean;
  url: string | null;
  snowmedCode: string | null;
  bnoCodes: treatmentSchemaChore_bnoCodes[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: treatmentChore
// ====================================================

export interface treatmentChore_doctor {
  __typename: "DoctorProfile";
  id: string;
}

export interface treatmentChore_title {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface treatmentChore_description {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface treatmentChore_bnoCodes {
  __typename: "BnoCode";
  id: string;
  code: string;
  title: string;
}

export interface treatmentChore {
  __typename: "Treatment";
  id: string;
  status: TreatmentStatus | null;
  createdAt: any;
  updatedAt: any;
  comment: string | null;
  doctor: treatmentChore_doctor;
  title: treatmentChore_title;
  description: treatmentChore_description;
  bnoCodes: treatmentChore_bnoCodes[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: patientChore
// ====================================================

export interface patientChore {
  __typename: "PatientProfile";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  tajNumber: string;
  phone: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: notificationSettings
// ====================================================

export interface notificationSettings {
  __typename: "NotificationSettings";
  id: string;
  allowEmailSending: boolean;
  allowSmsSending: boolean;
  type: NotificationTypes;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: notification
// ====================================================

export interface notification {
  __typename: "Notification";
  id: string;
  type: NotificationTypes;
  data: any | null;
  isRead: boolean;
  createdAt: any;
  sentAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: institutionToDoctor
// ====================================================

export interface institutionToDoctor_institution {
  __typename: "Institution";
  id: string;
  mapLink: string | null;
  name: string;
}

export interface institutionToDoctor {
  __typename: "InstitutionToDoctor";
  id: string;
  assistant: any | null;
  facebookId: string | null;
  phone: string | null;
  workingHours: string[] | null;
  institution: institutionToDoctor_institution;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: institutionToDoctorChore
// ====================================================

export interface institutionToDoctorChore_institution {
  __typename: "Institution";
  id: string;
  mapLink: string | null;
  name: string;
}

export interface institutionToDoctorChore {
  __typename: "InstitutionToDoctor";
  id: string;
  assistant: any | null;
  facebookId: string | null;
  phone: string | null;
  workingHours: string[] | null;
  institution: institutionToDoctorChore_institution;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: userChore
// ====================================================

export interface userChore {
  __typename: "User";
  id: string;
  email: string;
  userType: UserType;
  language: Language;
  lastLogin: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: assistantProfileChore
// ====================================================

export interface assistantProfileChore_user {
  __typename: "User";
  id: string;
  email: string;
  userType: UserType;
  language: Language;
  lastLogin: any | null;
}

export interface assistantProfileChore {
  __typename: "AssistantProfile";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  registrationNumber: string;
  gender: string;
  phone: string | null;
  createdAt: any;
  user: assistantProfileChore_user;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: doctorProfileMinimalForPatients
// ====================================================

export interface doctorProfileMinimalForPatients_user {
  __typename: "User";
  email: string;
  id: string;
}

export interface doctorProfileMinimalForPatients_assistants_user {
  __typename: "User";
  id: string;
  email: string;
  userType: UserType;
  language: Language;
  lastLogin: any | null;
}

export interface doctorProfileMinimalForPatients_assistants {
  __typename: "AssistantProfile";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  registrationNumber: string;
  gender: string;
  phone: string | null;
  createdAt: any;
  user: doctorProfileMinimalForPatients_assistants_user;
}

export interface doctorProfileMinimalForPatients {
  __typename: "DoctorProfile";
  id: string;
  title: string;
  firstName: string;
  lastName: string;
  phone: string | null;
  user: doctorProfileMinimalForPatients_user | null;
  assistants: doctorProfileMinimalForPatients_assistants[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: doctorProfileForPatients
// ====================================================

export interface doctorProfileForPatients_professions {
  __typename: "Profession";
  id: string;
  code: string;
  name: string;
}

export interface doctorProfileForPatients_institutionToDoctor_institution {
  __typename: "Institution";
  id: string;
  mapLink: string | null;
  name: string;
}

export interface doctorProfileForPatients_institutionToDoctor {
  __typename: "InstitutionToDoctor";
  id: string;
  assistant: any | null;
  facebookId: string | null;
  phone: string | null;
  workingHours: string[] | null;
  institution: doctorProfileForPatients_institutionToDoctor_institution;
}

export interface doctorProfileForPatients_user {
  __typename: "User";
  id: string;
  email: string;
}

export interface doctorProfileForPatients_assistants_user {
  __typename: "User";
  id: string;
  email: string;
  userType: UserType;
  language: Language;
  lastLogin: any | null;
}

export interface doctorProfileForPatients_assistants {
  __typename: "AssistantProfile";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  registrationNumber: string;
  gender: string;
  phone: string | null;
  createdAt: any;
  user: doctorProfileForPatients_assistants_user;
}

export interface doctorProfileForPatients {
  __typename: "DoctorProfile";
  id: string;
  phone: string | null;
  firstName: string;
  lastName: string;
  title: string;
  professions: doctorProfileForPatients_professions[];
  institutionToDoctor: doctorProfileForPatients_institutionToDoctor[];
  user: doctorProfileForPatients_user | null;
  assistants: doctorProfileForPatients_assistants[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: appointmentInfoChore
// ====================================================

export interface appointmentInfoChore_patientTitle {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface appointmentInfoChore_doctorTodo {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface appointmentInfoChore_doctorTitle {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface appointmentInfoChore_goodToKnow {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface appointmentInfoChore_professions {
  __typename: "Profession";
  id: string;
  code: string;
  name: string;
}

export interface appointmentInfoChore {
  __typename: "AppointmentInfo";
  id: string;
  patientTitle: appointmentInfoChore_patientTitle;
  doctorTodo: appointmentInfoChore_doctorTodo;
  doctorTitle: appointmentInfoChore_doctorTitle;
  goodToKnow: appointmentInfoChore_goodToKnow;
  oeno: string | null;
  professions: appointmentInfoChore_professions[] | null;
  userId: string | null;
  eesztServiceId: string | null;
  beforeTreatmentNote: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: treatmentOptionsChore
// ====================================================

export interface treatmentOptionsChore_description {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface treatmentOptionsChore_title {
  __typename: "MultiLangText";
  hu: string;
  en: string;
}

export interface treatmentOptionsChore_professions {
  __typename: "Profession";
  id: string;
  code: string;
  name: string;
}

export interface treatmentOptionsChore_institutions {
  __typename: "Institution";
  id: string;
  name: string;
}

export interface treatmentOptionsChore_user_doctorProfile {
  __typename: "DoctorProfile";
  title: string;
  firstName: string;
  lastName: string;
}

export interface treatmentOptionsChore_user {
  __typename: "User";
  id: string;
  doctorProfile: treatmentOptionsChore_user_doctorProfile | null;
}

export interface treatmentOptionsChore_sharedWith_doctorProfile {
  __typename: "DoctorProfile";
  title: string;
  firstName: string;
  lastName: string;
}

export interface treatmentOptionsChore_sharedWith {
  __typename: "User";
  id: string;
  doctorProfile: treatmentOptionsChore_sharedWith_doctorProfile | null;
}

export interface treatmentOptionsChore_bnoCodes {
  __typename: "BnoCode";
  id: string;
  code: string;
  title: string;
}

export interface treatmentOptionsChore {
  __typename: "TreatmentSchema";
  id: string;
  description: treatmentOptionsChore_description | null;
  title: treatmentOptionsChore_title;
  professions: treatmentOptionsChore_professions[];
  institutions: treatmentOptionsChore_institutions[];
  disabled: boolean;
  user: treatmentOptionsChore_user;
  sharedWith: treatmentOptionsChore_sharedWith[];
  url: string | null;
  bnoCodes: treatmentOptionsChore_bnoCodes[];
  snowmedCode: string | null;
  isGlobal: boolean | null;
  optimalDuration: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: doctorProfileChore
// ====================================================

export interface doctorProfileChore_professions {
  __typename: "Profession";
  id: string;
  code: string;
  name: string;
}

export interface doctorProfileChore_user {
  __typename: "User";
  id: string;
  email: string;
}

export interface doctorProfileChore_institutionToDoctor_institution {
  __typename: "Institution";
  id: string;
  mapLink: string | null;
  name: string;
}

export interface doctorProfileChore_institutionToDoctor {
  __typename: "InstitutionToDoctor";
  id: string;
  assistant: any | null;
  facebookId: string | null;
  phone: string | null;
  workingHours: string[] | null;
  institution: doctorProfileChore_institutionToDoctor_institution;
}

export interface doctorProfileChore_assistants_user {
  __typename: "User";
  id: string;
  email: string;
  userType: UserType;
  language: Language;
  lastLogin: any | null;
}

export interface doctorProfileChore_assistants {
  __typename: "AssistantProfile";
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  registrationNumber: string;
  gender: string;
  phone: string | null;
  createdAt: any;
  user: doctorProfileChore_assistants_user;
}

export interface doctorProfileChore {
  __typename: "DoctorProfile";
  id: string;
  phone: string | null;
  title: string;
  firstName: string;
  lastName: string;
  stampNumber: string;
  userId: string;
  professions: doctorProfileChore_professions[];
  user: doctorProfileChore_user | null;
  institutionToDoctor: doctorProfileChore_institutionToDoctor[];
  assistants: doctorProfileChore_assistants[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: dependencyChore
// ====================================================

export interface dependencyChore {
  __typename: "AppointmentDependency";
  id: string;
  distance: string;
  type: AppointmentDependencyTypes;
  isBroken: boolean;
  isCustom: boolean;
  fromId: string;
  toId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: surveyQuestionChore
// ====================================================

export interface surveyQuestionChore_data_scaleData_options {
  __typename: "ScaleOption";
  value: number;
  label: string | null;
}

export interface surveyQuestionChore_data_scaleData {
  __typename: "ScaleData";
  options: surveyQuestionChore_data_scaleData_options[];
}

export interface surveyQuestionChore_data_fileUploadData {
  __typename: "FileUploadData";
  maxItemsCount: number;
  maxFileSize: SurveyQuestionFileSize;
  validFileTypes: SurveyQuestionValidFileType[];
}

export interface surveyQuestionChore_data {
  __typename: "QuestionData";
  multipleChoice: boolean | null;
  scaleData: surveyQuestionChore_data_scaleData | null;
  fileUploadData: surveyQuestionChore_data_fileUploadData | null;
}

export interface surveyQuestionChore_mcSurveyQuestionChoices {
  __typename: "AnswerOption";
  id: string | null;
  name: string;
}

export interface surveyQuestionChore_sclSurveyQuestionAspects {
  __typename: "AnswerOption";
  id: string | null;
  name: string;
}

export interface surveyQuestionChore {
  __typename: "SurveyQuestion";
  id: string;
  title: string;
  description: string | null;
  type: SurveyQuestionEnum;
  surveySchemaId: string | null;
  isGlobal: boolean;
  data: surveyQuestionChore_data | null;
  mcSurveyQuestionChoices: surveyQuestionChore_mcSurveyQuestionChoices[];
  sclSurveyQuestionAspects: surveyQuestionChore_sclSurveyQuestionAspects[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: surveySchemaQuestionChore
// ====================================================

export interface surveySchemaQuestionChore_data_nextSectionInformation {
  __typename: "NextSectionInformation";
  choiceId: string;
  choiceIndex: number;
  nextSectionOrder: number;
}

export interface surveySchemaQuestionChore_data {
  __typename: "SurveyQuestion2SurveySchemaData";
  nextSectionInformation: surveySchemaQuestionChore_data_nextSectionInformation[];
}

export interface surveySchemaQuestionChore_surveyQuestion_data_scaleData_options {
  __typename: "ScaleOption";
  value: number;
  label: string | null;
}

export interface surveySchemaQuestionChore_surveyQuestion_data_scaleData {
  __typename: "ScaleData";
  options: surveySchemaQuestionChore_surveyQuestion_data_scaleData_options[];
}

export interface surveySchemaQuestionChore_surveyQuestion_data_fileUploadData {
  __typename: "FileUploadData";
  maxItemsCount: number;
  maxFileSize: SurveyQuestionFileSize;
  validFileTypes: SurveyQuestionValidFileType[];
}

export interface surveySchemaQuestionChore_surveyQuestion_data {
  __typename: "QuestionData";
  multipleChoice: boolean | null;
  scaleData: surveySchemaQuestionChore_surveyQuestion_data_scaleData | null;
  fileUploadData: surveySchemaQuestionChore_surveyQuestion_data_fileUploadData | null;
}

export interface surveySchemaQuestionChore_surveyQuestion_mcSurveyQuestionChoices {
  __typename: "AnswerOption";
  id: string | null;
  name: string;
}

export interface surveySchemaQuestionChore_surveyQuestion_sclSurveyQuestionAspects {
  __typename: "AnswerOption";
  id: string | null;
  name: string;
}

export interface surveySchemaQuestionChore_surveyQuestion {
  __typename: "SurveyQuestion";
  id: string;
  title: string;
  description: string | null;
  type: SurveyQuestionEnum;
  surveySchemaId: string | null;
  isGlobal: boolean;
  data: surveySchemaQuestionChore_surveyQuestion_data | null;
  mcSurveyQuestionChoices: surveySchemaQuestionChore_surveyQuestion_mcSurveyQuestionChoices[];
  sclSurveyQuestionAspects: surveySchemaQuestionChore_surveyQuestion_sclSurveyQuestionAspects[];
}

export interface surveySchemaQuestionChore {
  __typename: "SurveySchemaQuestion";
  id: string;
  required: boolean;
  orderInSection: number;
  section: number;
  sectionName: string | null;
  data: surveySchemaQuestionChore_data | null;
  surveyQuestion: surveySchemaQuestionChore_surveyQuestion;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: surveySchemaChore
// ====================================================

export interface surveySchemaChore_referenceUrls {
  __typename: "SurveySchemaReferenceUrl";
  name: string;
  url: string;
}

export interface surveySchemaChore_bnoCodes {
  __typename: "BnoCode";
  id: string;
  code: string;
  title: string;
}

export interface surveySchemaChore_professions {
  __typename: "Profession";
  id: string;
  code: string;
  name: string;
}

export interface surveySchemaChore_institutions {
  __typename: "Institution";
  id: string;
  name: string;
}

export interface surveySchemaChore_sharedWithUsers_doctorProfile {
  __typename: "DoctorProfile";
  title: string;
  firstName: string;
  lastName: string;
  userId: string;
}

export interface surveySchemaChore_sharedWithUsers {
  __typename: "User";
  id: string;
  doctorProfile: surveySchemaChore_sharedWithUsers_doctorProfile | null;
}

export interface surveySchemaChore {
  __typename: "SurveySchema";
  id: string;
  title: string;
  description: string;
  patientDescription: string;
  categories: SurveyCategoryEnum[];
  isGlobal: boolean;
  status: SurveySchemaStatusEnum;
  canBeDeleted: boolean;
  referenceUrls: surveySchemaChore_referenceUrls[] | null;
  snomedCode: string | null;
  bnoCodes: surveySchemaChore_bnoCodes[];
  professions: surveySchemaChore_professions[];
  institutions: surveySchemaChore_institutions[];
  sharedWithUsers: surveySchemaChore_sharedWithUsers[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: surveyAnswerChore
// ====================================================

export interface surveyAnswerChore_mcQuestionAnswer {
  __typename: "McQuestionAnswer";
  selectedChoiceIds: string[] | null;
}

export interface surveyAnswerChore_scaleQuestionAnswer_aspectAnswers {
  __typename: "AspectAnswer";
  aspectId: string;
  scaleValue: number;
}

export interface surveyAnswerChore_scaleQuestionAnswer {
  __typename: "ScaleQuestionAnswer";
  aspectAnswers: surveyAnswerChore_scaleQuestionAnswer_aspectAnswers[] | null;
}

export interface surveyAnswerChore_simpleTextQuestionAnswer {
  __typename: "SimpleTextQuestionAnswer";
  text: string | null;
}

export interface surveyAnswerChore_fileUploadQuestionAnswer_documents {
  __typename: "DocumentAnswer";
  documentId: string;
  fileName: string;
}

export interface surveyAnswerChore_fileUploadQuestionAnswer {
  __typename: "FileUploadQuestionAnswer";
  documents: surveyAnswerChore_fileUploadQuestionAnswer_documents[] | null;
}

export interface surveyAnswerChore {
  __typename: "SurveyQuestionAnswer";
  mcQuestionAnswer: surveyAnswerChore_mcQuestionAnswer | null;
  scaleQuestionAnswer: surveyAnswerChore_scaleQuestionAnswer | null;
  simpleTextQuestionAnswer: surveyAnswerChore_simpleTextQuestionAnswer | null;
  fileUploadQuestionAnswer: surveyAnswerChore_fileUploadQuestionAnswer | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: message
// ====================================================

export interface message {
  __typename: "Message";
  id: string;
  message: string;
  createdAt: any;
  readAt: any | null;
  recipientUserId: string;
  senderUserId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AssistantProfileUpdateChore
// ====================================================

export interface AssistantProfileUpdateChore {
  __typename: "AssistantProfile";
  title: string;
  phone: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: NewOwnPatient
// ====================================================

export interface NewOwnPatient_patient_user {
  __typename: "User";
  id: string;
  email: string;
}

export interface NewOwnPatient_patient {
  __typename: "PatientProfile";
  id: string;
  user: NewOwnPatient_patient_user | null;
  firstName: string;
  lastName: string;
  title: string;
  tajNumber: string;
  phone: string;
  birthDate: any;
}

export interface NewOwnPatient {
  __typename: "DoctorOwnPatients";
  id: string;
  patient: NewOwnPatient_patient | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DoctorProfileUpdateChore
// ====================================================

export interface DoctorProfileUpdateChore_professions {
  __typename: "Profession";
  id: string;
  code: string;
  name: string;
}

export interface DoctorProfileUpdateChore_institutionToDoctor_institution {
  __typename: "Institution";
  id: string;
  mapLink: string | null;
  name: string;
}

export interface DoctorProfileUpdateChore_institutionToDoctor {
  __typename: "InstitutionToDoctor";
  id: string;
  assistant: any | null;
  facebookId: string | null;
  phone: string | null;
  workingHours: string[] | null;
  institution: DoctorProfileUpdateChore_institutionToDoctor_institution;
}

export interface DoctorProfileUpdateChore {
  __typename: "DoctorProfile";
  title: string;
  phone: string | null;
  professions: DoctorProfileUpdateChore_professions[];
  hasEESZTToken: boolean;
  institutionToDoctor: DoctorProfileUpdateChore_institutionToDoctor[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SchemaDisabledChore
// ====================================================

export interface SchemaDisabledChore {
  __typename: "TreatmentSchema";
  disabled: boolean;
  isDeletable: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Possible types for appointment dependency
 */
export enum AppointmentDependencyTypes {
  maxDistance = "maxDistance",
  minDistance = "minDistance",
  notSpecified = "notSpecified",
}

/**
 * Possible statuses for appointments
 */
export enum AppointmentStatus {
  BetmenBooked = "BetmenBooked",
  Declined = "Declined",
  EesztBooked = "EesztBooked",
  Expired = "Expired",
  Happened = "Happened",
  Missed = "Missed",
  Proposed = "Proposed",
}

/**
 * Possible blood types
 */
export enum BloodType {
  AB_minus = "AB_minus",
  AB_plus = "AB_plus",
  A_minus = "A_minus",
  A_plus = "A_plus",
  B_minus = "B_minus",
  B_plus = "B_plus",
  O_minus = "O_minus",
  O_plus = "O_plus",
}

/**
 * Possible categories of document type
 */
export enum DocumentTypeEnum {
  SurveyQuestionAnswerFile = "SurveyQuestionAnswerFile",
}

/**
 * Possible statuses for EesztAppointmentBookingTransactions
 */
export enum EesztAppointmentBookingTransactionStatusEnum {
  Failed = "Failed",
  Pending = "Pending",
  Success = "Success",
}

/**
 * Possible gender types
 */
export enum Gender {
  FEMALE = "FEMALE",
  MALE = "MALE",
}

/**
 * Possible languages
 */
export enum Language {
  en = "en",
  hu = "hu",
}

/**
 * Types of possible notifications
 */
export enum NotificationTypes {
  AppointmentGoodToKnowChanged = "AppointmentGoodToKnowChanged",
  AppointmentLocationChanged = "AppointmentLocationChanged",
  AppointmentStatusChanged = "AppointmentStatusChanged",
  AppointmentTimeChanged = "AppointmentTimeChanged",
  AssistantAddedToDoctor = "AssistantAddedToDoctor",
  AssistantInvite = "AssistantInvite",
  CancelledTreatment = "CancelledTreatment",
  DailyAppointmentReminder = "DailyAppointmentReminder",
  DoctorDeclinedAppointment = "DoctorDeclinedAppointment",
  DoctorInvite = "DoctorInvite",
  DoctorNextWeekTasks = "DoctorNextWeekTasks",
  DoctorReceivedNewPatient = "DoctorReceivedNewPatient",
  EESZTNewFeedItem = "EESZTNewFeedItem",
  ExpiringPasswordReminder = "ExpiringPasswordReminder",
  ExpiringTreatmentReminder = "ExpiringTreatmentReminder",
  LockOutUser = "LockOutUser",
  NewAppointment = "NewAppointment",
  NewAppointmentAssignedToDoctor = "NewAppointmentAssignedToDoctor",
  NewDoctorAssignedToAppointment = "NewDoctorAssignedToAppointment",
  NewHealthIssue = "NewHealthIssue",
  NewSurvey = "NewSurvey",
  NewTreatment = "NewTreatment",
  PasswordResetLink = "PasswordResetLink",
  PatientAddedToDoctor = "PatientAddedToDoctor",
  PatientInvite = "PatientInvite",
  PatientNextWeekAppointments = "PatientNextWeekAppointments",
  SurveyFillableFromToday = "SurveyFillableFromToday",
  SurveyToFillExpiring = "SurveyToFillExpiring",
  TwoFactorAuthToken = "TwoFactorAuthToken",
}

/**
 * Patient status
 */
export enum PatientStatus {
  ACTIVE = "ACTIVE",
  DISCHARGED = "DISCHARGED",
}

/**
 * Possible categories of survey
 */
export enum SurveyCategoryEnum {
  PREM = "PREM",
  PROM = "PROM",
  QualityAssurance = "QualityAssurance",
  QualityOfLife = "QualityOfLife",
  RiskAssessment = "RiskAssessment",
}

/**
 * Possible types of survey question
 */
export enum SurveyQuestionEnum {
  FileUpload = "FileUpload",
  MultipleChoice = "MultipleChoice",
  Scale = "Scale",
  SimpleText = "SimpleText",
}

/**
 * Maximum file size of survey question
 */
export enum SurveyQuestionFileSize {
  FiveMB = "FiveMB",
  TenMB = "TenMB",
  TwentyFourMB = "TwentyFourMB",
  Unlimited = "Unlimited",
}

/**
 * Valid file type of survey question
 */
export enum SurveyQuestionValidFileType {
  DOCX = "DOCX",
  JPG = "JPG",
  MP4 = "MP4",
  PDF = "PDF",
  PNG = "PNG",
  XLSX = "XLSX",
}

/**
 * Possible statuses of survey schema
 */
export enum SurveySchemaStatusEnum {
  Active = "Active",
  Archived = "Archived",
  InActive = "InActive",
}

/**
 * Possible statuses of survey
 */
export enum SurveyStatusEnum {
  Finished = "Finished",
  InProgress = "InProgress",
  Untouched = "Untouched",
}

/**
 * Possible types for treatment log
 */
export enum TreatmentLogTypeEnum {
  TreatmentAppointmentAdded = "TreatmentAppointmentAdded",
  TreatmentAppointmentDateUpdated = "TreatmentAppointmentDateUpdated",
  TreatmentAppointmentDeleted = "TreatmentAppointmentDeleted",
  TreatmentAppointmentDoctorUpdated = "TreatmentAppointmentDoctorUpdated",
  TreatmentAppointmentFeedbackRating = "TreatmentAppointmentFeedbackRating",
  TreatmentAppointmentFeedbackText = "TreatmentAppointmentFeedbackText",
  TreatmentAppointmentGoodToKnowUpdated = "TreatmentAppointmentGoodToKnowUpdated",
  TreatmentAppointmentLocationUpdated = "TreatmentAppointmentLocationUpdated",
  TreatmentAppointmentStatusUpdated = "TreatmentAppointmentStatusUpdated",
  TreatmentClosed = "TreatmentClosed",
  TreatmentCreated = "TreatmentCreated",
  TreatmentProtocolAdded = "TreatmentProtocolAdded",
  TreatmentProtocolDeleted = "TreatmentProtocolDeleted",
  TreatmentSent = "TreatmentSent",
  TreatmentUpdated = "TreatmentUpdated",
}

/**
 * Possible statuses for treatments
 */
export enum TreatmentStatus {
  Cancelled = "Cancelled",
  Draft = "Draft",
  Sent = "Sent",
}

/**
 * Possible user types
 */
export enum UserType {
  Admin = "Admin",
  Assistant = "Assistant",
  Doctor = "Doctor",
  Patient = "Patient",
}

export interface AddProtocolInput {
  treatmentSchemaId: string;
  startDate: any;
  treatmentId: string;
}

export interface AnswerOptionInput {
  id?: string | null;
  name: string;
}

export interface AppointmentInput {
  treatmentId: string;
  appointmentInfoId: string;
  proposedDate: string;
  institutionId?: string | null;
  roomId?: string | null;
  doctorId: string;
  consultationId?: string | null;
  isBetmenBooked?: boolean | null;
}

export interface AppointmentUpdateGoodToKnowInput {
  goodToKnowHu?: string | null;
  goodToKnowEn?: string | null;
}

export interface AppointmentUpdateInput {
  appointmentId: string;
  proposedDate: string;
  institutionId?: string | null;
  roomId?: string | null;
  doctorId: string;
  consultationId?: string | null;
  isBetmenBooked?: boolean | null;
}

export interface AssistantInputData {
  name: string;
  phone?: string | null;
  email?: string | null;
}

export interface CancelTreatmentInput {
  treatmentId: string;
  closingComment?: string | null;
}

export interface CreateAppointmentDependencyInput {
  appointmentId: string;
  fromId: string;
  distance: string;
}

export interface CreateAppointmentInfoInput {
  doctorTitle: any;
  patientTitle: any;
  professionIds?: string[] | null;
  goodToKnow: any;
  doctorTodo: any;
  oeno?: string | null;
  beforeTreatmentNote: boolean;
  treatmentSchemaId?: string | null;
}

export interface CreateAssistantProfileData {
  firstName: string;
  lastName: string;
  gender: string;
  email: string;
  registrationNumber: string;
  title: string;
  phone?: string | null;
}

export interface CreateDependencyInput {
  treatmentSchemaId: string;
  fromId: string;
  toId: string;
  distance: number;
  dependencyType: AppointmentDependencyTypes;
  unit: string;
}

export interface CreateDoctorProfileData {
  title: string;
  firstName: string;
  lastName: string;
  gender: string;
  email: string;
  stampNumber: string;
  phone?: string | null;
}

export interface CreatePatientProfileData {
  firstName: string;
  lastName: string;
  title: string;
  tajNumber: string;
  birthDate: any;
  email: string;
  phone: string;
  gender: string;
}

export interface CreateRoomInput {
  name: MultiLangTextInput;
  address?: OptionalMultiLangTextInput | null;
  description?: OptionalMultiLangTextInput | null;
  neakCode?: string | null;
  isActive?: boolean | null;
  institutionId: string;
}

export interface CreateSurveyQuestionInput {
  title: string;
  description?: string | null;
  type: SurveyQuestionEnum;
  data?: QuestionDataInput | null;
  mcSurveyQuestionChoices?: AnswerOptionInput[] | null;
  sclSurveyQuestionAspects?: AnswerOptionInput[] | null;
  surveySchemaId?: string | null;
  isSurveySpecific?: boolean | null;
}

export interface CreateSurveySchemaInput {
  title: string;
  description: string;
  patientDescription: string;
  referenceUrls?: InputSurveySchemaReferenceUrl[] | null;
  snomedCode?: string | null;
  professionIds?: string[] | null;
  bnoCodeIds?: string[] | null;
  categories: SurveyCategoryEnum[];
  questions: SurveyQuestionToSurveySchemaInput[];
}

export interface CreateTreatmentInput {
  patientId: string;
  customTreatmentTitle: MultiLangTextInput;
  customTreatmentDescription: MultiLangTextInput;
  bnoCodeIds?: string[] | null;
}

export interface DateIntervalInput {
  startDate?: any | null;
  endDate?: any | null;
}

export interface DeleteAppointmentInfoInput {
  appointmentInfoId: string;
}

export interface DeleteProtocolInput {
  treatmentSchemaId: string;
  treatmentId: string;
}

export interface DoctorProfileFilter {
  assistantId: string;
}

export interface EESZTStsLoginInput {
  password: string;
}

export interface EditAbleDoctorData {
  phone?: string | null;
  title?: string | null;
  professionIds?: string[] | null;
  institutions?: InstitutionInputData[] | null;
}

export interface EditAblePatientData {
  gender?: string | null;
  title?: string | null;
  phone?: string | null;
  address?: string | null;
  height?: number | null;
  weight?: number | null;
  takenMedicines?: string[] | null;
  medicineIntolerance?: string[] | null;
  emergencyContacts?: EmergencyContactInput[] | null;
}

export interface EmergencyContactInput {
  name?: string | null;
  phone?: string | null;
}

export interface FileUploadDataInput {
  maxItemsCount: number;
  maxFileSize: SurveyQuestionFileSize;
  validFileTypes: SurveyQuestionValidFileType[];
}

export interface InputAspectAnswer {
  aspectId: string;
  scaleValue: number;
}

export interface InputFileUploadAnswer {
  documentId: string;
  fileName: string;
}

export interface InputFileUploadQuestionAnswer {
  documents?: InputFileUploadAnswer[] | null;
}

export interface InputMcQuestionAnswer {
  selectedChoiceIds?: string[] | null;
}

export interface InputScaleQuestionAnswer {
  aspectAnswers?: InputAspectAnswer[] | null;
}

export interface InputSimpleTextQuestionAnswer {
  text?: string | null;
}

export interface InputSurveyQuestionAnswer {
  surveyId: string;
  surveyQuestionId: string;
  mcQuestionAnswer?: InputMcQuestionAnswer | null;
  scaleQuestionAnswer?: InputScaleQuestionAnswer | null;
  simpleTextQuestionAnswer?: InputSimpleTextQuestionAnswer | null;
  fileUploadQuestionAnswer?: InputFileUploadQuestionAnswer | null;
}

export interface InputSurveySchemaReferenceUrl {
  name: string;
  url: string;
}

export interface InstitutionInput {
  name: string;
  addressCounty?: string | null;
  addressPostalCode?: string | null;
  addressCity?: string | null;
  addressAddress?: string | null;
  phoneNumber?: string | null;
  email?: string | null;
  website?: string | null;
  neak?: string | null;
  isActive: boolean;
}

export interface InstitutionInputData {
  facebookId?: string | null;
  phone?: string | null;
  workingHours?: string[] | null;
  assistant?: AssistantInputData | null;
  institution: InstitutionInputType;
}

export interface InstitutionInputType {
  id: string;
  name: string;
}

export interface LoginWithTwoFactorTokenInput {
  email: string;
  token: string;
}

export interface MultiLangTextInput {
  hu: string;
  en: string;
}

export interface NextSectionInformationInput {
  choiceId: string;
  choiceIndex: number;
  nextSectionOrder: number;
}

export interface NotificationSettingsInput {
  type: NotificationTypes;
  allowEmailSending: boolean;
  allowSmsSending: boolean;
}

export interface OptionalMultiLangTextInput {
  hu?: string | null;
  en?: string | null;
}

export interface PaginationInput {
  offset?: number | null;
  limit?: number | null;
}

export interface PatientAgeFilter {
  max?: number | null;
  min?: number | null;
}

export interface PatientBNOCodeFilter {
  includes?: string[] | null;
  excludes?: string[] | null;
}

export interface PatientFilter {
  bnoCode?: PatientBNOCodeFilter | null;
  age?: PatientAgeFilter | null;
  nameOrTAJ?: string | null;
  gender?: Gender | null;
  status?: PatientStatus | null;
}

export interface QuestionDataInput {
  multipleChoice?: boolean | null;
  scaleData?: ScaleDataInput | null;
  fileUploadData?: FileUploadDataInput | null;
}

export interface ScaleDataInput {
  options: ScaleOptionInput[];
}

export interface ScaleOptionInput {
  value: number;
  label?: string | null;
}

export interface ShareSchemaInput {
  sharedWithUserIds: string[];
  institutionIds: string[];
  schemaId: string;
}

export interface ShareSurveySchemaInput {
  surveySchemaId: string;
  institutionIds: string[];
  sharedWithUserIds: string[];
}

export interface SurveyFilter {
  isFinished: boolean;
  categories?: SurveyCategoryEnum[] | null;
}

export interface SurveyQuestion2SurveySchemaDataInput {
  nextSectionInformation: NextSectionInformationInput[];
}

export interface SurveyQuestionFilterInput {
  searchTerm?: string | null;
  isOwn?: boolean | null;
  includeBySurveySchemaId?: string | null;
  questionType?: SurveyQuestionEnum[] | null;
}

export interface SurveyQuestionToSurveySchemaInput {
  surveyQuestionId?: string | null;
  required: boolean;
  section: number;
  sectionName?: string | null;
  orderInSection: number;
  data?: SurveyQuestion2SurveySchemaDataInput | null;
  questionData?: CreateSurveyQuestionInput | null;
}

export interface SurveySchemaFilter {
  isOwn?: boolean | null;
  status?: SurveySchemaStatusEnum | null;
  professionIds?: string[] | null;
  searchTerm?: string | null;
  categories?: SurveyCategoryEnum[] | null;
  latestVersionOnly?: boolean | null;
}

export interface TreatmentSchemaCreateInput {
  title: MultiLangTextInput;
  description: MultiLangTextInput;
  treatmentId?: string | null;
}

export interface TreatmentSchemaFilter {
  bnoCodes?: string[] | null;
  isOwn?: boolean | null;
  active?: boolean | null;
  professionIds?: string[] | null;
  searchTerm?: string | null;
}

export interface UnsubscribeSchemaInput {
  schemaId: string;
}

export interface UpdateAppointmentDependencyInput {
  dependencyId: string;
  distance: string;
}

export interface UpdateAppointmentInfoInput {
  doctorTitle: any;
  patientTitle: any;
  professionIds?: string[] | null;
  goodToKnow: any;
  doctorTodo: any;
  oeno?: string | null;
  beforeTreatmentNote: boolean;
  treatmentSchemaId?: string | null;
  appointmentInfoId: string;
}

export interface UpdateAssistantProfileInput {
  id: string;
  phone?: string | null;
  title?: string | null;
}

export interface UpdateDependencyInput {
  treatmentSchemaId: string;
  fromId: string;
  toId: string;
  dependencyId: string;
  distance: number;
  dependencyType: AppointmentDependencyTypes;
  unit: string;
}

export interface UpdateRoomInput {
  name: MultiLangTextInput;
  address?: OptionalMultiLangTextInput | null;
  description?: OptionalMultiLangTextInput | null;
  neakCode?: string | null;
  isActive?: boolean | null;
  roomId: string;
}

export interface UpdateScheduleAppointmentInfoInput {
  doctorTitle: any;
  patientTitle: any;
  professionIds?: string[] | null;
  goodToKnow: any;
  doctorTodo: any;
  oeno?: string | null;
  beforeTreatmentNote: boolean;
  treatmentSchemaId?: string | null;
}

export interface UpdateSchemaInput {
  id: string;
  title?: MultiLangTextInput | null;
  description?: MultiLangTextInput | null;
  url?: string | null;
  bnoCodeIds?: string[] | null;
  snowmedCode?: string | null;
  professionIds?: string[] | null;
  optimalDuration?: number | null;
}

export interface UpdateSurveyQuestionInput {
  title: string;
  description?: string | null;
  type: SurveyQuestionEnum;
  data?: QuestionDataInput | null;
  mcSurveyQuestionChoices?: AnswerOptionInput[] | null;
  sclSurveyQuestionAspects?: AnswerOptionInput[] | null;
  id: string;
}

export interface UpdateSurveyQuestionToSurveySchemaInput {
  surveyQuestionId?: string | null;
  required: boolean;
  section: number;
  sectionName?: string | null;
  orderInSection: number;
  data?: SurveyQuestion2SurveySchemaDataInput | null;
  questionData?: CreateSurveyQuestionInput | null;
  id?: string | null;
}

export interface UpdateSurveySchemaInput {
  title: string;
  description: string;
  patientDescription: string;
  referenceUrls?: InputSurveySchemaReferenceUrl[] | null;
  snomedCode?: string | null;
  professionIds?: string[] | null;
  bnoCodeIds?: string[] | null;
  categories: SurveyCategoryEnum[];
  id: string;
  questions: UpdateSurveyQuestionToSurveySchemaInput[];
}

export interface UpdateTreatmentInput {
  treatmentId: string;
  title?: MultiLangTextInput | null;
  description?: MultiLangTextInput | null;
  bnoCodeIds?: string[] | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
