import { Box, Tab, TabProps, Tabs } from '@mui/material'
import { isFunction } from 'lodash'
import React, { createContext, FC, useContext, useState } from 'react'
import { ScrollablePanel } from './ScrollablePanel'
import { ScrollablePanelProps } from './ScrollablePanel/ScrollablePanel'

interface BetmenPageContextProps {
  scrollPositionY: number
  basePadding: { xs: number; md: number }
  setScrollPositionY?: (posY: number) => void
}

const defaultBetmenPageContext: BetmenPageContextProps = {
  scrollPositionY: 0,
  basePadding: { xs: 1, md: 2 },
}

const BetmenPageContext = createContext<BetmenPageContextProps>(
  defaultBetmenPageContext
)

export const useBetmenPage = (): BetmenPageContextProps =>
  useContext(BetmenPageContext)

export interface Tab extends TabProps {
  'data-cy'?: string
}

interface Props extends ScrollablePanelProps {
  filters?: React.ReactNode
  tabs?: Tab[]
  defaultTabIndex?: number
  selectedTabValue?: number
  onTabChange?: (tabIndex: number) => void
}

export const BetmenPage: FC<Props> = ({
  children,
  filters,
  tabs,
  selectedTabValue,
  onTabChange,
  defaultTabIndex = 0,
  ...otherProps
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(defaultTabIndex)
  const [scrollPositionY, setScrollPositionY] = useState<number>(
    defaultBetmenPageContext.scrollPositionY
  )

  const setTabIndex = (tabIndex: number) => {
    setSelectedTabIndex(tabIndex)
    isFunction(onTabChange) && onTabChange(tabIndex)
  }

  return (
    <BetmenPageContext.Provider
      value={{
        scrollPositionY,
        basePadding: defaultBetmenPageContext.basePadding,
        setScrollPositionY,
      }}
    >
      <Box display="flex" height="100%" flexDirection="column">
        <ScrollablePanel
          {...otherProps}
          filters={filters}
          tabs={
            tabs?.length && (
              <Tabs
                value={selectedTabValue || selectedTabIndex}
                indicatorColor="primary"
                textColor="primary"
                scrollButtons="auto"
                variant="scrollable"
                onChange={(_, tabIndex) => setTabIndex(tabIndex)}
                orientation="horizontal"
              >
                {tabs.map((tab, index) => (
                  <Tab key={index} {...tab} />
                ))}
              </Tabs>
            )
          }
        >
          {children}
        </ScrollablePanel>
      </Box>
    </BetmenPageContext.Provider>
  )
}
